import { Navigate, Routes, Route } from "react-router-dom";
import { UsageRoute } from "./UsageRoute";
import { WithHeader } from "../page/header";
import { BillingRoute } from "./BillingRoute";

export const DashRoute: React.FC = () => {
  return (
    <WithHeader>
      <Routes>
        <Route path="usage/*" element={<UsageRoute />} />
        <Route path="billing/*" element={<BillingRoute />} />
        <Route path="*" element={<Navigate to="/dash/usage/" />} />
      </Routes>
    </WithHeader>
  );
};
