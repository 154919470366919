import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { Navigate } from "react-router-dom";
import { useScaffold } from "@Light/scaffold";
import { useAPI } from "@/services/api";

export function Login() {
  const { useLoginEmailTokenMutation } = useAPI();
  const [loginEmailToken, loginEmailTokenState] = useLoginEmailTokenMutation();

  const scaffold = useScaffold();
  const Title = scaffold.page.title;
  const Subtitle = scaffold.page.subtitle;
  const Field = scaffold.system.field;
  const Label = scaffold.system.label;
  const Input = scaffold.system.input;
  const ErrorMessage = scaffold.system.errorMessage;
  const Button = scaffold.system.button;
  const { register, handleSubmit, watch } = useForm();
  const { email } = watch();

  const onSubmit = useCallback(
    ({ email }: any) => {
      loginEmailToken({ email });
    },
    [loginEmailToken],
  );

  if (loginEmailTokenState.isSuccess) {
    const params = new URLSearchParams({ email });
    return <Navigate to={`/login/check-email?${params}`} />;
  }

  const error = loginEmailTokenState?.error ?? "";
  return (
    <div className="flex items-center justify-center text-center">
      <div className="flex flex-col items-center gap-4">
        <div className="flex flex-col items-center gap-2">
          <Title>
            <div className="flex flex-col">
              <div>Welcome to</div>
              <div>{scaffold.page.companyName}</div>
            </div>
          </Title>
          <Subtitle>Sign in with your email</Subtitle>
        </div>
        <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col items-center gap-4 w-full">
            <Field className="w-full">
              <Label>Email</Label>
              <Input
                type="email"
                {...register("email", { required: "required" })}
              />
              {error ? (
                <ErrorMessage>
                  We encountered an error while trying to log you in. Please try
                  again later.
                </ErrorMessage>
              ) : null}
            </Field>
            <Button
              type="submit"
              className="w-full"
              disabled={loginEmailTokenState.isLoading}
            >
              Continue
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}
