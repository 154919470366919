import { useScaffold } from "@Light/scaffold";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
export type ItemProps = {
  title: string;
  description?: string;
  href: string;
};
export const Item: React.FC<ItemProps> = ({ title, href, description }) => {
  const scaffold = useScaffold();
  const DetailLabel = scaffold.page.detail.detailLabel;
  return (
    <a href={href} target="_blank" rel="noopener noreferrer">
      <div className="flex flex-row justify-between items-start w-full">
        <div className="flex flex-col gap-2">
          <DetailLabel>{title}</DetailLabel>
          {description ? (
            <div
              className={clsx(
                "text-sm font-regular",
                scaffold.page.colors.text.tertiary,
              )}
            >
              {description}
            </div>
          ) : null}
        </div>
        <ArrowTopRightOnSquareIcon
          className={clsx(
            "w-5 h-5 flex-shrink-0",
            scaffold.page.colors.text.button,
          )}
        />
      </div>
    </a>
  );
};
