import {
  Description as HeadlessDescription,
  Field as HeadlessField,
  Fieldset as HeadlessFieldset,
  Label as HeadlessLabel,
  Legend as HeadlessLegend,
  type DescriptionProps as HeadlessDescriptionProps,
  type FieldProps as HeadlessFieldProps,
  type FieldsetProps as HeadlessFieldsetProps,
  type LabelProps as HeadlessLabelProps,
  type LegendProps as HeadlessLegendProps,
} from "@headlessui/react";
import clsx from "clsx";
import React from "react";
import { useScaffold } from "../../scaffold";

export type FieldsetProps = { disabled?: boolean } & HeadlessFieldsetProps;
export function Fieldset({ className, ...props }: FieldsetProps) {
  return (
    <HeadlessFieldset
      {...props}
      className={clsx(
        className,
        "[&>*+[data-slot=control]]:mt-6 [&>[data-slot=text]]:mt-1",
      )}
    />
  );
}

export type LegendProps = HeadlessLegendProps;
export function Legend({ ...props }: LegendProps) {
  return (
    <HeadlessLegend
      {...props}
      data-slot="legend"
      className={clsx(
        props.className,
        "text-base/6 font-semibold text-zinc-950 data-[disabled]:opacity-50 sm:text-sm/6 dark:text-white",
      )}
    />
  );
}

export type FieldGroupProps = React.ComponentPropsWithoutRef<"div">;
export function FieldGroup({ className, ...props }: FieldGroupProps) {
  return (
    <div
      {...props}
      data-slot="control"
      className={clsx(className, "space-y-8")}
    />
  );
}

export type FieldProps = HeadlessFieldProps;
export function Field({ className, ...props }: FieldProps) {
  return (
    <HeadlessField
      className={clsx(
        className,
        "[&>[data-slot=label]+[data-slot=control]]",
        "[&>[data-slot=label]+[data-slot=description]]:mt-1",
        "[&>[data-slot=description]+[data-slot=control]]:mt-3",
        "[&>[data-slot=control]+[data-slot=description]]:mt-3",
        "[&>[data-slot=control]+[data-slot=error]]:mt-3",
        "[&>[data-slot=label]]:font-medium",
        "text-left",
      )}
      {...props}
    />
  );
}

export type LabelProps = { className?: string } & HeadlessLabelProps;
export function Label({ className, ...props }: LabelProps) {
  const scaffold = useScaffold();
  return (
    <HeadlessLabel
      {...props}
      data-slot="label"
      className={clsx(
        className,
        "select-none text-base/6",
        scaffold.page.colors.text.secondary,
        "data-[disabled]:opacity-50 dark:text-white text-xs",
      )}
    />
  );
}

export type DescriptionProps = {
  className?: string;
  disabled?: boolean;
} & HeadlessDescriptionProps;
export function Description({
  className,
  disabled,
  ...props
}: DescriptionProps) {
  return (
    <HeadlessDescription
      {...props}
      data-slot="description"
      className={clsx(
        className,
        "text-base/6 text-zinc-500 data-[disabled]:opacity-50 sm:text-sm/6 dark:text-zinc-400",
      )}
    />
  );
}

export type ErrorMessageProps = {
  className?: string;
  disabled?: boolean;
  error?: React.ReactNode;
} & HeadlessDescriptionProps;
export function ErrorMessage({
  className,
  disabled,
  children,
  error,
  ...props
}: ErrorMessageProps) {
  const errorNode = children ?? error;
  if (!errorNode) {
    return null;
  }
  return (
    <HeadlessDescription
      {...props}
      data-slot="error"
      className={clsx(
        className,
        "text-base/6 text-red-600 data-[disabled]:opacity-50 sm:text-sm/6 dark:text-red-500",
      )}
      children={errorNode}
    />
  );
}
