import { useScaffold } from "@Light/scaffold";

export type EnrollmentConfirmedFooterProps = {};

export const EnrollmentConfirmedFooter: React.FC<
  EnrollmentConfirmedFooterProps
> = ({}) => {
  const scaffold = useScaffold();
  const Button = scaffold.page.button;
  const Link = scaffold.system.link;
  return (
    <Link to="/dash">
      <Button className="w-full">All set</Button>
    </Link>
  );
};
