import { ReactNode } from "react";
import { Navigate } from "react-router-dom";
import { useAccount } from "@Light/components/page/account";

export type RequireNoServiceProps = {
  redirect: string;
  children: ReactNode;
};

export const RequireNoService: React.FC<RequireNoServiceProps> = ({
  redirect,
  children,
}) => {
  const account = useAccount();
  if (
    account.enrollment?.is_service_active &&
    account.enrollment?.has_usage_history
  ) {
    return <Navigate to={redirect} />;
  }
  return children;
};
