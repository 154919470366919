import { useScaffold } from "@Light/scaffold";
import { PeriodUsage } from "@Light/services/lightTypes";
import clsx from "clsx";
import dayjs from "dayjs";
import { useMemo } from "react";
import { UsageChart } from "./UsageChart";
import { BillingCard } from "./BillingCard";
import { useLight } from "@Light/services/light";
import { useLocation } from "@Light/components/page/account";
import { Query, QueryLoader } from "@Light/components/app/QueryLoader";
import { Notification } from "./Notification";

export type UsageDataProps = {};

export const UsageData: React.FC<UsageDataProps> = ({}) => {
  const { uuid } = useLocation();
  const { useGetLocationMeterReadUsageQuery } = useLight();
  const meterReadUsage = useGetLocationMeterReadUsageQuery({ uuid });
  const scaffold = useScaffold();
  const PageBody = scaffold.page.pageBody;
  return (
    <PageBody notification={<Notification />} title="Energy consumption">
      <QueryLoader
        queries={[meterReadUsage as Query]}
        errorMessage="Error loading meter read data. Please try again later."
      >
        <UsageDataB1 meterReads={meterReadUsage.data ?? []} />
      </QueryLoader>
    </PageBody>
  );
};

export type UsageDataB1Props = {
  meterReads: PeriodUsage[];
};

export const UsageDataB1: React.FC<UsageDataB1Props> = ({ meterReads }) => {
  const scaffold = useScaffold();
  const latestRead = meterReads[0];
  const latestStart = useMemo(
    () => dayjs(latestRead?.period_start),
    [latestRead?.period_start],
  );
  const latestEnd = useMemo(
    () => dayjs(latestRead?.period_end),
    [latestRead?.period_end],
  );
  const latestDays = useMemo(
    () => latestEnd?.diff(latestStart, "days"),
    [latestStart, latestEnd],
  );

  if (!latestRead) {
    return <div>We haven't yet received your meter data. Stay tuned!</div>;
  }
  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row justify-between">
        <div className="flex flex-col">
          <div className={clsx("text-xs", scaffold.page.colors.text.secondary)}>
            {latestEnd.format("MMM D")} ({latestDays} days)
          </div>
          <div className="flex flex-row gap-1 items-end">
            <div
              className={clsx("text-2xl", scaffold.page.colors.text.primary)}
            >
              {Math.round(latestRead.total_kwh)}
            </div>
            <div
              className={clsx(
                "text-sm pb-0.5",
                scaffold.page.colors.text.primary,
              )}
            >
              kWh
            </div>
          </div>
        </div>
        <div className="flex flex-col">
          <div
            className={clsx(
              "text-xs font-regular",
              scaffold.page.colors.text.secondary,
            )}
          >
            Daily usage
          </div>
          <div className="flex flex-row gap-1 items-end">
            <div
              className={clsx("text-2xl", scaffold.page.colors.text.primary)}
            >
              {Math.round(latestRead.total_kwh / latestDays)}
            </div>
            <div
              className={clsx(
                "text-sm pb-0.5",
                scaffold.page.colors.text.primary,
              )}
            >
              kWh
            </div>
          </div>
        </div>
      </div>
      <UsageChart periodUsage={meterReads} />
      <BillingCard />
    </div>
  );
};
