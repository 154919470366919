import { DataInteractive as HeadlessDataInteractive } from "@headlessui/react";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";
import React from "react";

export type LinkProps = RouterLinkProps & React.ComponentPropsWithoutRef<"a">;
export const Link: React.FC<LinkProps> = React.forwardRef(function Link(
  props: LinkProps,
  ref: React.ForwardedRef<HTMLAnchorElement>,
) {
  return (
    <HeadlessDataInteractive>
      <RouterLink {...props} ref={ref} />
    </HeadlessDataInteractive>
  );
});
