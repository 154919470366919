import { useScaffold } from "@Light/scaffold";
import { DetailFieldProps } from "@Light/components/page/detail/DetailField";
import {
  ChatBubbleLeftRightIcon,
  IdentificationIcon,
  LinkIcon,
} from "@heroicons/react/24/outline";
import { useLight } from "@Light/services/light";
import { useMutation } from "@Light/utils/mutation";
import { useCallback, useEffect } from "react";
import {
  AuthorizeVehiclesRequest,
  AuthorizeVehiclesResponse,
} from "@Light/services/lightTypes";

export const EnodeAuthorization: React.FC = ({}) => {
  const { useAuthorizeVehiclesMutation } = useLight();
  const authorizeVehicles = useMutation<
    AuthorizeVehiclesRequest,
    AuthorizeVehiclesResponse
  >(useAuthorizeVehiclesMutation);

  const mutate = useCallback(() => {
    const redirect_url = new URL(window.location.href);
    redirect_url.pathname = "/enroll/authorize-vehicles/check-authorized";
    authorizeVehicles.mutate({
      redirect_url: redirect_url.toString(),
    });
  }, [window.location.href, authorizeVehicles.mutate]);

  useEffect(() => {
    if (!authorizeVehicles.data) {
      return;
    }
    const { url } = authorizeVehicles.data;
    window.location.href = url;
  }, [authorizeVehicles.data]);

  const scaffold = useScaffold();
  const PageBody = scaffold.page.pageBody;
  const Divided = scaffold.page.divided;
  const MutationButton = scaffold.page.mutationButton;
  const Hyperlink = scaffold.page.hyperlink;
  return (
    <PageBody
      title="Vehicle authorization"
      footer={
        authorizeVehicles.isSuccess ? (
          <div className="flex justify-center">Redirecting...</div>
        ) : (
          <MutationButton
            mutate={mutate}
            errorMessage="Failed generating an authorization link. Please try again later."
            mutation={authorizeVehicles}
            mutateButtonText={
              <div className="flex flex-row gap-2 items-center justify-center w-full">
                <LinkIcon className="w-5 h-5" />
                Link Enode
              </div>
            }
          />
        )
      }
    >
      <Divided gap="6">
        <Item icon={<ChatBubbleLeftRightIcon />} label="Partnership with enode">
          Light parners with{" "}
          <Hyperlink
            href="https://enode.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Enode
          </Hyperlink>{" "}
          in order to gather information about your vehicle's charging history
          so that we can determine how much power was used during your charging
          period.
        </Item>
        <Item icon={<LinkIcon />} label="You will be redirected">
          By clicking "Link Enode" below, you will be sent to the Enode website
          in order to authorize Light to view information about your vehicle's
          charging history. After authorization, you will be sent back here to
          complete your enrollment.
        </Item>
        <Item icon={<IdentificationIcon />} label="Have your credentials ready">
          The authorization process involves signing into your vehicle's vendor
          account, so please have your credentials ready in order to complete
          this process.
        </Item>
      </Divided>
    </PageBody>
  );
};

export type ItemProps = DetailFieldProps & {
  icon: React.ReactNode;
};

export const Item: React.FC<ItemProps> = ({ icon, ...props }) => {
  const scaffold = useScaffold();
  const DetailField = scaffold.page.detail.detailField;
  return (
    <div className="flex flex-row gap-4 items-start">
      <div className="w-1/12">{icon}</div>
      <div className="w-11/12">
        <DetailField {...props} />
      </div>
    </div>
  );
};
