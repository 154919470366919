import { useScaffold } from "@Light/scaffold";
import { CheckIcon } from "@heroicons/react/24/solid";
import { BankIcon } from "./common/BankIcon";
import { Navigate } from "react-router-dom";
import clsx from "clsx";
import { EnsurePaymentMethodAddedRequest } from "@Light/services/lightTypes";
import { useLight } from "@Light/services/light";
import { useMutation } from "@Light/utils/mutation";
import { useCallback } from "react";

export type OnFilePaymentSetupProps = {};

export const OnFilePaymentSetup: React.FC = () => {
  const scaffold = useScaffold();
  const PageBody = scaffold.page.pageBody;
  const MutationButton = scaffold.page.mutationButton;

  const { useEnsurePaymentMethodAddedMutation } = useLight();
  const ensurePaymentMethodAdded = useMutation<EnsurePaymentMethodAddedRequest>(
    useEnsurePaymentMethodAddedMutation,
  );

  const mutate = useCallback(() => {
    ensurePaymentMethodAdded.mutate({});
  }, [ensurePaymentMethodAdded]);

  if (ensurePaymentMethodAdded.isSuccess) {
    return <Navigate to="/enroll/confirm-and-enroll" />;
  }

  return (
    <form onSubmit={mutate}>
      <PageBody
        title="Confirm your payment method"
        subtitle="You won't be charged until your next bill is due."
        progress="50%"
      >
        <div className="flex flex-col gap-6">
          <div
            className={clsx(
              "flex items-center gap-2 outline outline-1 outline-gray-200 rounded-lg p-4",
              scaffold.page.colors.text.secondary,
            )}
          >
            <BankIcon className="w-6 h-6 text-gray-600" />
            <p className="text-md font-regular">Bank account on file</p>
            <CheckIcon className="w-6 h-6 text-green-500 ml-auto" />
          </div>
          <div className="flex flex-col gap-2 text-sm text-gray-500">
            You can update your payment method later if needed.
          </div>
          <MutationButton
            mutation={ensurePaymentMethodAdded}
            mutate={mutate}
            mutateButtonText="Continue"
          />
          <div className="flex flex-col gap-2 text-xs text-gray-500">
            By selecting ‘Continue’, you authorize Light to debit your bank
            account on file with {scaffold.page.companyName} for the invoiced
            amount on the due date of each bill. You will receive your bill at
            least 16 days before we debit your account, and you may amend or
            cancel this authorization at any time.
          </div>
        </div>
      </PageBody>
    </form>
  );
};
