import { CheckAddress } from "./CheckAddress";
import { CheckESIID } from "./CheckESIID";
import { Navigate, Route, Routes } from "react-router-dom";
import { ServiceEvent } from "./ServiceEvent";
import { useEnroll } from "../context";
import { NotFound } from "./NotFound";
import { useSearchPreliminaryAddress } from "../preliminaryAddressHook";

export const PickAddress: React.FC = () => {
  const { address } = useEnroll();
  const [isLoading] = useSearchPreliminaryAddress();
  if (isLoading) {
    return null;
  }
  return (
    <Routes>
      <Route path="/by-esi-id" element={<CheckESIID />} />
      <Route path="/not-found" element={<NotFound />} />
      {address.val ? (
        <Route path="/service-event" element={<ServiceEvent />} />
      ) : null}
      <Route path="/" element={<CheckAddress />} />
      <Route path="/*" element={<Navigate to="/enroll/" />} />
    </Routes>
  );
};
