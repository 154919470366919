import { useScaffold } from "@Light/scaffold";
import { clsx } from "clsx";
import { useLocation } from "@Light/components/page/account";
import dayjs from "dayjs";
import { usePlan } from "@Light/utils/plan";

export type ListItemProps = React.ComponentProps<"div">;

export const ListItem: React.FC<ListItemProps> = ({ className, ...props }) => {
  const scaffold = useScaffold();
  return (
    <div
      className={clsx("text-md", scaffold.page.colors.text.primary, className)}
      {...props}
    />
  );
};

export const EnrollmentConfirmed: React.FC = () => {
  const location = useLocation();
  const plan = usePlan();
  const scaffold = useScaffold();
  const PageBody = scaffold.page.pageBody;
  const EnumeratedList = scaffold.page.enumeratedList;
  const EnrollmentConfirmedFooter =
    scaffold.enroll.steps.enrollmentConfirmedFooter;
  return (
    <PageBody
      progress="100%"
      title={`You've signed up for the ${scaffold.page.companyName} ${plan.plan.name} plan!`}
      subtitle="Here's what happens next:"
      footer={EnrollmentConfirmedFooter && <EnrollmentConfirmedFooter />}
    >
      <div className="w-full flex flex-col gap-8">
        <EnumeratedList>
          <ListItem>
            <span>You'll receive a service email confirmation within</span>{" "}
            <span className="font-medium">24-72 hours</span>.
          </ListItem>
          <ListItem>
            We’ll take care of switching from your current provider.
          </ListItem>
          <ListItem>
            <span>Your service is scheduled to begin on</span>{" "}
            <span className="font-medium">
              {dayjs(location.service_start_date).format("MMMM DD, YYYY")}
            </span>
            .
          </ListItem>
          <ListItem>
            Come back to the app to access your account when it is ready.
          </ListItem>
        </EnumeratedList>
      </div>
    </PageBody>
  );
};
