import { useScaffold } from "@Light/scaffold";
import { UsageData } from "./UsageData";
import { IntervalUsage } from "./IntervalUsage";

export type EnergyConsumptionProps = {};
export const EnergyConsumption: React.FC<EnergyConsumptionProps> = ({}) => {
  const scaffold = useScaffold();
  const { showIntervalUsage } = scaffold.dash;
  if (showIntervalUsage) {
    return <IntervalUsage />;
  }

  return <UsageData />;
};
