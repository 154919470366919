import { useScaffold } from "@Light/scaffold";
import clsx from "clsx";
import { OfferSummary } from "@Light/components/enroll/VerifyAndEnroll/OfferSummary";
import { usePlan } from "@Light/utils/plan";

export type WaitingForServiceProps = {};

export const WaitingForService: React.FC<WaitingForServiceProps> = ({}) => {
  const plan = usePlan();
  const scaffold = useScaffold();
  const PageBody = scaffold.page.pageBody;
  return (
    <PageBody>
      <div className="flex flex-col gap-6 pt-8">
        <div className={clsx("font-medium", scaffold.page.colors.text.primary)}>
          We're waiting for your service to start with the{" "}
          {scaffold.page.companyName} {plan.plan.name} plan
        </div>
        <div
          className={clsx(
            "flex flex-col gap-4",
            "text-xs",
            scaffold.page.colors.text.tertiary,
          )}
        >
          <OfferSummary label="You’re on your way to having" />
          <div className="text-sm">
            You'll receive an email confirmation once your service is up and
            running. No need to reach out to your old provider.
          </div>
        </div>
      </div>
    </PageBody>
  );
};
