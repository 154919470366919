import { QueryLoader } from "@Light/components/app/QueryLoader";
import { useDailyUsage } from "./interval";
import { DailyIntervalBody } from "./DailyIntervalBody";
import { DailySelector } from "./DailySelector";

export type DailyIntervalUsageProps = {};

export const DailyIntervalUsage: React.FC<DailyIntervalUsageProps> = ({}) => {
  const dailyUsage = useDailyUsage();
  return (
    <div className="flex flex-col gap-6">
      <div className="border-y -mx-4">
        <DailySelector />
      </div>
      <QueryLoader
        queries={[dailyUsage]}
        errorMessage="Failed querying usage data."
      >
        <DailyIntervalBody />
      </QueryLoader>
    </div>
  );
};
