export type OverlayProps = {
  children: React.ReactNode;
};

export const Overlay: React.FC<OverlayProps> = ({ children }) => {
  return (
    <div className="flex justify-center items-center sm:min-h-screen bg-black bg-opacity-40">
      <div
        className="flex flex-col *:w-full sm:max-w-[400px] mx-auto bg-white sm:rounded-lg shadow-lg overflow-hidden h-svh  md:h-screen sm:max-h-[750px] relative"
        style={{ aspectRatio: "16/9" }}
      >
        {children}
      </div>
    </div>
  );
};
