import { useCallback } from "react";
import { useScaffold } from "@Light/scaffold";
import { clsx } from "clsx";
import { ConfirmRatePopover } from "./ConfirmRatePopover";
import { WithPopover } from "@Light/components/page/popover/WithPopover";

export type ProductInfoProps = {};
export const ProductInfo: React.FC<ProductInfoProps> = () => {
  return (
    <WithPopover popover={<ConfirmRatePopover />}>
      {useCallback(({ open }) => {
        const scaffold = useScaffold();
        const Hyperlink = scaffold.page.hyperlink;
        return (
          <div className={clsx("text-sm", scaffold.page.colors.text.tertiary)}>
            <span className="inline">
              {scaffold.page.companyName} is powered by Light.{" "}
            </span>
            <Hyperlink onClick={open} className="inline cursor-pointer">
              Learn more
            </Hyperlink>
          </div>
        );
      }, [])}
    </WithPopover>
  );
};
