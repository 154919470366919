import { useAccount } from "@Light/components/page/account";
import { AddressSearchResult } from "@Light/services/lightTypes";
import { useLight } from "@Light/services/light";

const emptyList: AddressSearchResult[] = [];

export function useSearchPreliminaryAddress(): [
  boolean,
  AddressSearchResult[],
] {
  const account = useAccount();

  const { useSearchPreliminaryAddressQuery } = useLight();

  const location = account.locations?.[0];
  const fetchPreliminaryAddress = Boolean(account.preliminary_address);

  const preliminaryAddress = useSearchPreliminaryAddressQuery(undefined, {
    skip: Boolean(location || !fetchPreliminaryAddress),
  });
  if (location) {
    return [
      false,
      [
        {
          esi_id: location.utility_number,
          address_1: location.address_1,
          address_2: location.address_2,
          city: location.city,
          state: location.state,
          postal_code: location.postal_code,
        },
      ],
    ];
  }

  const results = preliminaryAddress.data || emptyList;
  const isLoading =
    preliminaryAddress.isLoading ||
    (!preliminaryAddress.isSuccess && !preliminaryAddress.isError);

  return [fetchPreliminaryAddress && isLoading, results];
}
