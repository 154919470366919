import { useCallback, useMemo } from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import { generateCalendarMonth, splitDate } from "./helpers";
import clsx from "clsx";
import { State, useState } from "@Light/utils/state";
import { DayButton } from "./DayButton";
import { useScaffold } from "@Light/scaffold";
import { usePopover } from "@Light/components/page/popover/WithPopover";
import { captureEvent } from "@Light/app/analytics";

type DateSelectorProps = {
  selectedDate: State<string>;
  minSelectableDate: string;
  maxSelectableDate: string;
};

export const DateSelector: React.FC<DateSelectorProps> = ({
  selectedDate,
  minSelectableDate,
  maxSelectableDate,
}: DateSelectorProps) => {
  const [minYear, minMonth] = splitDate(minSelectableDate);

  const year = useState(minYear);
  const month = useState(minMonth);
  const tempSelectedDate = useState(selectedDate.val || minSelectableDate);
  const { close } = usePopover();

  const days = useMemo(() => {
    return generateCalendarMonth(
      year.val,
      month.val,
      tempSelectedDate.val,
      minSelectableDate,
      maxSelectableDate,
    );
  }, [
    year.val,
    month.val,
    tempSelectedDate.val,
    minSelectableDate,
    maxSelectableDate,
  ]);

  const todayInSelectedMonth = useMemo(
    () => days.some((day) => day.isToday && day.isCurrentMonth),
    [days],
  );

  const monthName = useMemo(
    () =>
      new Date(year.val, month.val).toLocaleString("default", {
        month: "long",
      }),
    [year.val, month.val],
  );

  const shortYear = useMemo(() => String(year.val).slice(-2), [year.val]);

  const onPreviousMonth = useCallback(() => {
    if (todayInSelectedMonth) {
      return;
    }
    month.setVal(month.val - 1);
    if (month.val === 0) {
      year.setVal(year.val - 1);
    }
    captureEvent("enroll_select_date_prev_month");
  }, [todayInSelectedMonth, month.val, year.val]);

  const onNextMonth = useCallback(() => {
    month.setVal(month.val + 1);
    if (month.val === 11) {
      year.setVal(year.val + 1);
    }
    captureEvent("enroll_select_date_next_month");
  }, [month.val, year.val]);

  const onSelect = useCallback(() => {
    selectedDate.setVal(tempSelectedDate.val);
    close();
    captureEvent("enroll_select_date_select");
  }, [tempSelectedDate.val, close]);

  const scaffold = useScaffold();
  const Button = scaffold.page.button;
  return (
    <div>
      <div>
        <div className="flex flex-col mt-10 text-center gap-4">
          <div className="flex items-center text-gray-900">
            <button
              type="button"
              className={clsx(
                "-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500",
                todayInSelectedMonth && "opacity-0",
              )}
              disabled={todayInSelectedMonth}
              onClick={onPreviousMonth}
            >
              <span className="sr-only">Previous month</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            <div className="flex-auto text-sm font-semibold">
              {monthName} {"'"}
              {shortYear}
            </div>
            <button
              type="button"
              className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
              onClick={onNextMonth}
            >
              <span className="sr-only">Next month</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
          <div className="flex flex-col">
            <div className="grid grid-cols-7 text-xs leading-6 text-gray-500">
              <div>S</div>
              <div>M</div>
              <div>T</div>
              <div>W</div>
              <div>T</div>
              <div>F</div>
              <div>S</div>
            </div>
            <div className="isolate grid grid-cols-7 gap-px rounded-lg bg-gray-200 text-sm shadow ring-1 ring-gray-200">
              {days.map((day, dayIdx) => (
                <DayButton
                  key={day.date}
                  day={day}
                  dayIdx={dayIdx}
                  totalDays={days.length}
                  minSelectableDate={minSelectableDate}
                  maxSelectableDate={maxSelectableDate}
                  tempSelectedDate={tempSelectedDate}
                />
              ))}
            </div>
          </div>
          <Button className={clsx("w-full")} onClick={onSelect}>
            Select date
          </Button>
        </div>
      </div>
    </div>
  );
};
