import { useScaffold } from "@Light/scaffold";
import { useLight } from "@Light/services/light";
import { BillingInvoice } from "./BillingInvoice";
import { useHeader } from "@Light/components/page/header/context";
import { QueryLoader } from "@Light/components/app/QueryLoader";
import {
  BillingInvoice as BillingInvoiceType,
  BillingInvoiceDetails,
} from "@Light/services/lightTypes";
import { useParams } from "@Light/utils/context";

const PREVIOUS_ROUTE = "/dash/billing/";

export const BillingInvoiceRoute: React.FC = () => {
  const scaffold = useScaffold();
  const { invoiceNumber } = useParams();
  if (!invoiceNumber) {
    const Navigate = scaffold.system.navigate;
    return <Navigate to={PREVIOUS_ROUTE} />;
  }
  return <BillingInvoiceRouteB1 invoiceNumber={invoiceNumber} />;
};

export type BillingInvoiceRouteB1Props = {
  invoiceNumber: string;
};

export const BillingInvoiceRouteB1: React.FC<BillingInvoiceRouteB1Props> = ({
  invoiceNumber,
}) => {
  const scaffold = useScaffold();
  const { useGetBillingInvoiceQuery, useGetBillingInvoicesQuery } = useLight();
  const billingInvoice = useGetBillingInvoiceQuery({ invoiceNumber });
  const billingInvoices = useGetBillingInvoicesQuery();
  const { setBackNavigate } = useHeader();

  setBackNavigate(PREVIOUS_ROUTE);

  const PageBody = scaffold.page.pageBody;
  return (
    <PageBody>
      <QueryLoader
        queries={[billingInvoice, billingInvoices]}
        errorMessage="Error loading invoice data. Please try again later."
      >
        <BillingInvoice
          invoice={billingInvoice.data as BillingInvoiceDetails}
          invoices={billingInvoices.data?.data as BillingInvoiceType[]}
        />
      </QueryLoader>
    </PageBody>
  );
};
