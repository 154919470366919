import clsx from "clsx";
import { useScaffold } from "../../scaffold";
export type SubtitleProps = React.ComponentProps<"div">;
export function Subtitle({ children, className, ...props }: SubtitleProps) {
  const scaffold = useScaffold();
  return (
    <div
      className={clsx(
        "font-normal text-md",
        scaffold.page.colors.text.tertiary,
        className,
      )}
      {...props}
    >
      {children}
    </div>
  );
}
