import { useUpdateBillingAddressForm } from "@Light/components/page/form/UpdateBillingAddress";
import { useHeader } from "@Light/components/page/header/context";
import { useScaffold } from "@Light/scaffold";
import React from "react";

export const BACK_ROUTE = "/account/details/";

export type UpdateBillingAddressProps = {};

export const UpdateBillingAddress: React.FC<
  UpdateBillingAddressProps
> = ({}) => {
  const scaffold = useScaffold();
  const { setTitle, setBackNavigate } = useHeader();
  const { onSubmit, disabled, mutation, form } = useUpdateBillingAddressForm();

  setTitle("Update your billing address");
  setBackNavigate(BACK_ROUTE);

  if (mutation.isSuccess) {
    const Navigate = scaffold.system.navigate;
    return <Navigate to={BACK_ROUTE} />;
  }

  const PageBody = scaffold.page.pageBody;
  const MutationButton = scaffold.page.mutationButton;
  return (
    <form>
      <PageBody
        footer={
          <MutationButton
            mutation={mutation}
            mutateButtonText="Confirm"
            mutate={onSubmit}
            errorMessage="Error updating billing address. Please try again later."
            disabled={disabled}
          />
        }
      >
        <div className="pt-8 flex flex-col gap-4">{form}</div>
      </PageBody>
    </form>
  );
};
