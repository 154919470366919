import { Navigate, Route, Routes } from "react-router-dom";
import { LoginPage } from "./LoginPage";
import { Login } from "./Login";
import { LoginToken } from "./LoginToken";
import { CheckEmail } from "./CheckEmail";

export type LoginRoutesProps = {};

export const LoginRoutes: React.FC<LoginRoutesProps> = ({}) => {
  return (
    <LoginPage>
      <Routes>
        <Route path="" element={<Login />} />
        <Route path="/check-email" element={<CheckEmail />} />
        <Route path="/token" element={<LoginToken />} />
        <Route path="/*" element={<Navigate to="/login" />} />
      </Routes>
    </LoginPage>
  );
};
