import { QueryLoader } from "@Light/components/app/QueryLoader";
import { useLight } from "@Light/services/light";
import { CreatePaymentSessionResponse } from "@Light/services/lightTypes";
import { useMutation } from "@Light/utils/mutation";
import React, { createContext, useContext, useEffect } from "react";

export type PaymentSessionState = CreatePaymentSessionResponse;

export const PaymentSessionContext = createContext<
  PaymentSessionState | undefined
>(undefined);

export type PaymentSessionProps = {
  children: React.ReactNode;
};

export const PaymentSession: React.FC<PaymentSessionProps> = ({ children }) => {
  const { useCreatePaymentSessionMutation } = useLight();
  const createPaymentSession = useMutation<void, CreatePaymentSessionResponse>(
    useCreatePaymentSessionMutation,
  );

  useEffect(() => {
    createPaymentSession.mutate();
  }, [createPaymentSession.mutate]);

  return (
    <QueryLoader
      queries={[createPaymentSession]}
      errorMessage="Failed initializing payments. Please try again later."
    >
      <PaymentSessionContext.Provider
        value={createPaymentSession.data as PaymentSessionState}
      >
        {children}
      </PaymentSessionContext.Provider>
    </QueryLoader>
  );
};

export function usePaymentSession() {
  const paymentSession = useContext(PaymentSessionContext);
  if (!paymentSession) {
    throw new Error(
      "PaymentSession context must be used to use usePaymentSession",
    );
  }

  return paymentSession;
}
