import { Popover } from "@headlessui/react";
import { Link } from "./Link";
import { clsx } from "clsx";
export type MobileNavLinkProps = Omit<
  React.ComponentPropsWithoutRef<typeof Popover.Button<typeof Link>>,
  "as"
>;
export const MobileNavLink: React.FC<MobileNavLinkProps> = ({
  className,
  ...props
}: MobileNavLinkProps) => {
  return (
    <Popover.Button
      as={Link}
      className={clsx(
        "block text-base leading-7 tracking-tight text-gray-700",
        className,
      )}
      {...props}
    />
  );
};
