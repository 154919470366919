import React from "react";
import { ServiceDetails } from "./ServiceDetails";
import { Navigate, Routes, Route } from "react-router-dom";
import { MoveOrCancel } from "./MoveOrCancel";
import { CancelService } from "./MoveOrCancel/CancelService";
import { CancelConfirmed } from "./MoveOrCancel/CancelConfirmed";

export type ServiceDetailsRouteProps = {};

export const ServiceDetailsRoute: React.FC<ServiceDetailsRouteProps> = ({}) => {
  return (
    <Routes>
      <Route path="/" element={<ServiceDetails />} />
      <Route path="/move-or-cancel" element={<MoveOrCancel />} />
      <Route path="/cancel/" element={<CancelService />} />
      <Route path="/cancel/confirmed" element={<CancelConfirmed />} />
      <Route path="/*" element={<Navigate to="/" />} />
    </Routes>
  );
};
