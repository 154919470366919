import { useStripe } from "@Light/app/stripe";
import { useAccount } from "@Light/components/page/account";
import { useEffect } from "react";

export type WithStripeProps = {
  children: React.ReactNode;
};

export const WithStripe: React.FC<WithStripeProps> = ({ children }) => {
  const account = useAccount();
  const { setPublicKey } = useStripe();

  useEffect(() => {
    if (account.app.stripe_public_key) {
      setPublicKey(account.app.stripe_public_key);
    }
  }, [account, setPublicKey]);

  return children;
};
