import React from "react";
import { ScaffoldContext } from "./context";
import { Scaffold } from "./scaffold";
import { defaultScaffold } from "./default";

export type ProviderProps = {
  scaffold?: Scaffold;
  children: React.ReactNode;
};

export function Provider({ scaffold, children }: ProviderProps) {
  return (
    <ScaffoldContext.Provider value={scaffold ?? defaultScaffold}>
      {children}
    </ScaffoldContext.Provider>
  );
}
