import React from "react";
import {
  Button as HeadlessButton,
  type ButtonProps as HeadlessButtonProps,
} from "@headlessui/react";
import clsx from "clsx";
import { useScaffold, Scaffold } from "@Light/scaffold";

export type ButtonType = "plain" | "outline" | "clear";
export type ButtonStyle = "plain" | "error";

export type ButtonProps = HeadlessButtonProps & {
  buttonType?: ButtonType;
  buttonStyle?: ButtonStyle;
};

export function buttonClassFromType(
  scaffold: Scaffold,
  buttonType: ButtonType,
  buttonStyle: ButtonStyle,
  disabled?: boolean,
) {
  if (buttonType === "plain") {
    return clsx(
      "text-white p-3 rounded-lg",
      disabled
        ? buttonStyle === "error"
          ? scaffold.page.colors.button.background.errorDisabled
          : scaffold.page.colors.button.background.plainDisabled
        : buttonStyle === "error"
          ? scaffold.page.colors.button.background.error
          : scaffold.page.colors.button.background.plain,
    );
  }
  if (buttonType === "outline") {
    return "border-2 rounded-lg p-3 font-medium";
  }
  return "";
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      buttonType = "plain",
      buttonStyle = "plain",
      disabled,
      className,
      children,
      ...props
    },
    ref,
  ) => {
    const scaffold = useScaffold();
    return (
      <HeadlessButton
        ref={ref}
        className={clsx(
          "rounded-lg p-3",
          disabled ? "" : "cursor-pointer",
          buttonClassFromType(scaffold, buttonType, buttonStyle, disabled),
          className,
        )}
        disabled={Boolean(disabled)}
        {...props}
      >
        {children}
      </HeadlessButton>
    );
  },
);

Button.displayName = "Button";
