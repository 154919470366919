import { useScaffold } from "@Light/scaffold";
import { Card } from "./Card";
import clsx from "clsx";
import { usePlan } from "@Light/utils/plan";

export type VehicleChargingCardProps = {};

export const VehicleChargingCard: React.FC<VehicleChargingCardProps> = ({}) => {
  const scaffold = useScaffold();
  const plan = usePlan();
  const { vehicleCharging } = plan.features;
  if (!vehicleCharging) {
    return null;
  }
  return (
    <Card label="Unlimited vehicle charging">
      <div className="flex flex-col gap-3">
        <div className={clsx("font-medium", scaffold.page.colors.text.primary)}>
          <span className="text-2xl">{vehicleCharging.rateText}</span>
          <span className="text-lg">/month per vehicle</span>
        </div>
        <div className={clsx("text-sm", scaffold.page.colors.text.tertiary)}>
          At home between {vehicleCharging.chargeStart} -{" "}
          {vehicleCharging.chargeEnd}
        </div>
      </div>
    </Card>
  );
};
