import { useScaffold } from "@Light/scaffold";
import { clsx } from "clsx";
import { Card } from "../ConfirmRate/Card";
import { SolarBuybackIcon } from "../ConfirmRate/SolarBuybackIcon";
import { RenewableIcon } from "../ConfirmRate/RenewableIcon";
import { FixedRateIcon } from "../ConfirmRate/FixedRateIcon";
import { VehicleChargingIcon } from "../ConfirmRate/VehicleChargingIcon";
import { usePlan } from "@Light/utils/plan";
import { CheckCircleIcon } from "@heroicons/react/24/outline";

export type OfferSummaryProps = {
  label?: string;
};

export const OfferSummary: React.FC<OfferSummaryProps> = ({ label }) => {
  const plan = usePlan();
  const scaffold = useScaffold();
  const {
    solarBuyback,
    storageCredit,
    vehicleCharging,
    fixedRate,
    noCancellationFees,
    renewableEnergy,
  } = plan.features;

  if (
    !solarBuyback &&
    !storageCredit &&
    !vehicleCharging &&
    !fixedRate &&
    !noCancellationFees &&
    !renewableEnergy
  ) {
    return null;
  }

  const CircleIcon = scaffold.page.circleIcon;
  const isPalmetto = scaffold.page.companyName === "Palmetto";
  const isJustSolarBuyback = Boolean(solarBuyback && !storageCredit);
  const isSolarBuybackWithStorage = Boolean(solarBuyback && storageCredit);
  return (
    <Card label={label ?? "You're one step from having"}>
      <div
        className={clsx(
          "flex flex-col gap-2",
          "text-sm",
          scaffold.page.colors.text.secondary,
        )}
      >
        {isPalmetto && (
          <Item>
            <CircleIcon className="bg-slate-50">
              <CheckCircleIcon className="w-4 text-slate-500" />
            </CircleIcon>
            <div>All your power in one place</div>
          </Item>
        )}
        {isJustSolarBuyback && (
          <Item>
            <SolarBuybackIcon />
            <div>Unlimited credit for solar buyback</div>
          </Item>
        )}
        {isSolarBuybackWithStorage && (
          <Item>
            <SolarBuybackIcon />
            <div>Rewards for solar buyback & battery use</div>
          </Item>
        )}
        {!isPalmetto && fixedRate && (
          <Item>
            <FixedRateIcon />
            <div>
              A locked-in rate for the next {fixedRate.termMonths} months
            </div>
          </Item>
        )}
        {renewableEnergy && (
          <Item>
            <RenewableIcon />
            <div>100% renewable home energy</div>
          </Item>
        )}
        {vehicleCharging && (
          <Item>
            <VehicleChargingIcon />
            <div>
              Unlimited vehicle charging for {vehicleCharging.rateText}/month
            </div>
          </Item>
        )}
      </div>
    </Card>
  );
};

export type ItemProps = {
  children: React.ReactNode;
};

export const Item: React.FC<ItemProps> = ({ children }) => {
  return <div className="flex items-center gap-2">{children}</div>;
};
