import { useScaffold } from "@Light/scaffold";
import clsx from "clsx";
import { useHeader } from "@Light/components/page/header/context";
import { useMemo } from "react";
import { usePlan } from "@Light/utils/plan";

export const Welcome: React.FC = () => {
  const { setRightButton, setDivider } = useHeader();

  setRightButton(useMemo(() => <div />, []));
  setDivider(false);

  const plan = usePlan();
  const scaffold = useScaffold();
  const PageBody = scaffold.page.pageBody;
  const EnumeratedList = scaffold.page.enumeratedList;
  const InfoItem = scaffold.page.infoItem;
  const Button = scaffold.page.button;
  const Link = scaffold.system.link;
  return (
    <PageBody
      footer={
        <Link to="/enroll/service-address" className="w-full">
          <Button className="w-full">Check eligibility</Button>
        </Link>
      }
    >
      <div className="flex flex-col gap-8 pt-8">
        <div
          className={clsx(
            "font-medium text-3xl",
            scaffold.page.colors.text.primary,
          )}
        >
          Hello
        </div>
        <div className={clsx("text-xl", scaffold.page.colors.text.secondary)}>
          In a few simple steps, start enjoying renewable grid electricity for
          your home
        </div>
        <EnumeratedList>
          <InfoItem title="Check eligibility">
            Enter your address to see if our service is available in your area
          </InfoItem>
          <InfoItem title="Review your offer">
            Learn about the benefits and pricing of our plan tailored for your
            needs
          </InfoItem>
          <InfoItem title="Accept your offer">
            Confirm your enrollment and start enjoying the value of your{" "}
            {scaffold.page.companyName} {plan.plan.name} plan
          </InfoItem>
        </EnumeratedList>
      </div>
    </PageBody>
  );
};
