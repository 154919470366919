import React, { useRef } from "react";
import { useScaffold } from "@Light/scaffold";
import { clsx } from "clsx";
import { MenuHeaderTitle } from "./MenuHeaderTitle";

export type MenuHeaderProps = React.ComponentProps<"header"> & {
  titleLink?: string;
  leftButton?: React.ReactNode;
  rightButton?: React.ReactNode;
};

export const MenuHeader: React.FC<MenuHeaderProps> = React.forwardRef<
  HTMLElement,
  MenuHeaderProps
>(
  (
    { children, titleLink, leftButton, rightButton, className, ...props },
    ref,
  ) => {
    const innerHeaderRef = useRef<HTMLDivElement>(null);
    const titleRef = useRef<HTMLDivElement>(null);

    const scaffold = useScaffold();
    const Link = scaffold.system.link;

    children = <MenuHeaderTitle>{children}</MenuHeaderTitle>;
    if (titleLink) {
      children = (
        <Link className="items-center" to={titleLink}>
          {children}
        </Link>
      );
    }

    return (
      <header
        {...props}
        ref={ref}
        className={clsx("pt-6 pb-4 px-3", className)}
      >
        <div
          ref={innerHeaderRef}
          className="flex justify-between items-center w-full"
        >
          <div className="w-1/6">
            {leftButton || <div className="w-10 h-10" />}
          </div>
          <div ref={titleRef} className="w-2/3 flex justify-center">
            <div className="flex items-center justify-center">{children}</div>
          </div>
          <div className="w-1/6 flex justify-end">
            {rightButton || <div className="w-10 h-10" />}
          </div>
        </div>
      </header>
    );
  },
);
