import { useLight } from "@Light/services/light";
import { EnsurePaymentMethodAddedRequest } from "@Light/services/lightTypes";
import { Mutation, useMutation } from "@Light/utils/mutation";
import { useState } from "@Light/utils/state";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { useCallback, useEffect } from "react";
import { FieldValues, UseFormRegister, useForm } from "react-hook-form";
import { usePaymentSession } from "@Light/components/page/payment/PaymentSession";
import { State } from "@Light/utils/state";
import { captureError, captureMessage } from "@Light/app/sentry";

export type UpdatePaymentMethodForm = {
  onSubmit: () => void;
  mutation: Mutation<EnsurePaymentMethodAddedRequest>;
  form: React.ReactNode;
  stripeError?: string;
  miscError?: string;
};

export function useUpdatePaymentMethodForm(
  collectZip: boolean,
): UpdatePaymentMethodForm {
  const isSubmitting = useState(false);
  const wasSuccessful = useState<boolean | undefined>(undefined);
  const stripeError = useState<string | undefined>(undefined);
  return useUpdatePaymentMethodFormB1({
    isSubmitting,
    wasSuccessful,
    stripeError,
    collectZip,
  });
}

export type UseUpdatePaymentMethodFormB1Props = {
  isSubmitting: State<boolean>;
  wasSuccessful: State<boolean | undefined>;
  stripeError: State<string | undefined>;
  collectZip: boolean;
};

export function useUpdatePaymentMethodFormB1({
  isSubmitting,
  wasSuccessful,
  stripeError,
  collectZip,
}: UseUpdatePaymentMethodFormB1Props): UpdatePaymentMethodForm {
  const { useEnsurePaymentMethodAddedMutation } = useLight();
  const ensurePaymentMethodAdded = useMutation<EnsurePaymentMethodAddedRequest>(
    useEnsurePaymentMethodAddedMutation,
  );
  const { register, handleSubmit, watch, setValue } = useForm();

  const zip = collectZip ? watch("zip") : undefined;

  useEffect(() => {
    if (!zip) {
      return;
    }

    // strip any non-digit characters
    let digits = zip.replace(/\D/g, "");

    if (digits.length > 5) {
      digits = digits.slice(0, 5);
    }

    if (digits !== zip) {
      setValue("zip", digits);
    }
  }, [zip, setValue]);

  const stripe = useStripe();
  const elements = useElements();
  const paymentSession = usePaymentSession();

  const client_secret = paymentSession.client_secret;
  const onSubmit = useCallback(
    handleSubmit(({ zip }) => {
      if (!stripe || !elements || isSubmitting.val || !client_secret) {
        captureMessage("Failed to update payment method", {
          noStripe: !stripe,
          noElements: !elements,
          isSubmitting: isSubmitting.val,
          noClientSecret: !client_secret,
        });
        return;
      }

      isSubmitting.setVal(true);
      wasSuccessful.setVal(undefined);
      stripeError.setVal(undefined);

      stripe
        .confirmCardSetup(client_secret, {
          // Format: https://docs.stripe.com/api/payment_methods/object
          payment_method: {
            card: elements.getElement(CardNumberElement)!,
            billing_details: {
              address: {
                postal_code: zip ?? undefined,
              },
            },
          },
        })
        .then(({ error, setupIntent }) => {
          if (error?.message) {
            wasSuccessful.setVal(false);
            stripeError.setVal(error.message);

            captureMessage("Stripe error", {
              stripeError: error.message,
            });

            return;
          }
          stripeError.setVal(undefined);

          if (!setupIntent?.payment_method) {
            wasSuccessful.setVal(false);
            captureMessage("No payment method returned from Stripe");
            return;
          }

          wasSuccessful.setVal(true);
          ensurePaymentMethodAdded.mutate({
            payment_method_id: setupIntent.payment_method as string,
          });
        })
        .catch((error) => {
          wasSuccessful.setVal(false);
          stripeError.setVal(undefined);

          captureError(error);
        })
        .finally(() => {
          isSubmitting.setVal(false);
        });
    }),
    [
      handleSubmit,
      isSubmitting.val,
      client_secret,
      stripe,
      elements,
      isSubmitting.setVal,
      ensurePaymentMethodAdded.mutate,
    ],
  );

  const mutation = {
    ...ensurePaymentMethodAdded,
    isLoading: Boolean(isSubmitting.val || ensurePaymentMethodAdded.isLoading),
    isSuccess: Boolean(wasSuccessful.val && ensurePaymentMethodAdded.isSuccess),
    error:
      ensurePaymentMethodAdded.error ??
      (wasSuccessful.val === false
        ? { status: 400, data: "Failed submitting payment method" }
        : undefined),
  };
  if (!stripe || !elements) {
    return {
      onSubmit,
      mutation,
      form: null,
      stripeError: stripeError.val,
    };
  }

  return {
    onSubmit,
    mutation,
    form: <UpdatePaymentMethod register={register} collectZip={collectZip} />,
    stripeError: stripeError.val,
  };
}

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      fontFamily: 'Inter var, "Helvetica Neue", sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#b2b2c0",
      },
      ":-webkit-autofill": {
        color: "#32325d",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

export type UpdatePaymentMethodProps = {
  register: UseFormRegister<FieldValues>;
  collectZip: boolean;
};

export const UpdatePaymentMethod: React.FC<UpdatePaymentMethodProps> = ({
  register,
  collectZip,
}) => {
  return (
    <div>
      <label className="block text-sm font-medium text-gray-700">
        Card number
      </label>
      <div className="border border-gray-300 rounded p-3 mt-1">
        <CardNumberElement
          options={{ ...CARD_ELEMENT_OPTIONS, showIcon: true }}
        />
      </div>
      <div className="flex mt-4">
        <div className="w-1/2 mr-2">
          <label className="block text-sm font-medium text-gray-700">
            Expiration
          </label>
          <div className="border border-gray-300 rounded p-3 mt-1">
            <CardExpiryElement options={CARD_ELEMENT_OPTIONS} />
          </div>
        </div>
        <div className={collectZip ? "w-1/3 mx-2" : "w-1/2 ml-2"}>
          <label className="block text-sm font-medium text-gray-700">CVC</label>
          <div className="border border-gray-300 rounded p-3 mt-1">
            <CardCvcElement options={CARD_ELEMENT_OPTIONS} />
          </div>
        </div>
        {collectZip && (
          <div className="w-1/2 ml-2">
            <label className="block text-sm font-medium text-gray-700">
              ZIP
            </label>
            <input
              type="number"
              className="border border-gray-300 rounded p-2.5 mt-1 w-full placeholder:text-gray-400 font-light"
              {...register("zip", {
                required: true,
                minLength: 5,
                maxLength: 5,
              })}
            />
          </div>
        )}
      </div>
    </div>
  );
};
