import { Hyperlink } from "@Light/components/page/Hyperlink";
import { useScaffold } from "@Light/scaffold";
import clsx from "clsx";
import React from "react";

export type ServiceLinkProps = {
  label: string;
  href: string;
  children: React.ReactNode;
};

export const ServiceLink: React.FC<ServiceLinkProps> = ({
  label,
  href,
  children,
}) => {
  const scaffold = useScaffold();
  return (
    <div className="flex flex-col gap-1">
      <div
        className={clsx(
          "text-sm font-medium",
          scaffold.page.colors.text.tertiary,
        )}
      >
        {label}
      </div>
      <Hyperlink
        className={clsx(
          "no-underline font-medium text-sm",
          scaffold.page.colors.text.info,
        )}
        href={href}
      >
        {children}
      </Hyperlink>
    </div>
  );
};
