import { clsx } from "clsx";

export type VerticalProps = {
  children: React.ReactNode;
  className?: string;
};
export const Vertical: React.FC<VerticalProps> = ({
  children,
  className,
}: VerticalProps) => {
  return (
    <div className={clsx("flex flex-col gap-2", className)}>{children}</div>
  );
};
