import { useNavigate } from "@Light/utils/context";
import { State } from "@Light/utils/state";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react";
export type OnBack = () => void;
export type HeaderState = {
  onBack: State<OnBack | undefined>;
  contentHeight: State<number | undefined>;
  keyboardOpen: State<boolean>;
  isHidden: State<boolean>;
  divider: State<boolean>;
  titleText: State<string | undefined>;
  leftButton: State<React.ReactNode | undefined>;
  rightButton: State<React.ReactNode | undefined>;
};
export const HeaderContext = createContext<HeaderState | undefined>(undefined);
export function useHeader() {
  const header = useContext(HeaderContext);
  const navigate = useNavigate();
  const sizeProps = useMemo(() => {
    if (!header?.contentHeight?.val) {
      return {
        style: {
          height: "100%",
        },
      };
    }
    return {
      style: { height: `${header.contentHeight.val}px` },
    };
  }, [header?.contentHeight?.val]);
  const useHeaderEffect = useCallback(
    (effect: (header: HeaderState) => any) => {
      useEffect(() => {
        // Only call the effect function if there is a configured header context.
        // If not, this should no-op.
        if (!header) {
          return;
        }
        return effect(header);
      }, [header, effect]);
    },
    [header],
  );
  const setBack = useCallback(
    (onBack?: OnBack) => {
      useHeaderEffect(
        useCallback(
          (header) => {
            header.onBack.setVal(() => onBack);
            return () => {
              header.onBack.setVal(undefined);
            };
          },
          [onBack],
        ),
      );
    },
    [useHeaderEffect],
  );
  const setBackNavigate = useCallback(
    (route: string) => {
      setBack(useCallback(() => navigate(route), [navigate, route]));
    },
    [navigate, setBack],
  );
  const setTitle = useCallback(
    (title: string) => {
      useHeaderEffect(
        useCallback(
          (header) => {
            header.titleText.setVal(title);
            return () => {
              header.titleText.setVal(undefined);
            };
          },
          [title],
        ),
      );
    },
    [useHeaderEffect],
  );
  const hide = useCallback(() => {
    useHeaderEffect(
      useCallback((header) => {
        header.isHidden.setVal(true);
        return () => {
          header.isHidden.setVal(false);
        };
      }, []),
    );
  }, [useHeaderEffect]);
  const setDivider = useCallback(
    (divider: boolean) => {
      useHeaderEffect(
        useCallback(
          (header) => {
            header.divider.setVal(divider);
            return () => {
              header.divider.setVal(true);
            };
          },
          [divider],
        ),
      );
    },
    [useHeaderEffect],
  );
  const setLeftButton = useCallback(
    (leftButton: React.ReactNode) => {
      useHeaderEffect(
        useCallback(
          (header) => {
            header.leftButton.setVal(leftButton);
            return () => {
              header.leftButton.setVal(undefined);
            };
          },
          [leftButton],
        ),
      );
    },
    [useHeaderEffect],
  );
  const setRightButton = useCallback(
    (rightButton: React.ReactNode) => {
      useHeaderEffect(
        useCallback(
          (header) => {
            header.rightButton.setVal(rightButton);
            return () => {
              header.rightButton.setVal(undefined);
            };
          },
          [rightButton],
        ),
      );
    },
    [useHeaderEffect],
  );
  return {
    sizeProps,
    isKeyboardOpen: header?.keyboardOpen.val,
    setBack,
    setBackNavigate,
    setTitle,
    hide,
    setDivider,
    setLeftButton,
    setRightButton,
  };
}
