import clsx from "clsx";
import { useScaffold } from "@Light/scaffold";
export type TitleProps = {
  children: React.ReactNode;
};
export function Title({ children }: TitleProps) {
  const scaffold = useScaffold();
  return (
    <div
      className={clsx(
        "font-medium text-2xl",
        scaffold.page.colors.text.primary,
      )}
    >
      {children}
    </div>
  );
}
