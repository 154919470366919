import { useScaffold } from "@Light/scaffold";
import dayjs from "dayjs";
import clsx from "clsx";
import {
  BillingInvoice as BillingInvoiceType,
  BillingInvoiceDetails,
} from "@Light/services/lightTypes";
import { InvoiceStatusChip } from "./InvoiceStatusChip";
import { useMemo } from "react";
import { InvoiceComparison } from "./InvoiceComparison";
import { ChargeGroup } from "./ChargeGroup";
import { DollarAmount } from "./DollarAmount";
import { ArrowRightEndOnRectangleIcon } from "@heroicons/react/24/solid";
import { usePaymentDays } from "../Billing/common";
import { ArrowRightIcon } from "@heroicons/react/24/outline";

export type BillingInvoiceProps = {
  invoice: BillingInvoiceDetails;
  invoices: BillingInvoiceType[];
};

export const BillingInvoice: React.FC<BillingInvoiceProps> = ({
  invoice,
  invoices,
}) => {
  const { month, year } = useMemo(() => {
    const date = dayjs(invoice.invoice_date);
    const month = date.month();
    const year = date.year();
    return { month, year };
  }, [invoice.invoice_date]);
  const lastMonth = month === 0 ? 11 : month - 1;
  const lastYear = year - 1;
  const lastInvoice = useMemo(
    () =>
      invoices.find((invoice) => {
        const date = dayjs(invoice.invoice_date);
        return date.month() === lastMonth && date.year() === year;
      }),
    [invoices, lastMonth, year],
  );
  const lastYearInvoice = useMemo(
    () =>
      invoices.find((invoice) => {
        const date = dayjs(invoice.invoice_date);
        return date.month() === month && date.year() === lastYear;
      }),
    [invoices, month, lastYear],
  );
  const paymentDays = usePaymentDays(invoice);
  const isPastDue = paymentDays < 0 && !invoice.paid_at;

  const scaffold = useScaffold();
  const Divided = scaffold.page.divided;
  const Link = scaffold.system.link;
  return (
    <div className="flex flex-col gap-6 pt-8">
      <div className="flex flex-col justify-center items-center text-center gap-3 pt-4 pb-3">
        <div className="flex flex-col gap-0.5">
          <div className={clsx("text-xs", scaffold.page.colors.text.secondary)}>
            {dayjs(invoice.invoice_date).format("MMMM D, YYYY")}
          </div>
          <div className={clsx("text-3xl", scaffold.page.colors.text.primary)}>
            ${invoice.total}
          </div>
        </div>
        <div className="flex flex-col justify-center items-center gap-4">
          <div className="flex">
            <InvoiceStatusChip
              invoice={invoice}
              className="text-xs font-semibold"
            />
          </div>
          {isPastDue && (
            <Link to="/account/payment-method/update">
              <div
                className={clsx(
                  "flex flex-row items-center gap-1 font-medium text-sm",
                )}
              >
                <div>Update payment method</div>
                <ArrowRightIcon className="w-4 h-4" />
              </div>
            </Link>
          )}
        </div>
      </div>
      <div className="flex flex-col gap-4">
        <div className="flex justify-center items-center">
          <div className={clsx("text-xs", scaffold.page.colors.text.tertiary)}>
            Invoice #{invoice.number}
          </div>
        </div>
        <div className="border rounded flex flex-row justify-between divide-x items-start h-[70px]">
          <InvoiceComparison
            label="This month"
            invoice={invoice}
            dateFormat="MMMM YYYY"
          />
          <InvoiceComparison
            label="Last month"
            invoice={lastInvoice}
            dateFormat="MMMM YYYY"
          />
          <InvoiceComparison
            label="Last year"
            invoice={lastYearInvoice}
            dateFormat="MMMM D, YYYY"
          />
        </div>
      </div>
      <Divided className="py-4">
        <Divided>
          {invoice.charge_groups.map((chargeGroup) => (
            <ChargeGroup key={chargeGroup.key} chargeGroup={chargeGroup} />
          ))}
        </Divided>
        <div className="flex flex-row justify-between w-full">
          <div className="text-lg font-medium">Total amount payable</div>
          <div className="flex flex-row">
            <DollarAmount
              className={clsx(
                "text-lg font-medium",
                scaffold.page.colors.text.primary,
              )}
              amount={invoice.total}
              isPastDue={isPastDue}
            />
            <div className="w-6" />
          </div>
        </div>
      </Divided>
      {invoice.payment_due_date && !invoice.paid_at ? (
        <div
          className={clsx(
            "flex flex-row justify-between",
            "text-blue-950 bg-blue-50 border border-blue-200",
            "rounded px-2 py-4 text-sm",
          )}
        >
          <div>Automatic payment date</div>
          <div>{dayjs(invoice.payment_due_date).format("MMMM D, YYYY")}</div>
        </div>
      ) : null}
      <a href={invoice.pdf} target="_blank" rel="noopener noreferrer">
        <div className="flex flex-row w-full justify-center items-center pb-4">
          <div className="flex flex-row gap-2 items-center text-blue-950 text-sm">
            <div className="">Download PDF</div>
            <ArrowRightEndOnRectangleIcon className="w-6 h-6" />
          </div>
        </div>
      </a>
    </div>
  );
};
