import { useScaffold } from "@Light/scaffold";
import {
  ArrowRightIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/outline";
import clsx from "clsx";

export type PastDueNotificationProps = {};

export const PastDueNotification: React.FC<PastDueNotificationProps> = ({}) => {
  const scaffold = useScaffold();
  const Link = scaffold.system.link;
  return (
    <div
      className={clsx(
        "border-2 rounded-lg p-4",
        scaffold.page.colors.border.plain,
      )}
    >
      <div className={clsx("flex flex-row items-start gap-4")}>
        <ExclamationCircleIcon
          className={clsx("w-1/12", scaffold.page.colors.text.error)}
        />
        <div className="flex flex-col w-11/12 gap-4">
          <div className="flex flex-col gap-2">
            <div
              className={clsx("font-medium", scaffold.page.colors.text.primary)}
            >
              Your payment didn't go through
            </div>
            <div
              className={clsx("text-sm", scaffold.page.colors.text.secondary)}
            >
              Please update your payment info to keep your service running
              smoothly
            </div>
          </div>
          <Link to="/account/payment-method/update">
            <div
              className={clsx(
                "flex flex-row items-center gap-1 font-medium text-sm",
              )}
            >
              <div>Update payment method</div>
              <ArrowRightIcon className="w-4 h-4" />
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};
