import { useAPI } from "@/services/api";
import { QueryLoader } from "@Light/components/app/QueryLoader";
import { useSearchParams } from "@Light/utils/context";
import { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { PlanCard } from "./plan-card/plan-card";
import { useHeader } from "@Light/components/page/header/context";

export type ByZipProps = {};

export const ByZip: React.FC<ByZipProps> = ({}) => {
  const { sizeProps } = useHeader();
  const [searchParams] = useSearchParams();
  const zip_code = searchParams.get("zip_code");
  if (!zip_code) {
    return <Navigate to="/shop/" />;
  }
  return (
    <div {...sizeProps} className="flex flex-col">
      <div className="h-10 bg-blue-100 flex justify-center items-center">
        Plans for zip: {zip_code}
      </div>
      <Body zip_code={zip_code} />
    </div>
  );
};

export type BodyProps = {
  zip_code: string;
};

export const Body: React.FC<BodyProps> = ({ zip_code }) => {
  const { useShopMutation } = useAPI();
  const [shop, shopQuery] = useShopMutation();
  const { isUninitialized } = shopQuery;
  useEffect(() => {
    if (isUninitialized) {
      shop({ zip_code });
    }
  }, [isUninitialized, shop]);
  return (
    <QueryLoader
      queries={[shopQuery]}
      errorMessage="Failed querying available plans. Please try again later."
    >
      <div className="flex flex-col gap-4 p-4 overflow-y-auto">
        {shopQuery.data?.map((plan) => (
          <PlanCard key={plan.uuid} plan={plan} />
        ))}
      </div>
    </QueryLoader>
  );
};
