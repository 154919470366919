import { useAPI } from "@/services/api";
import { SignupRequest } from "@/services/apiTypes";
import { PageBody } from "@Light/components/page/PageBody";
import { useScaffold } from "@Light/scaffold";
import { useMutation } from "@Light/utils/mutation";
import clsx from "clsx";
import { useCallback } from "react";
import { Navigate, useSearchParams } from "react-router-dom";

export type EmailSentProps = {};

export const EmailSent: React.FC<EmailSentProps> = ({}) => {
  const scaffold = useScaffold();
  const [searchParams] = useSearchParams();
  const plan = searchParams.get("plan") ?? null;
  const email = searchParams.get("email") ?? "";
  const { useSignupMutation } = useAPI();
  const signup = useMutation<SignupRequest, void>(useSignupMutation);
  const resend = useCallback(() => {
    signup.mutate({ email, plan });
  }, [signup.mutate]);
  if (plan === "" || email === "") {
    return <Navigate to="/shop" />;
  }
  return (
    <PageBody
      title="An email has been sent"
      subtitle={
        <div className="flex flex-col gap-2">
          <div>
            <span className={clsx(scaffold.page.colors.text.secondary)}>
              An email has been sent to
            </span>{" "}
            <span
              className={clsx("font-medium", scaffold.page.colors.text.primary)}
            >
              {email}
            </span>
          </div>
          <div className={clsx(scaffold.page.colors.text.secondary)}>
            <span>
              Please follow the link to be redirected to plan enrollment. You
              can also click
            </span>{" "}
            <span
              onClick={resend}
              className={clsx(
                "font-medium cursor-pointer",
                scaffold.page.colors.text.primary,
              )}
            >
              here
            </span>{" "}
            <span>to resend.</span>
          </div>
        </div>
      }
    />
  );
};
