import { Navigate, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { useScaffold } from "@Light/scaffold";
import { useAPI } from "@/services/api";
import { useDispatch } from "react-redux";
import { light } from "@/services/light";

export const LoginToken: React.FC = () => {
  const searchParams = useSearchParams()[0];
  const token = searchParams.get("token");
  const dispatch = useDispatch();

  if (!token) {
    return <Navigate to="/login" />;
  }

  const { useLoginTokenMutation } = useAPI();
  const [loginToken, loginTokenState] = useLoginTokenMutation();

  // Check the token at the outset and wait for it to complete.
  useEffect(() => {
    loginToken({ token });
  }, [token, loginToken]);

  useEffect(() => {
    if (loginTokenState.isSuccess) {
      dispatch(light.util.invalidateTags(["Account"]));
    }
  }, [loginTokenState.isSuccess]);

  const scaffold = useScaffold();
  if (loginTokenState.isLoading) {
    const Spinner = scaffold.system.spinner;
    return (
      <div className="flex items-center justify-center h-40">
        <Spinner className="w-8 h-8" />
      </div>
    );
  }

  if (loginTokenState.isSuccess) {
    return <Navigate to="/dash" />;
  }

  const Link = scaffold.system.link;
  return (
    <div>
      <span>Your link has expired. You can request a new one</span>{" "}
      <span>
        <Link to="/login" className="font-semibold">
          here
        </Link>
        .
      </span>
    </div>
  );
};
