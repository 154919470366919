import React from "react";
import { getFullDate } from "@Light/components/system/Datepicker/helpers";
import { useScaffold } from "@Light/scaffold";
import { clsx } from "clsx";
import { useLocation } from "@Light/components/page/account";
import { usePlan } from "@Light/utils/plan";

export type ServiceCardProps = React.ComponentProps<"div"> & {
  className?: string;
};

export const ServiceCard: React.FC<ServiceCardProps> = React.forwardRef<
  HTMLDivElement,
  ServiceCardProps
>(({ className }: ServiceCardProps, ref) => {
  const plan = usePlan();
  const scaffold = useScaffold();
  const location = useLocation();
  return (
    <div
      ref={ref}
      className={clsx(
        "border border-gray-300 rounded p-2.5 w-full flex flex-col gap-1",
        className,
      )}
    >
      <div
        className={clsx(
          "text-sm font-semibold",
          scaffold.page.colors.text.primary,
        )}
      >
        {scaffold.page.companyName} {plan.plan.name} Plan
      </div>
      <div className="flex justify-between mt-2 items-start">
        <div>
          <div className="flex flex-col">
            <div
              className={clsx(
                "text-xs font-regular",
                scaffold.page.colors.text.secondary,
              )}
            >
              Service address
            </div>
            <div className="flex flex-col text-sm">
              <div>{location.address_1}</div>
              {location.address_2 ? <div>{location.address_2}</div> : null}
              <div>
                {location.city}, {location.state} {location.postal_code}
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-between items-center mx-2 flex-shrink-0">
          <div className="flex flex-col items-start">
            <div
              className={clsx(
                "text-xs font-regular",
                scaffold.page.colors.text.secondary,
              )}
            >
              Service start date
            </div>
            <div className="flex text-sm items-center">
              {getFullDate(location.service_start_date)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
