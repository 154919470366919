import React, { useCallback, useEffect } from "react";
import { useScaffold } from "@Light/scaffold";
import { useHeader } from "@Light/components/page/header/context";
import { useAPI } from "@Light/services/api";
import { useMutation } from "@Light/utils/mutation";
import { useForm } from "react-hook-form";
import { UpdateUser } from "@Light/services/apiTypes";
import { useUser } from "@Light/components/auth/context";
import { light } from "@Light/services/light";
import { useDispatch, useNavigate } from "@Light/utils/context";

const BACK_ROUTE = "/account/details/";

export type UpdateNameProps = {};

export const UpdateName: React.FC<UpdateNameProps> = ({}) => {
  const scaffold = useScaffold();
  const { setBackNavigate, setTitle } = useHeader();
  const { useUpdateUserMutation } = useAPI();
  const user = useUser();
  const updateUser = useMutation<UpdateUser>(useUpdateUserMutation);
  const { register, handleSubmit, watch } = useForm();
  const { first_name, last_name } = watch();
  const disabled =
    user.first_name === first_name && user.last_name === last_name;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  setTitle("Update your name");
  setBackNavigate(BACK_ROUTE);

  useEffect(() => {
    if (!updateUser.isSuccess) {
      return;
    }
    // Tell redux to invalidate our account data as the back end server should
    // have updated its value.
    dispatch(light.util.invalidateTags(["Account"]));
    navigate(BACK_ROUTE);
  }, [updateUser.isSuccess]);

  const mutate = useCallback(
    handleSubmit(({ first_name, last_name }: any) => {
      updateUser.mutate({ first_name, last_name });
    }),
    [handleSubmit],
  );

  const PageBody = scaffold.page.pageBody;
  const MutationButton = scaffold.page.mutationButton;
  const Field = scaffold.system.field;
  const Label = scaffold.system.label;
  const Input = scaffold.system.input;
  return (
    <form onSubmit={mutate}>
      <PageBody
        footer={
          <MutationButton
            mutate={mutate}
            mutation={updateUser}
            mutateButtonText="Confirm"
            errorMessage="Error updating your name. Please try again later."
            disabled={disabled}
          />
        }
      >
        <div className="pt-8 flex flex-col gap-4">
          <Field>
            <Label>First name</Label>
            <Input
              {...register("first_name", {
                required: true,
                value: user.first_name,
              })}
            />
          </Field>
          <Field>
            <Label>Last name</Label>
            <Input
              {...register("last_name", {
                required: true,
                value: user.last_name,
              })}
            />
          </Field>
        </div>
      </PageBody>
    </form>
  );
};
