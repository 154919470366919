import React from "react";
import clsx from "clsx";
import { useScaffold } from "@Light/scaffold";

export type DetailFieldProps = React.ComponentProps<"div"> & {
  label: React.ReactNode;
};

export const DetailField: React.FC<DetailFieldProps> = React.forwardRef<
  HTMLDivElement,
  DetailFieldProps
>(({ label, children }, ref) => {
  const scaffold = useScaffold();
  const DetailLabel = scaffold.page.detail.detailLabel;
  return (
    <div ref={ref} className="flex flex-col gap-1">
      <DetailLabel>{label}</DetailLabel>
      <div className={clsx("text-sm", scaffold.page.colors.text.secondary)}>
        {children}
      </div>
    </div>
  );
});
