import { useScaffold } from "@Light/scaffold";
import { BaseMenuProps } from "./BaseMenu";
import { useAccount } from "../../account";

export type ServiceMenuProps = BaseMenuProps;

export const ServiceMenu: React.FC<BaseMenuProps> = (props) => {
  const account = useAccount();
  const scaffold = useScaffold();
  const Divided = scaffold.page.divided;
  const BaseMenu = scaffold.page.header.menu.baseMenu;
  const MenuItem = scaffold.page.header.menu.menuItem;
  return (
    <BaseMenu {...props}>
      <Divided>
        <div className="flex flex-col gap-6">
          <MenuItem title="Home" route="/dash" />
          <MenuItem title="Billing" route="/dash/billing" />
          <MenuItem title="Account" route="/account/" />
          <MenuItem title="Help & Support" route="/support/" />
        </div>

        {account.app.is_sandbox ? (
          <MenuItem title="Sandbox **DEMO ONLY**" route="/sandbox" />
        ) : null}
      </Divided>
    </BaseMenu>
  );
};
