import { QueryLoader } from "@Light/components/app/QueryLoader";
import { useLocation } from "@Light/components/page/account";
import { useLight } from "@Light/services/light";
import { useHeader } from "@Light/components/page/header/context";
import { useMemo } from "react";
import { useScaffold } from "@Light/scaffold";
import { ChevronRightIcon } from "@heroicons/react/24/solid";
import dayjs from "dayjs";
import clsx from "clsx";

export const PreviousDocumentsList: React.FC = () => {
  const { setBackNavigate, setTitle } = useHeader();

  setBackNavigate("/account/documents/");
  setTitle("Previous documents");

  const { useGetAllLocationsDocumentsQuery } = useLight();
  const { uuid } = useLocation();
  const allDocuments = useGetAllLocationsDocumentsQuery({ uuid });

  const scaffold = useScaffold();
  const PageBody = scaffold.page.pageBody;
  const DetailLabel = scaffold.page.detail.detailLabel;

  const previousDocuments = useMemo(
    () => allDocuments.data?.data?.filter((docSet) => !docSet.is_active),
    [allDocuments.data?.data],
  );

  const Link = scaffold.system.link;
  return (
    <PageBody>
      <QueryLoader
        queries={[allDocuments]}
        errorMessage="Failed loading documents. Please try again later."
      >
        <div className="pt-8 flex flex-col">
          {previousDocuments?.map((docSet) => (
            <Link
              to={`/account/documents/previous/${docSet.uuid}`}
              key={docSet.uuid}
              className="w-full border-b py-6 flex justify-between items-center"
            >
              <div className="flex flex-col gap-2">
                <DetailLabel>{docSet.plan_name}</DetailLabel>
                <div
                  className={clsx(
                    "text-sm font-regular",
                    scaffold.page.colors.text.secondary,
                  )}
                >
                  {dayjs(docSet.contract_start).format("MMMM YYYY")} {" - "}
                  {dayjs(docSet.contract_end).format("MMMM YYYY")}
                </div>
              </div>
              <ChevronRightIcon
                className={clsx(
                  "h-5 w-5 font-medium stroke-2",
                  scaffold.page.colors.text.secondary,
                )}
              />
            </Link>
          ))}
        </div>
      </QueryLoader>
    </PageBody>
  );
};
