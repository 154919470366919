import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { Details } from "./Details";
import { UpdateBillingAddress } from "./UpdateBillingAddress";
import { useScaffold } from "@Light/scaffold";

export type DetailsRouteProps = {};

export const DetailsRoute: React.FC<DetailsRouteProps> = ({}) => {
  const scaffold = useScaffold();
  const UpdateName = scaffold.account.details.updateName;
  const UpdateEmail = scaffold.account.details.updateEmail;
  return (
    <Routes>
      <Route path="/" element={<Details />} />
      <Route path="/update-name" element={<UpdateName />} />
      <Route path="/update-email" element={<UpdateEmail />} />
      <Route
        path="/update-billing-address"
        element={<UpdateBillingAddress />}
      />
      <Route path="/*" element={<Navigate to="/account/details/" />} />
    </Routes>
  );
};
