import { ShopPlan } from "@/services/apiTypes";
import { useScaffold } from "@Light/scaffold";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { checkSolarBuybackMatch } from "./utils";

export type SellingPointsProps = {
  plan: ShopPlan;
};

export const SellingPoints: React.FC<SellingPointsProps> = ({ plan }) => {
  const hasSolarBuybackMatch = checkSolarBuybackMatch(plan);
  const hasStorageCredit =
    parseFloat(plan.storage_monthly_credit_dollars) !== 0;
  return (
    <div className="flex flex-col gap-2">
      {hasStorageCredit && <Item>Battery storage credit</Item>}
      {hasSolarBuybackMatch && <Item>Matched rate for solar buyback</Item>}
      <Item>Locked-in rate for the next {plan.term_months} months</Item>
      {plan.renewable_percent >= 100 && <Item>100% renewable home energy</Item>}
      {parseInt(plan.early_termination_fee_dollars) === 0 && (
        <Item>No early termination fee</Item>
      )}
    </div>
  );
};

export type ItemProps = {
  children: React.ReactNode;
};

export const Item: React.FC<ItemProps> = ({ children }) => {
  const scaffold = useScaffold();
  return (
    <div className="flex flex-row gap-3 items-center">
      <CheckCircleIcon className="w-8 text-green-600" />
      <div className={clsx("text-sm", scaffold.page.colors.text.secondary)}>
        {children}
      </div>
    </div>
  );
};
