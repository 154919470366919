import { clsx } from "clsx";

export type DividedProps = React.ComponentProps<"div"> & {
  gap?: string;
  children: React.ReactNode;
};

export const Divided: React.FC<DividedProps> = ({
  gap = "4",
  children,
  className,
  ...props
}: DividedProps) => {
  return (
    <div
      {...props}
      className={clsx(`flex flex-col divide-y gap-${gap}`, className)}
    >
      {!Array.isArray(children)
        ? children
        : children
            .filter((child) => Boolean(child))
            .map((child, i) => {
              const classes = [];
              if (i !== 0) {
                classes.push(`pt-${gap}`);
              }
              return (
                <div key={i} className={clsx(...classes)}>
                  {child}
                </div>
              );
            })}
    </div>
  );
};
