import { useState as reactUseState, Dispatch, SetStateAction } from "react";

export type State<S> = {
  val: S;
  setVal: Dispatch<SetStateAction<S>>;
};

export function useState<S>(initialState: S | (() => S)): State<S> {
  const [val, setVal] = reactUseState<S>(initialState);
  return { val, setVal };
}
