import { useNavigate } from "@Light/utils/context";
import { State, useState } from "@Light/utils/state";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import clsx from "clsx";
import { useCallback } from "react";

export type Mode = "month" | "year";

export type ModeSelectorProps = {
  selectedMode: Mode;
};

export const ModeSelector: React.FC<ModeSelectorProps> = ({ selectedMode }) => {
  const isOpen = useState<boolean>(false);
  const monthButton = (
    <ModeButton mode="month" selectedMode={selectedMode} isOpen={isOpen} />
  );
  const yearButton = (
    <ModeButton mode="year" selectedMode={selectedMode} isOpen={isOpen} />
  );
  return (
    <div className="relative">
      {selectedMode === "month" ? monthButton : yearButton}
      <div className="absolute top-10 left-0">
        {isOpen.val && (selectedMode === "month" ? yearButton : monthButton)}
      </div>
    </div>
  );
};

export type ModeButtonProps = {
  mode: Mode;
  selectedMode: Mode;
  isOpen: State<boolean>;
};

export const ModeButton: React.FC<ModeButtonProps> = ({
  mode,
  selectedMode,
  isOpen,
}) => {
  const navigate = useNavigate();
  const selected = selectedMode == mode;
  const onClick = useCallback(() => {
    if (mode === selectedMode) {
      isOpen.setVal(!isOpen.val);
      return;
    }
    navigate(`/dash/usage?${new URLSearchParams({ mode })}`);
    isOpen.setVal(false);
  }, [mode, isOpen.val, isOpen.setVal]);
  return (
    <div
      className={clsx("border rounded-lg cursor-pointer w-20", "bg-white")}
      onClick={onClick}
    >
      <div className="flex flex-row justify-between p-2 gap-1">
        <div className="text-xs">
          {mode.charAt(0).toUpperCase() + mode.slice(1)}
        </div>
        <div className="w-4 h-4">
          {Boolean(selected) && <ChevronDownIcon />}
        </div>
      </div>
    </div>
  );
};
