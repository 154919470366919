export function FixedRateIcon(props: React.ComponentPropsWithoutRef<"svg">) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z"
        fill="#F0F9FF"
      />
      <path
        d="M22 14.6668H10M22 16.3335V13.8668C22 12.7467 22 12.1867 21.782 11.7588C21.5903 11.3825 21.2843 11.0766 20.908 10.8848C20.4802 10.6668 19.9201 10.6668 18.8 10.6668H13.2C12.0799 10.6668 11.5198 10.6668 11.092 10.8848C10.7157 11.0766 10.4097 11.3825 10.218 11.7588C10 12.1867 10 12.7467 10 13.8668V19.4668C10 20.5869 10 21.147 10.218 21.5748C10.4097 21.9511 10.7157 22.2571 11.092 22.4488C11.5198 22.6668 12.0799 22.6668 13.2 22.6668H16M18.6667 9.3335V12.0002M13.3333 9.3335V12.0002M17.6667 20.6668L19 22.0002L22 19.0002"
        stroke="#026AA2"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
