import { useScaffold } from "@Light/scaffold";
import { useEnroll } from "../../context";
import { ServiceEventButton } from "./ServiceEventButton";
import { useCallback } from "react";
import { clsx } from "clsx";
import { useNavigate } from "@Light/utils/context";
import { ServiceEvent as ServiceEventType } from "@Light/services/lightTypes";
import { useEnrollRoutes } from "../../routes";
import { useRef } from "react";
import { Button } from "@Light/components/page/Button";

export type ServiceEventProps = {};

export const ServiceEvent: React.FC<ServiceEventProps> = ({}) => {
  const { address, serviceEvent } = useEnroll();
  const navigate = useNavigate();
  const { nextRoute } = useEnrollRoutes();
  const redirect = nextRoute("/service-address/*");

  const onClick = useCallback(() => {
    if (!redirect) {
      throw new Error("Cannot redirect from service event selection");
    }
    navigate(redirect);
  }, [redirect, navigate]);

  const onSelectEvent = useCallback(
    (event: ServiceEventType) => {
      serviceEvent.setVal(event);
      buttonRef.current?.focus();
    },
    [serviceEvent.setVal],
  );

  const buttonRef = useRef<HTMLButtonElement>(null);

  if (!address.val) {
    throw new Error("address must be set to render ServiceEvent");
  }

  const scaffold = useScaffold();
  const PageBody = scaffold.page.pageBody;
  const Link = scaffold.system.link;
  const ServiceAddressField = scaffold.page.detail.serviceAddressField;
  const hasOffer = Boolean(scaffold.enroll.steps.offer);
  const Hyperlink = scaffold.page.hyperlink;
  return (
    <PageBody
      title="Let's build your plan"
      subtitle="Is this address correct?"
      footer={
        <div className="flex flex-col gap-4">
          {!hasOffer && Boolean(scaffold.page.termsOfServiceUrl) && (
            <div
              className={clsx("text-sm", scaffold.page.colors.text.tertiary)}
            >
              <span>
                By proceeding, you agree to {scaffold.page.companyName}'s
              </span>{" "}
              <span>
                <Hyperlink
                  href={scaffold.page.termsOfServiceUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms of Service
                </Hyperlink>
                .
              </span>
            </div>
          )}
          <Button
            className={clsx(
              "focus:ring-4 focus:ring-blue-500 focus:border-blue-500",
            )}
            onClick={onClick}
            ref={buttonRef}
          >
            See your plan
          </Button>
        </div>
      }
    >
      <div className="flex flex-col gap-8">
        <div className="flex flex-col gap-4">
          <div className="flex justify-between">
            <ServiceAddressField {...address.val} />
            <Link to="/enroll/service-address/">
              <Button
                buttonType="clear"
                className={clsx("text-sm", scaffold.page.colors.text.secondary)}
              >
                Edit
              </Button>
            </Link>
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <ServiceEventButton
            isSelected={serviceEvent.val === "switch"}
            onChange={useCallback(
              () => onSelectEvent("switch"),
              [onSelectEvent],
            )}
          >
            I already live at this address.
          </ServiceEventButton>
          <ServiceEventButton
            isSelected={serviceEvent.val === "move_in"}
            onChange={useCallback(
              () => onSelectEvent("move_in"),
              [onSelectEvent],
            )}
          >
            I'm moving to this address.
          </ServiceEventButton>
        </div>
      </div>
    </PageBody>
  );
};
