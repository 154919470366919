import { Button } from "@Light/components/page/Button";
import { PageBody } from "@Light/components/page/PageBody";
import { Input } from "@Light/components/system/Input";
import { useNavigate } from "@Light/utils/context";
import { useCallback } from "react";
import { useForm } from "react-hook-form";

export type EnterZipProps = {};

export const EnterZip: React.FC<EnterZipProps> = ({}) => {
  const { register, handleSubmit } = useForm();
  const navigate = useNavigate();
  const onSubmit = handleSubmit(
    useCallback(({ zip_code }) => {
      navigate(`/shop/by-zip?${new URLSearchParams({ zip_code })}`);
    }, []),
  );
  return (
    <PageBody
      title="Please enter your zip code"
      subtitle={
        <>Enter your zip code below to discover available plans in your area</>
      }
    >
      <form className="pt-8" onSubmit={onSubmit}>
        <div className="flex flex-col gap-8">
          <Input
            type="number"
            placeholder="Zip code"
            {...register("zip_code", { required: true })}
          />
          <Button
            type="submit"
            className="bg-blue-600 hover:bg-blue-300 w-full"
          >
            Search
          </Button>
        </div>
      </form>
    </PageBody>
  );
};
