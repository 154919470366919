import { useAPI } from "@Light/services/api";
import { useScaffold } from "@Light/scaffold";
import { useCallback } from "react";

export type LogoutButtonProps = {};

export const LogoutButton: React.FC<LogoutButtonProps> = ({}) => {
  const { useLogoutMutation } = useAPI();
  const [logout] = useLogoutMutation();
  const scaffold = useScaffold();
  const Button = scaffold.system.button;
  return (
    <Button onClick={useCallback(() => logout(), [logout])}>Log out</Button>
  );
};
