import React from "react";
import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import { State } from "@Light/utils/state";
import { OnBack } from "./context";

export type BackButtonProps = {
  onBack: State<OnBack | undefined>;
};

export const BackButton: React.FC<BackButtonProps> = ({
  onBack,
}: BackButtonProps) => {
  if (!onBack.val) {
    return <div className="w-10 h-10" />;
  }

  return (
    <button onClick={onBack.val}>
      <div className="w-10 h-10 flex items-center justify-center">
        <ChevronLeftIcon className="w-6 h-6 cursor-pointer" />
      </div>
    </button>
  );
};
