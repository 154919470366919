import { WithHeader } from "@Light/components/page/header";
import { Navigate, Route, Routes } from "react-router-dom";
import { EnterZip } from "./enter-zip";
import { Overlay } from "@Light/components/page/Overlay";
import { useHeader } from "@Light/components/page/header/context";
import { useMemo } from "react";
import { ByZip } from "./by-zip";
import { Signup } from "./signup";
import { EmailSent } from "./email-sent";

export type ShopRoutesProps = {};

export const ShopRoutes: React.FC<ShopRoutesProps> = ({}) => {
  return (
    <Overlay>
      <WithHeader>
        <ShopRoutesWithinHeader />
      </WithHeader>
    </Overlay>
  );
};

export type ShopRoutesWithinHeaderProps = {};

export const ShopRoutesWithinHeader: React.FC<
  ShopRoutesWithinHeaderProps
> = ({}) => {
  const { setRightButton } = useHeader();
  setRightButton(useMemo(() => <div />, []));
  return (
    <Routes>
      <Route path="/" element={<EnterZip />} />
      <Route path="/by-zip" element={<ByZip />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/email-sent" element={<EmailSent />} />
      <Route path="/*" element={<Navigate to="/" />} />
    </Routes>
  );
};
