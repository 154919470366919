import { BillingInvoice } from "@Light/services/lightTypes";
import dayjs from "dayjs";
import { useMemo } from "react";
import { clsx } from "clsx";
import { useScaffold } from "@Light/scaffold";
import { ArrowRightIcon } from "@heroicons/react/24/solid";
import { InvoiceStatus } from "./InvoiceStatus";
import { usePaymentDays } from "./common";
import { DollarAmount } from "../BillingInvoice/DollarAmount";

export type InvoiceCellProps = {
  invoice: BillingInvoice;
};

export const InvoiceCell: React.FC<InvoiceCellProps> = ({ invoice }) => {
  const scaffold = useScaffold();
  const invoiceDate = useMemo(
    () => dayjs(invoice.invoice_date),
    [invoice.invoice_date],
  );
  const paymentDays = usePaymentDays(invoice);
  const Link = scaffold.system.link;
  return (
    <Link to={`/dash/billing/invoice/${encodeURIComponent(invoice.number)}`}>
      <div className="flex flex-row justify-between items-center">
        <div className="flex flex-col">
          <div className={clsx("text-sm", scaffold.page.colors.text.primary)}>
            {invoiceDate.format("MMM D, YYYY")}
          </div>
          <div>
            <InvoiceStatus
              invoice={invoice}
              className="text-xs font-semibold"
            />
          </div>
        </div>
        <div className="flex flex-row items-center gap-2">
          <DollarAmount
            amount={invoice.total}
            isPastDue={paymentDays < 0 && !invoice.paid_at}
          />
          <ArrowRightIcon
            className={clsx(
              "h-4 w-4 font-medium stroke-2 cursor-pointer pb-0.5",
              scaffold.page.colors.text.secondary,
            )}
          />
        </div>
      </div>
    </Link>
  );
};
