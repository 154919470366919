import React from "react";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { useScaffold } from "@Light/scaffold";

export type ItemProps = React.ComponentProps<"div"> & {
  link: string;
};

export const Item: React.FC<ItemProps> = React.forwardRef(
  ({ link, children }, ref) => {
    const scaffold = useScaffold();
    const Link = scaffold.system.link;
    return (
      <Link to={link}>
        <div
          ref={ref}
          className="flex flex-row justify-between items-center py-2"
        >
          <div>{children}</div>
          <ChevronRightIcon className="w-5 h-5" />
        </div>
      </Link>
    );
  },
);
