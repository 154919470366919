import { Navigate, Route, Routes } from "react-router-dom";
import { Sandbox } from "./Sandbox";
import { useAccount } from "@Light/components/page/account";
import { WithHeader } from "../page/header";

export type SandboxRouteProps = {};

export const SandboxRoute: React.FC<SandboxRouteProps> = ({}) => {
  const account = useAccount();
  if (!account.app.is_sandbox || !account.enrollment?.has_payment_method) {
    return <Navigate to="/" />;
  }
  return (
    <WithHeader>
      <Routes>
        <Route path="/" element={<Sandbox />} />
        <Route path="/*" element={<Navigate to="/sandbox" />} />
      </Routes>
    </WithHeader>
  );
};
