import { QueryLoader } from "@Light/components/app/QueryLoader";
import { useLocation } from "@Light/components/page/account";
import { useLight } from "@Light/services/light";
import { useHeader } from "@Light/components/page/header/context";
import { useScaffold } from "@Light/scaffold";
import { DocumentList } from "./DocumentList";
import dayjs from "dayjs";
import { useParams } from "@Light/utils/context";

export const PreviousDocuments: React.FC = () => {
  const { setBackNavigate, setTitle } = useHeader();

  setBackNavigate("/account/documents/previous/");

  const { documentsUUID } = useParams();

  const { useGetAllLocationsDocumentsQuery } = useLight();
  const { uuid } = useLocation();
  const allDocuments = useGetAllLocationsDocumentsQuery({ uuid });

  const documents = allDocuments.data?.data.find(
    (docSet) => docSet.uuid === documentsUUID,
  );

  let title = "";
  if (documents) {
    title = `${dayjs(documents.contract_start).format("YYYY")} - ${dayjs(documents.contract_end).format("YYYY")}`;
  }
  setTitle(title);

  const scaffold = useScaffold();
  if (allDocuments.isSuccess && !documents) {
    const Navigate = scaffold.system.navigate;
    return <Navigate to="/account/documents/previous/" />;
  }

  const PageBody = scaffold.page.pageBody;
  return (
    <PageBody>
      <QueryLoader
        queries={[allDocuments]}
        errorMessage="Failed loading documents. Please try again later."
      >
        <div className="pt-8">
          <DocumentList
            efl={documents?.efl}
            yrac={documents?.yrac}
            tos={documents?.tos}
            contract_start={documents?.contract_start}
            contract_end={documents?.contract_end}
          />
        </div>
      </QueryLoader>
    </PageBody>
  );
};
