import React, { useCallback } from "react";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { useScaffold } from "@Light/scaffold";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import { usePopover } from "@Light/components/page/popover/WithPopover";

export const BACK_ROUTE = "/account/payment-method/";

export type PaymentUpdatedPopoverProps = {};

export const PaymentUpdatedPopover: React.FC<
  PaymentUpdatedPopoverProps
> = ({}) => {
  const scaffold = useScaffold();
  const navigate = useNavigate();

  const { close } = usePopover();
  const onClick = useCallback(() => {
    close();
    navigate("/account/payment-method/");
  }, [close, navigate]);

  const Button = scaffold.page.button;
  const CircleIcon = scaffold.page.circleIcon;
  return (
    <div className="p-4 flex flex-col gap-4">
      <div className="flex">
        <CircleIcon
          className={clsx("w-10 h-10", scaffold.page.colors.background.info)}
        >
          <CheckCircleIcon className={scaffold.page.colors.text.info} />
        </CircleIcon>
      </div>
      <div className="flex flex-col gap-2">
        <div
          className={clsx(
            "text-lg font-medium",
            scaffold.page.colors.text.primary,
          )}
        >
          Payment updated
        </div>
        <div>Your payment has been successfully updated</div>
      </div>
      <Button buttonType="outline" onClick={onClick}>
        Done
      </Button>
    </div>
  );
};
