import { useScaffold } from "@Light/scaffold";
import { Item } from "./Item";
import {
  HomeIcon,
  DevicePhoneMobileIcon,
  CalendarDaysIcon,
  BoltIcon,
} from "@heroicons/react/24/outline";
import { usePlan } from "@Light/utils/plan";

export type OfferItemsProps = {};

export const OfferItems: React.FC<OfferItemsProps> = ({}) => {
  const scaffold = useScaffold();
  const plan = usePlan();
  const { fixedRate, noCancellationFees, renewableEnergy, vehicleCharging } =
    plan.features;
  const Divided = scaffold.page.divided;
  return (
    <Divided gap="4">
      {fixedRate && !noCancellationFees && (
        <Item
          icon={<CalendarDaysIcon className="w-6 h-6" />}
          title="Fixed rate"
        >
          Lock in your home electricity rate for {fixedRate.termMonths} months.
        </Item>
      )}
      {fixedRate && noCancellationFees && (
        <Item
          icon={<CalendarDaysIcon className="w-6 h-6" />}
          title="Cancel anytime"
        >
          Lock in your home electricity rate for {fixedRate.termMonths} months
          with no early cancellation fees.
        </Item>
      )}
      {renewableEnergy && (
        <Item
          icon={<HomeIcon className="w-6 h-6" />}
          title="100% renewable home electricity"
        >
          Power your home at a competitive rate with energy from sustainable
          sources.
        </Item>
      )}
      {vehicleCharging && (
        <Item
          icon={<BoltIcon className="w-6 h-6" />}
          title="Unlimited vehicle charging"
        >
          Charge overnight for {vehicleCharging.rateText}/month per vehicle, no
          hidden fees.
        </Item>
      )}
      <Item
        icon={<DevicePhoneMobileIcon className="w-6 h-6" />}
        title="No installation required"
      >
        Sign up in less than 1 minute, with no need for additional hardware.
      </Item>
    </Divided>
  );
};
