import { useLocation } from "@Light/components/page/account";
import { useLight } from "@Light/services/light";
import { useIsPastDue } from "../Billing/common";
import { PastDueNotification } from "../Billing/PastDueNotification";
import { ServiceCancelledNotification } from "./ServiceCancelledNotification";

export type NotificationProps = {};
export const Notification: React.FC<NotificationProps> = ({}) => {
  const { useGetBillingInvoicesQuery } = useLight();
  const billingInvoices = useGetBillingInvoicesQuery();
  const firstInvoice = billingInvoices.data?.data[0];
  const isPastDue = useIsPastDue(firstInvoice);
  const location = useLocation();
  if (isPastDue) {
    return <PastDueNotification />;
  }
  if (location.final_service_date) {
    return <ServiceCancelledNotification />;
  }
  return null;
};
