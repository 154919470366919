import { useScaffold } from "@Light/scaffold";
import { Card } from "./Card";
import clsx from "clsx";
import { usePlan } from "@Light/utils/plan";

export type TransparentBillingCardProps = {};

export const TransparentBillingCard: React.FC<
  TransparentBillingCardProps
> = ({}) => {
  const scaffold = useScaffold();
  const plan = usePlan();
  const { solarBuyback, vehicleCharging } = plan.features;
  if (solarBuyback || vehicleCharging) {
    return null;
  }
  return (
    <Card label="Transparent billing">
      <div className="flex flex-col gap-3">
        <div
          className={clsx(
            "text-lg font-medium",
            scaffold.page.colors.text.primary,
          )}
        >
          No hidden costs
        </div>
        <div
          className={clsx(
            "text-sm",
            "font-normal",
            scaffold.page.colors.text.tertiary,
          )}
        >
          With us, there are no hidden fees, surprises, or miscellaneous
          charges.
        </div>
      </div>
    </Card>
  );
};
