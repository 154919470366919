import { Navigate, Routes, Route } from "react-router-dom";
import { Billing } from "@Light/components/dash/Billing";
import { BillingInvoiceRoute } from "./BillingInvoice/BillingInvoiceRoute";
import { useHeader } from "../page/header/context";

export type BillingRouteprops = {
  hideRootBack?: boolean;
  showDivider?: boolean;
};

export const BillingRoute: React.FC<BillingRouteprops> = ({
  hideRootBack,
  showDivider,
}) => {
  const { setTitle, setDivider } = useHeader();

  setTitle("Billing");

  if (!showDivider) {
    setDivider(false);
  }

  return (
    <Routes>
      <Route path="/" element={<Billing hideBack={hideRootBack} />} />
      <Route path="/invoice/:invoiceNumber" element={<BillingInvoiceRoute />} />
      <Route path="/*" element={<Navigate to="/dash/billing/" />} />
    </Routes>
  );
};
