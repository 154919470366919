import * as Sentry from "@sentry/react";
import { captureConsoleIntegration } from "@sentry/browser";
import { useEffect } from "react";
import { useLight } from "@Light/services/light";
import { isFetchBaseQueryError } from "@Light/utils/errors";

let initialized = false;

const ALLOWED_ENVIRONMENTS = ["production", "staging"];

export const initSentry = (clientType: string) => {
  if (initialized) {
    return;
  }

  const environment = import.meta.env.MODE;

  if (!ALLOWED_ENVIRONMENTS.includes(environment)) {
    return;
  }

  Sentry.init({
    dsn: "https://57f4409a22096eff98a13d9f06dae3d2@o4506870612688896.ingest.us.sentry.io/4507985630068736",
    environment,
    integrations: [
      captureConsoleIntegration({
        levels: ["error"],
      }),
    ],
    initialScope: {
      tags: {
        client_type: clientType,
      },
    },
  });

  initialized = true;
};

export function captureError(
  error: Error,
  extra?: Record<string, string | boolean>,
) {
  try {
    if (initialized) {
      Sentry.captureException(error, {
        extra,
      });
    } else {
      console.error("captureError", error, extra);
    }
  } catch (e) {
    console.error("Failed to capture error", e);
  }
}

export function captureMessage(
  message: string,
  extra?: Record<string, string | boolean>,
) {
  try {
    if (initialized) {
      Sentry.captureMessage(message, {
        extra,
      });
    } else {
      console.error("captureMessage", message, extra);
    }
  } catch (e) {
    console.error("Failed to capture message", e);
  }
}

function logoutSentry() {
  if (initialized) {
    Sentry.setUser(null);
  }
}

export const SentryAddContext = () => {
  const light = useLight();

  const {
    data: account,
    isError,
    error,
  } = light.endpoints.getAccount.useQueryState();

  useEffect(() => {
    if (account) {
      Sentry.setUser({
        id: account.uuid,
        email: account.email,
        app_uuid: account.app.uuid,
        app_name: account.app.name,
        app_is_sandbox: account.app.is_sandbox,
      });
    }
  }, [account]);

  useEffect(() => {
    if (isError && isFetchBaseQueryError(error) && error.status === 401) {
      logoutSentry();
    }
  }, [isError, error]);

  return null;
};
