import { clsx } from "clsx";
import { useMemo } from "react";

export type EnumeratedListProps = {
  children: React.ReactNode[];
};

export const EnumeratedList: React.FC<EnumeratedListProps> = ({ children }) => {
  return (
    <div className="flex flex-col gap-6">
      {children.map((item, i) => (
        <div key={i} className="flex flex-row items-start gap-8">
          <Index index={i} />
          <div>{item}</div>
        </div>
      ))}
    </div>
  );
};

export type IndexProps = {
  index: number;
};

export const Index: React.FC<IndexProps> = ({ index }) => {
  // Turn the index into a monospace font by separating out the characters into
  // their own div and ensuring each has a fixed with.
  const chars = useMemo(
    () =>
      String(index + 1)
        .padStart(2, "0")
        .split(""),
    [index],
  );
  return (
    <div
      className={clsx(
        "text-2xl border-l border-l-gray-600 text-gray-700 font-light pl-1.5",
      )}
    >
      <div className="flex flex-row justify-between items-center">
        {chars.map((c, i) => (
          <div key={i} className="flex w-3.5 justify-center">
            {c}
          </div>
        ))}
      </div>
    </div>
  );
};
