import { useScaffold } from "@Light/scaffold";
import { ArrowRightIcon } from "@heroicons/react/24/outline";

export type AddressNotFoundLinkProps = {};

export const AddressNotFoundLink: React.FC<AddressNotFoundLinkProps> = ({}) => {
  const scaffold = useScaffold();
  const Link = scaffold.system.link;
  return (
    <Link to="/enroll/service-address/not-found">
      <div className="flex flex-row gap-1 justify-center items-center w-44">
        <div className="font-medium text-sm underline underline-offset-4">
          I don't see my address
        </div>
        <ArrowRightIcon className="w-4 h-4" />
      </div>
    </Link>
  );
};
