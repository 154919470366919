import { QueryLoader } from "@Light/components/app/QueryLoader";
import { useLight } from "@Light/services/light";
import { useMutation } from "@Light/utils/mutation";
import { useEffect } from "react";
import { Navigate } from "react-router-dom";

export type CheckAuthorizedProps = {};

export const CheckAuthorized: React.FC<CheckAuthorizedProps> = ({}) => {
  const { useCheckVehiclesAuthorizedMutation } = useLight();
  const checkVehiclesAuthorized = useMutation(
    useCheckVehiclesAuthorizedMutation,
  );

  useEffect(() => {
    checkVehiclesAuthorized.mutate();
  }, [checkVehiclesAuthorized.mutate]);

  if (checkVehiclesAuthorized.isSuccess) {
    return <Navigate to="/enroll/authorize-vehicles/pick" />;
  }

  return (
    <QueryLoader
      queries={[checkVehiclesAuthorized]}
      errorMessage="Failed verifying vehicle authorization. Please try again later."
    />
  );
};
