import { QueryLoader } from "@Light/components/app/QueryLoader";
import { useLocation } from "@Light/components/page/account";
import { useLight } from "@Light/services/light";
import { useHeader } from "@Light/components/page/header/context";
import { useScaffold } from "@Light/scaffold";
import { DocumentList } from "./DocumentList";

export type DocumentsProps = {
  hideBack?: boolean;
};

export const Documents: React.FC<DocumentsProps> = ({ hideBack }) => {
  const { setTitle, setBackNavigate } = useHeader();

  setTitle("Documents");
  if (!hideBack) {
    setBackNavigate("/account/");
  }

  const { useGetAllLocationsDocumentsQuery } = useLight();
  const { uuid } = useLocation();
  const allDocuments = useGetAllLocationsDocumentsQuery({ uuid });

  const scaffold = useScaffold();
  const PageBody = scaffold.page.pageBody;

  const activeDocuments = allDocuments.data?.data.find(
    (docSet) => docSet.is_active,
  );

  const previousDocuments = allDocuments.data?.data.filter(
    (docSet) => !docSet.is_active,
  );

  const Link = scaffold.system.link;
  return (
    <PageBody>
      <QueryLoader
        queries={[allDocuments]}
        errorMessage="Failed loading documents. Please try again later."
      >
        <div className="pt-8">
          <DocumentList
            efl={activeDocuments?.efl}
            yrac={activeDocuments?.yrac}
            tos={activeDocuments?.tos}
            contract_start={activeDocuments?.contract_start}
            contract_end={activeDocuments?.contract_end}
          />
        </div>
        {Boolean(previousDocuments?.length) && (
          <Link
            className="text-sm font-medium underline"
            to="/account/documents/previous"
          >
            View previous documents {"->"}
          </Link>
        )}
      </QueryLoader>
    </PageBody>
  );
};
