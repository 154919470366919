import { LightAccount, LightLocation } from "@Light/services/lightTypes";
import { createContext, useContext } from "react";

export type OnBack = () => void;
export type AccountState = LightAccount;

export const AccountContext = createContext<AccountState | undefined>(
  undefined,
);

export function useAccount(): AccountState {
  const account = useContext(AccountContext);
  if (!account) {
    throw new Error("WithAccount provider must be used for useAccount");
  }

  return account;
}

export function useLocation(): LightLocation {
  const account = useAccount();
  const location = account.locations[0];
  if (!location) {
    throw new Error("no location discovered");
  }

  return location;
}
