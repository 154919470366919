import { useHeader } from "@Light/components/page/header/context";
import { useScaffold } from "@Light/scaffold";
import React from "react";
import { MoveServiceButton } from "./MoveServiceButton";
import { CancelServiceButton } from "./CancelServiceButton";

export type MoveOrCancelProps = {};

export const MoveOrCancel: React.FC<MoveOrCancelProps> = ({}) => {
  const scaffold = useScaffold();
  const { setTitle, setBackNavigate } = useHeader();

  setTitle("Service adjustment");
  setBackNavigate("/account/service-details/");

  const PageBody = scaffold.page.pageBody;
  return (
    <PageBody>
      <div className="pt-8 flex flex-col gap-4">
        <MoveServiceButton />
        <CancelServiceButton />
      </div>
    </PageBody>
  );
};
