import { Navigate, Routes, Route } from "react-router-dom";
import { Support } from "./Support";
import { FAQ } from "./FAQ";
import { ContactUs } from "./ContactUs";
import { useHeader } from "@Light/components/page/header/context";
import { WithHeader } from "../page/header";

export const SupportRoute: React.FC = () => {
  const { setDivider } = useHeader();
  setDivider(false);
  return (
    <WithHeader>
      <Routes>
        <Route path="/faq" element={<FAQ />} />
        <Route path="/*" element={<SupportRouteWithService />} />
      </Routes>
    </WithHeader>
  );
};

export const SupportRouteWithService: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Support />} />
      <Route path="/contact" element={<ContactUs />} />
      <Route path="/*" element={<Navigate to="/support/" />} />
    </Routes>
  );
};
