import { useLight } from "@Light/services/light";
import { VehicleField } from "@Light/components/page/detail/VehicleField";
import { useScaffold } from "@Light/scaffold";
import { useMemo } from "react";
import clsx from "clsx";

export type VehiclesProps = {};

export const Vehicles: React.FC<VehiclesProps> = ({}) => {
  const { useGetVehiclesQuery } = useLight();
  const vehicles = useGetVehiclesQuery();
  if (!vehicles.isSuccess) {
    return null;
  }
  return <VehiclesB1 />;
};

export type VehiclesB1Props = {};

export const VehiclesB1: React.FC<VehiclesB1Props> = ({}) => {
  const { useGetVehiclesQuery } = useLight();
  const vehicles = useGetVehiclesQuery();
  if (!vehicles.isSuccess) {
    throw new Error(
      "cannot render VehiclesB1 without a completed vehicles query",
    );
  }

  const enrolledVehicles = useMemo(
    () => vehicles.data.data.filter((v) => Boolean(v.enrolled_at)),
    [vehicles.data.data],
  );
  const scaffold = useScaffold();
  const DetailLabel = scaffold.page.detail.detailLabel;
  return (
    <div className="flex flex-col gap-6">
      <DetailLabel>Enrolled vehicles</DetailLabel>
      <div className="flex flex-col gap-4">
        {enrolledVehicles.map((vehicle) => (
          <VehicleField key={vehicle.vehicle_id} vehicle={vehicle} />
        ))}
        {enrolledVehicles.length === 0 && (
          <div className={clsx("text-sm", scaffold.page.colors.text.secondary)}>
            You do not have any vehicles enrolled
          </div>
        )}
      </div>
    </div>
  );
};
