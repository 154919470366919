import { useLight } from "@Light/services/light";
import { AccountContext } from "./context";
import { LightAccount } from "@Light/services/lightTypes";
import { QueryLoader } from "@Light/components/app/QueryLoader";

export type WithAccountProps = {
  children: React.ReactNode;
};

export const WithAccount: React.FC<WithAccountProps> = ({ children }) => {
  const { useGetAccountQuery } = useLight();
  const account = useGetAccountQuery();
  return (
    <QueryLoader
      queries={[account]}
      errorMessage="Error loading account data. Please try again later."
    >
      <AccountContext.Provider value={account.data as LightAccount}>
        {children}
      </AccountContext.Provider>
    </QueryLoader>
  );
};
