import { createContext, useCallback, useContext } from "react";
import { useLight } from "@Light/services/light";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { ReactNode, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { isFetchBaseQueryError } from "@Light/utils/errors";
import { useObservedRateGroup } from "@Light/utils/rate_group";
import { State, useState } from "@Light/utils/state";
import { useSearchParams } from "@Light/utils/context";

const posthogKey = import.meta.env.VITE_POSTHOG_KEY;

let initialized = false;

if (typeof window !== "undefined" && posthogKey) {
  posthog.init(posthogKey, {
    api_host: "https://us.i.posthog.com",
    person_profiles: "identified_only",
    capture_pageview: false,
    session_recording: {
      maskAllInputs: true,
      maskInputFn: (text, element) => {
        if (element?.dataset["record"] === "true") {
          return text;
        }
        return "*".repeat(text.trim().length);
      },
    },
  });

  initialized = true;
}

function logoutPosthog() {
  if (initialized) {
    posthog.reset();
  }
}

export function captureEvent(
  event: string,
  properties?: Record<string, string | boolean>,
) {
  if (initialized) {
    posthog.capture(event, properties);
  } else {
    console.log("captureEvent", event, properties);
  }
}

export type PostHogState = {
  attribution: State<string | undefined>;
  planUUID: State<string | undefined>;
  useLocalStorage: boolean;
};

export const PostHogContext = createContext<PostHogState | undefined>(
  undefined,
);

interface AnalyticsProviderProps {
  children: ReactNode;
  useLocalStorage: boolean;
}

export function AnalyticsProvider({
  children,
  useLocalStorage,
}: AnalyticsProviderProps) {
  const location = useLocation();
  const light = useLight();
  const attribution = useState<string | undefined>(
    (useLocalStorage && localStorage.getItem("attribution")) || undefined,
  );
  const planUUID = useState<string | undefined>(
    (useLocalStorage && localStorage.getItem("planUUID")) || undefined,
  );

  const {
    data: account,
    isError,
    error,
  } = light.endpoints.getAccount.useQueryState();

  const rateGroup = useObservedRateGroup(planUUID.val || undefined);

  // Track pageviews on route change
  useEffect(() => {
    if (initialized) {
      posthog.capture("$pageview", {
        $current_url: window.location.href,
      });
    }
  }, [location]);

  // Identify user on login
  useEffect(() => {
    const properties: Record<string, string | boolean> = {};
    if (account) {
      properties["email"] = account.email;
      properties["app_uuid"] = account.app.uuid;
      properties["app_name"] = account.app.name;
      properties["app_is_sandbox"] = account.app.is_sandbox;
    }

    if (rateGroup) {
      properties["plan_uuid"] = rateGroup.uuid;
      properties["plan_name"] = rateGroup.name;
    }

    if (attribution.val) {
      // Keep setting "flow_attribution" as that's the legacy key. The
      // "attribution" key is the one I'm using going forward.
      properties["flow_attribution"] = attribution.val;
      properties["attribution"] = attribution.val;
    }

    const identity = account?.uuid ?? posthog.get_distinct_id();
    posthog.identify(identity, properties);
  }, [account, rateGroup, attribution.val]);

  // Log out user on logout
  useEffect(() => {
    if (isError && isFetchBaseQueryError(error) && error.status === 401) {
      logoutPosthog();
    }
  }, [isError, error]);

  // Detect tab/window close and capture event
  useEffect(() => {
    const handleBeforeUnload = () => {
      captureEvent("window_close");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return (
    <PostHogProvider client={posthog}>
      <PostHogContext.Provider
        value={{ attribution, planUUID, useLocalStorage }}
      >
        {children}
      </PostHogContext.Provider>
    </PostHogProvider>
  );
}

export function useAnalytics() {
  const postHog = useContext(PostHogContext);
  if (!postHog) {
    throw new Error(
      "must be wrapped in a AnalyticsProvider to use useAnalytics",
    );
  }
  const { useLocalStorage } = postHog;
  const setAttribution = useCallback(
    (attribution: string) => {
      postHog.attribution.setVal(attribution);
      if (useLocalStorage) {
        localStorage.setItem("attribution", attribution);
      }
    },
    [postHog.attribution.setVal, useLocalStorage],
  );
  const setPlanUUID = useCallback(
    (planUUID: string) => {
      postHog.planUUID.setVal(planUUID);
      if (useLocalStorage) {
        localStorage.setItem("planUUID", planUUID);
      }
    },
    [postHog.planUUID.setVal, useLocalStorage],
  );
  const attribution = postHog.attribution.val;
  const planUUID = postHog.planUUID.val;
  return {
    setAttribution,
    setPlanUUID,
    attribution,
    planUUID,
  };
}

export function useAnalyticsSearchParams() {
  const [searchParams] = useSearchParams();
  const attribution = searchParams.get("attribution");
  const plan = searchParams.get("plan");
  const { setAttribution, setPlanUUID } = useAnalytics();

  useEffect(() => {
    if (attribution) {
      setAttribution(attribution);
    }
  }, [attribution, setAttribution]);

  useEffect(() => {
    if (plan) {
      setPlanUUID(plan);
    }
  }, [plan, setPlanUUID]);
}
