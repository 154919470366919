export type LoginPageProps = {
  children: React.ReactNode;
};

export const LoginPage: React.FC<LoginPageProps> = ({ children }) => {
  return (
    <div className="flex items-center justify-center min-h-screen">
      <div className="max-w-sm overflow-hidden p-12">{children}</div>
    </div>
  );
};
