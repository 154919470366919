import { LinkList } from "./LinkList";
import { LinkItem } from "./LinkItem";
import {
  UserIcon,
  CreditCardIcon,
  DocumentTextIcon,
  LightBulbIcon,
} from "@heroicons/react/24/outline";
import { useScaffold } from "@Light/scaffold";
import { useHeader } from "@Light/components/page/header/context";
import { useCallback } from "react";
import { useNavigate } from "@Light/utils/context";
export const Account: React.FC = () => {
  const scaffold = useScaffold();
  const navigate = useNavigate();
  const { setBack, setTitle } = useHeader();
  setBack(useCallback(() => navigate("/"), [navigate]));
  setTitle("Account");
  const Link = scaffold.system.link;
  return (
    <div className="flex flex-col gap-2 p-4 pt-8">
      <LinkList>
        <Link to="/account/details/">
          <LinkItem
            leftIcon={<UserIcon className="w-5 h-5" />}
            text="Account details"
          />
        </Link>
        <Link to="/account/service-details">
          <LinkItem
            leftIcon={<LightBulbIcon className="w-5 h-5" />}
            text="Service details"
          />
        </Link>
        <Link to="/account/payment-method/">
          <LinkItem
            leftIcon={<CreditCardIcon className="w-5 h-5" />}
            text="Payment method"
          />
        </Link>
        <Link to="/account/documents">
          <LinkItem
            leftIcon={<DocumentTextIcon className="w-5 h-5" />}
            text="Documents"
          />
        </Link>
      </LinkList>
    </div>
  );
};
