import React from "react";
import { useScaffold } from "@Light/scaffold";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { clsx } from "clsx";
import { usePopover } from "@Light/components/page/popover/WithPopover";

export type ConfirmRatePopoverProps = {};

export const ConfirmRatePopover: React.FC<ConfirmRatePopoverProps> = () => {
  const { close } = usePopover();
  const scaffold = useScaffold();
  const Title = scaffold.page.title;
  const Button = scaffold.system.button;
  return (
    <div className="p-4">
      <div className="flex flex-col gap-8 justify-center">
        <div className="flex flex-row justify-between">
          <div />
          <div onClick={close}>
            <XMarkIcon
              className={clsx(
                "h-6 w-6 cursor-pointer",
                scaffold.page.colors.text.primary,
              )}
            />
          </div>
        </div>
        <div className="text-center">
          <Title>
            <div className="flex flex-col">
              <div>{scaffold.page.companyName}</div>
              <div>is powered by Light</div>
            </div>
          </Title>
        </div>
        <div className="h-[300px] overflow-y-auto">
          <div
            className={clsx(
              "flex flex-col gap-8 text-lg",
              scaffold.page.colors.text.primary,
            )}
          >
            <div>
              When you sign up for a plan with {scaffold.page.companyName},
              you’re getting transparent, affordable electricity service
              provided by Light Energy, Public Utility Commission of Texas REP
              #10340.
            </div>
            <div>
              {scaffold.page.companyName} works with Light in order to provide
              you with an innovative and modern electricity service that’s built
              around the {scaffold.page.companyName} experience. You manage your
              account, pay invoices, and get help from friendly customer
              support, all within the {scaffold.page.companyName} platform. You
              can learn more about{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="underline"
                href="https://light.dev/for-consumers"
              >
                Light
              </a>
              .
            </div>
          </div>
        </div>
        <Button onClick={close}>Close</Button>
      </div>
    </div>
  );
};
