import { useScaffold } from "@Light/scaffold";
import { useNavigate } from "@Light/utils/context";
import clsx from "clsx";
import React, { useCallback } from "react";

export type CancelConfirmedProps = {};

export const CancelConfirmed: React.FC<CancelConfirmedProps> = ({}) => {
  const navigate = useNavigate();
  const scaffold = useScaffold();
  const PageBody = scaffold.page.pageBody;
  const Button = scaffold.system.button;
  const EnumeratedList = scaffold.page.enumeratedList;
  const InfoItem = scaffold.page.infoItem;
  return (
    <PageBody
      footer={
        <Button
          onClick={useCallback(() => {
            navigate("/account/service-details/");
          }, [navigate])}
        >
          Ok
        </Button>
      }
      className="pt-8"
    >
      <div className="flex flex-col gap-12">
        <div className="flex flex-col gap-8">
          <div
            className={clsx(
              "font-medium text-2xl",
              scaffold.page.colors.text.primary,
            )}
          >
            We’ve received your request to cancel your electricity service
          </div>
          <div className={clsx(scaffold.page.colors.text.secondary)}>
            Here’s what happens next
          </div>
        </div>
        <EnumeratedList>
          <InfoItem title="Confirmation email">
            You will receive a confirmation email shortly
          </InfoItem>
          <InfoItem title="Final billing">
            A final bill will be sent to cover charges up to your cancellation
            date
          </InfoItem>
          <InfoItem title="Service termination">
            Your electricity service will end on the specified date. If you are
            switching to a new provider, work with them to avoid interruptions
          </InfoItem>
        </EnumeratedList>
      </div>
    </PageBody>
  );
};
