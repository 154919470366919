import { ShopPlan } from "@/services/apiTypes";

export function checkSolarBuybackMatch(plan: ShopPlan) {
  const {
    has_solar_buyback,
    export_rate_cents_per_kwh,
    energy_rate_cents_per_kwh,
  } = plan;
  if (!has_solar_buyback) {
    return false;
  }

  if (export_rate_cents_per_kwh !== energy_rate_cents_per_kwh) {
    return false;
  }

  return true;
}
