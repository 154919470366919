import { useGetBillingInvoicesQuery, useLight } from "@Light/services/light";
import { InvoiceStatus } from "@Light/components/dash/Billing/InvoiceStatus";
import { ArrowRightIcon } from "@heroicons/react/24/solid";
import { useScaffold } from "@Light/scaffold";
import { clsx } from "clsx";

export const BillingCard: React.FC = ({}) => {
  const { useGetBillingInvoicesQuery } = useLight();
  return <BaseBillingCard billingInvoices={useGetBillingInvoicesQuery()} />;
};

export type BaseBillingCardProps = {
  billingInvoices: ReturnType<typeof useGetBillingInvoicesQuery>;
};

export const BaseBillingCard: React.FC<BaseBillingCardProps> = ({
  billingInvoices,
}: BaseBillingCardProps) => {
  const scaffold = useScaffold();
  const latestInvoice = billingInvoices.data?.data?.[0];
  const Link = scaffold.system.link;
  return (
    <div
      className={clsx(
        "w-full border p-3 rounded-lg cursor-pointer",
        scaffold.page.colors.border.plain,
      )}
    >
      <Link to="/dash/billing">
        <div className="flex flex-row justify-between w-full">
          <div className="flex flex-col gap-1">
            <div className="text-sm">Billing</div>
            {latestInvoice ? (
              <InvoiceStatus className="text-xs" invoice={latestInvoice} />
            ) : (
              <div className="text-2xs">No invoice yet</div>
            )}
          </div>
          <div className="flex flex-row items-center gap-1">
            <div className={clsx("text-xs", scaffold.page.colors.text.primary)}>
              View
            </div>
            <ArrowRightIcon
              className={clsx(
                "h-4 w-4 font-medium stroke-2 cursor-pointer pb-0.5",
                scaffold.page.colors.text.secondary,
              )}
            />
          </div>
        </div>
      </Link>
    </div>
  );
};
