import { ShopPlan } from "@/services/apiTypes";
import { useScaffold } from "@Light/scaffold";
import clsx from "clsx";

export type TermProps = {
  plan: ShopPlan;
};

export const Term: React.FC<TermProps> = ({ plan }) => {
  const scaffold = useScaffold();
  return (
    <div className={clsx("text-sm", scaffold.page.colors.text.secondary)}>
      Rate fixed for {plan.term_months} months
    </div>
  );
};
