import { useLocation } from "@Light/components/page/account";
import { useLight } from "@Light/services/light";
import { useSearchParams } from "@Light/utils/context";
import dayjs from "dayjs";
import { useMemo, useState } from "react";

export function useDailyIntervalParams() {
  const [currentMonth] = useState(dayjs().startOf("month"));
  const searchParams = useSearchParams()[0];
  const monthParam = searchParams.get("month");
  const yearParam = searchParams.get("year");
  const month = monthParam ? parseInt(monthParam) : currentMonth.month() + 1;
  const year = yearParam ? parseInt(yearParam) : currentMonth.year();
  const selectedMonth = useMemo(
    () => dayjs(`${year}-${month}-1`),
    [year, month],
  );

  return {
    month,
    year,
    currentMonth,
    selectedMonth,
  };
}

export function useDailyUsage() {
  const location = useLocation();
  const { uuid } = location;
  const { useGetDailyUsageQuery } = useLight();
  const { month, year } = useDailyIntervalParams();
  return useGetDailyUsageQuery({ uuid, month, year });
}

export function useMonthlyIntervalParams() {
  const [currentYear] = useState(dayjs().startOf("year"));
  const searchParams = useSearchParams()[0];
  const yearParam = searchParams.get("year");
  const year = yearParam ? parseInt(yearParam) : currentYear.year();
  const selectedYear = useMemo(() => dayjs(`${year}-1-1`), [year]);
  return {
    year,
    currentYear,
    selectedYear,
  };
}

export function useMonthlyUsage() {
  const location = useLocation();
  const { uuid } = location;
  const { useGetMonthlyUsageQuery } = useLight();
  const { year } = useMonthlyIntervalParams();
  return useGetMonthlyUsageQuery({ uuid, year });
}
