import { useAccount, useLocation } from "@Light/components/page/account";
import { useHeader } from "@Light/components/page/header/context";
import { useScaffold } from "@Light/scaffold";
import { useNavigate } from "@Light/utils/context";
import clsx from "clsx";
import React, { useCallback } from "react";
import { Vehicles } from "./Vehicles";
import { usePlan } from "@Light/utils/plan";

export type ServiceDetailsServiceDetailsProps = {};

export const ServiceDetails: React.FC<
  ServiceDetailsServiceDetailsProps
> = ({}) => {
  const scaffold = useScaffold();
  const { setTitle, setBackNavigate } = useHeader();
  const account = useAccount();
  const location = useLocation();
  const navigate = useNavigate();
  const plan = usePlan();

  setTitle("Service details");
  setBackNavigate("/account/");

  const hasVehicleCharging = Boolean(plan.features.vehicleCharging);
  const PageBody = scaffold.page.pageBody;
  const Divided = scaffold.page.divided;
  const DetailField = scaffold.page.detail.detailField;
  const ESIIDField = scaffold.page.detail.esiidField;
  const DetailLabel = scaffold.page.detail.detailLabel;
  const OutlineButton = scaffold.system.outlineButton;
  return (
    <PageBody>
      <Divided className="pt-8" gap="6">
        <div className="flex flex-col gap-8">
          <DetailField label="Service address">
            <div className="flex flex-col">
              <div>
                {location.address_1}
                {location.address_2 ? `, ${location.address_2}` : ""}
              </div>
              <div>
                {location.city}, {location.state} {location.postal_code}
              </div>
            </div>
          </DetailField>
          <DetailField label="Account number">
            {account.account_number}
          </DetailField>
          <ESIIDField>{location.utility_number}</ESIIDField>
        </div>
        {hasVehicleCharging && <Vehicles />}
        <div className="flex flex-col gap-6">
          <div className="flex flex-col gap-2">
            <DetailLabel>Move or cancel service</DetailLabel>
            <div
              className={clsx("text-sm", scaffold.page.colors.text.secondary)}
            >
              Change your service address or cancel your plan for any reason
            </div>
          </div>
          <div className="flex">
            <OutlineButton
              className={clsx(scaffold.page.colors.border.error)}
              onClick={useCallback(
                () => navigate("/account/service-details/move-or-cancel"),
                [navigate],
              )}
            >
              <div className={clsx("text-xs", scaffold.page.colors.text.error)}>
                Move or cancel my service
              </div>
            </OutlineButton>
          </div>
        </div>
      </Divided>
    </PageBody>
  );
};
