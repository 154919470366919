import React, { useCallback } from "react";
import { FAQBody } from "../support/FAQ/FAQ";
import { WithPopover } from "../page/popover/WithPopover";
import { SupportPopover } from "./SupportButton";
import { useScaffold } from "@Light/scaffold";

export type FAQProps = {};

export const FAQ: React.FC<FAQProps> = ({}) => {
  const scaffold = useScaffold();
  const Button = scaffold.page.button;
  const Link = scaffold.system.link;
  return (
    <FAQBody
      contactWrapper={({ children }) => (
        <WithPopover popover={<SupportPopover />}>
          {useCallback(({ open }) => {
            return <div onClick={open}>{children}</div>;
          }, [])}
        </WithPopover>
      )}
      footer={
        <Link to="/" className="w-full pt-4">
          <Button className="w-full">Close</Button>
        </Link>
      }
    />
  );
};
