import React from "react";
import { clsx } from "clsx";
import { useScaffold } from "@Light/scaffold";

export type MenuHeaderTitleProps = React.ComponentProps<"span"> & {};

export const MenuHeaderTitle: React.FC<MenuHeaderTitleProps> = React.forwardRef<
  HTMLElement,
  MenuHeaderTitleProps
>(({ children, className, ...props }, ref) => {
  const scaffold = useScaffold();
  const HeaderLogo = scaffold.page.header.headerLogo;
  return (
    <span
      ref={ref}
      {...props}
      className={clsx(
        "text-lg font-medium",
        scaffold.page.colors.text.primary,
        className,
      )}
    >
      {children ?? (HeaderLogo && <HeaderLogo />) ?? scaffold.page.companyName}
    </span>
  );
});
