import { getCsrfCookie } from "@Light/services/apiHelpers";
import { createLightSandboxApi } from "@Light/services/lightSandbox";
import { fetchBaseQuery } from "@reduxjs/toolkit/query";

export const baseQuery = fetchBaseQuery({
  baseUrl: "/api/v1/account",
  prepareHeaders: (headers) => {
    const csrfToken = getCsrfCookie();
    if (csrfToken) {
      headers.set("X-CSRFToken", csrfToken);
    }
    return headers;
  },
});

export const lightSandbox = createLightSandboxApi(baseQuery);
