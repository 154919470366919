import { useAPI } from "@/services/api";
import { SignupRequest } from "@/services/apiTypes";
import { MutationButton } from "@Light/components/page/MutationButton";
import { PageBody } from "@Light/components/page/PageBody";
import { Input } from "@Light/components/system/Input";
import { useMutation } from "@Light/utils/mutation";
import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useSearchParams } from "react-router-dom";

export type SignupProps = {};

export const Signup: React.FC<SignupProps> = ({}) => {
  const [searchParams] = useSearchParams();
  const plan = searchParams.get("plan") ?? null;
  const { useSignupMutation } = useAPI();
  const signup = useMutation<SignupRequest, void>(useSignupMutation);
  const { register, handleSubmit, watch } = useForm();
  const { email } = watch();
  const mutate = handleSubmit(
    useCallback(
      ({ email }) => {
        signup.mutate({
          email,
          plan,
        });
      },
      [signup.mutate],
    ),
  );
  if (signup.isSuccess) {
    const searchParams = new URLSearchParams({
      email,
    });
    if (plan) {
      searchParams.set("plan", plan);
    }
    return <Navigate to={`/shop/email-sent?${searchParams}`} />;
  }
  return (
    <PageBody
      title="What is your email address?"
      subtitle={
        <>
          Before we can walk you through the plan enrollment, we need to verify
          your email address.
        </>
      }
    >
      <form className="flex flex-col gap-6">
        <Input
          type="email"
          placeholder="Email"
          {...register("email", { required: true })}
        />
        <MutationButton
          mutateButtonText="Submit"
          mutation={signup}
          mutate={mutate}
          errorMessage="Failed selecting the plan. Please try again later."
        />
      </form>
    </PageBody>
  );
};
