import { useLight } from "@Light/services/light";
import { useScaffold } from "@Light/scaffold";
import { useHeader } from "@Light/components/page/header/context";
import { Query, QueryLoader } from "@Light/components/app/QueryLoader";
import { BillingContent } from "./BillingContent";
import { useIsPastDue } from "./common";
import { PastDueNotification } from "./PastDueNotification";

export type BillingProps = {
  hideBack?: boolean;
};

export const Billing: React.FC<BillingProps> = ({ hideBack }) => {
  const { setBackNavigate } = useHeader();

  if (!hideBack) {
    setBackNavigate("/dash/");
  }

  const { useGetBillingInvoicesQuery } = useLight();
  const billingInvoices = useGetBillingInvoicesQuery();
  const firstInvoice = billingInvoices.data?.data[0];
  const isPastDue = useIsPastDue(firstInvoice);

  const scaffold = useScaffold();
  const PageBody = scaffold.page.pageBody;
  return (
    <PageBody
      notification={isPastDue ? <PastDueNotification /> : null}
      className="pt-8"
    >
      <QueryLoader
        queries={[billingInvoices as Query]}
        errorMessage="Error loading invoice data. Please try again later."
      >
        <BillingContent billingInvoices={billingInvoices.data?.data ?? []} />
      </QueryLoader>
    </PageBody>
  );
};
