import { useScaffold } from "@Light/scaffold";
import { BillingInvoice } from "@Light/services/lightTypes";
import clsx from "clsx";
import dayjs from "dayjs";

export type InvoiceComparisonProps = {
  invoice?: BillingInvoice;
  label: string;
  dateFormat: string;
};

export const InvoiceComparison: React.FC<InvoiceComparisonProps> = ({
  invoice,
  label,
  dateFormat,
}) => {
  const scaffold = useScaffold();
  return (
    <div className="flex flex-col p-2 w-1/3 h-full">
      <div className={clsx("text-xs", scaffold.page.colors.text.secondary)}>
        {label}
      </div>
      {invoice ? (
        <>
          <div>{Math.round(parseFloat(invoice.total_kwh))} kWh</div>
          <div className={clsx("text-2xs", scaffold.page.colors.text.tertiary)}>
            {dayjs(invoice.invoice_date).format(dateFormat)}
          </div>
        </>
      ) : (
        <div>---</div>
      )}
    </div>
  );
};
