import { Navigate, Routes, Route } from "react-router-dom";
import { Account } from "./Account";
import { useHeader } from "../page/header/context";
import { DetailsRoute } from "./Details";
import { PaymentMethodRoute } from "./PaymentMethod/PaymentMethodRoute";
import { ServiceDetailsRoute } from "./ServiceDetails/ServiceDetailsRoute";
import { WithHeader } from "../page/header";
import { DocumentsRoute } from "./Documents/DocumentsRoute";

export const AccountRoute: React.FC = () => {
  return (
    <WithHeader>
      <AccountRouteWithHeader />
    </WithHeader>
  );
};

export const AccountRouteWithHeader: React.FC = () => {
  const { setDivider } = useHeader();
  setDivider(false);
  return (
    <Routes>
      <Route path="/documents/" element={<DocumentsRoute />} />
      <Route path="/*" element={<AccountRouteWithService />} />
    </Routes>
  );
};

export const AccountRouteWithService: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Account />} />
      <Route path="/details/*" element={<DetailsRoute />} />
      <Route path="/service-details/*" element={<ServiceDetailsRoute />} />
      <Route path="/payment-method/*" element={<PaymentMethodRoute />} />
      <Route path="/*" element={<Navigate to="/" />} />
    </Routes>
  );
};
