import { ShopPlan } from "@/services/apiTypes";
import { useScaffold } from "@Light/scaffold";
import clsx from "clsx";
import { checkSolarBuybackMatch } from "./utils";
import { Term } from "./term";

export type RateProps = {
  plan: ShopPlan;
};

export const Rate: React.FC<RateProps> = ({ plan }) => {
  const hasSolarBuybackMatch = checkSolarBuybackMatch(plan);
  const scaffold = useScaffold();
  if (hasSolarBuybackMatch) {
    return null;
  }
  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-col gap-2">
        <div className={clsx(scaffold.page.colors.text.primary)}>
          <span className={clsx("text-2xl font-medium")}>
            {plan.avg_cents_per_kwh_1000}¢
          </span>
          <span className="font-medium">/kWh price at 1000 kWh</span>
        </div>
      </div>
      <Term plan={plan} />
    </div>
  );
};
