import { useScaffold } from "@Light/scaffold";
import { BilllingInvoiceCharge } from "@Light/services/lightTypes";
import clsx from "clsx";
import { DollarAmount } from "./DollarAmount";

export type ChargeProps = {
  charge: BilllingInvoiceCharge;
};

export const Charge: React.FC<ChargeProps> = ({ charge }) => {
  const scaffold = useScaffold();
  return (
    <div className="flex flex-row justify-between items-start w-full">
      <div className="flex flex-col">
        <div className={clsx("text-sm", scaffold.page.colors.text.secondary)}>
          {charge.description}
        </div>
        {charge.quantity_text ? (
          <div className={clsx("text-xs", scaffold.page.colors.text.secondary)}>
            {charge.quantity_text} @ {charge.rate_text}
          </div>
        ) : null}
      </div>
      <div className="flex flex-row">
        <DollarAmount
          className={clsx("text-sm", scaffold.page.colors.text.secondary)}
          amount={charge.amount}
        />
        <div className="w-6" />
      </div>
    </div>
  );
};
