export type LogoProps = {};

export const Logo: React.FC<LogoProps> = ({}) => {
  return (
    <svg
      width="101"
      height="100"
      viewBox="0 0 101 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="50.5" cy="50" r="49.5" fill="white" stroke="black" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.5752 45.9403H22.0924C19.0038 45.9403 16.5 43.4402 16.5 40.3561C16.5 37.272 19.0038 34.7719 22.0924 34.7719C22.1123 34.7719 22.1323 34.772 22.1521 34.7722C22.6493 30.4469 26.1887 27.0936 30.481 27.0936C35.0051 27.0936 38.6927 30.8188 38.8634 35.481C41.5989 35.6576 44.006 38.3199 44.006 41.0961C44.006 43.3755 42.3179 45.9403 40.3181 45.9403H26.4115"
        fill="white"
      />
      <path
        d="M22.5752 45.9403H22.0924C19.0038 45.9403 16.5 43.4402 16.5 40.3561C16.5 37.272 19.0038 34.7719 22.0924 34.7719C22.1123 34.7719 22.1323 34.772 22.1521 34.7722C22.6493 30.4469 26.1887 27.0936 30.481 27.0936C35.0051 27.0936 38.6927 30.8188 38.8634 35.481C41.5989 35.6576 44.006 38.3199 44.006 41.0961C44.006 43.3755 42.3179 45.9403 40.3181 45.9403H26.4115"
        stroke="black"
        strokeLinecap="round"
      />
      <path
        d="M53.4122 69.0703C53.3168 69.1901 53.2096 69.2993 53.0924 69.3961C52.1624 70.1646 50.7979 70.0216 50.0406 69.0703C44.8002 62.4869 40.8821 56.78 38.2763 51.9463C35.6647 47.102 34.3984 43.1838 34.3984 40.1645C34.3984 30.3998 42.1652 22.5 51.7264 22.5C61.2876 22.5 69.0543 30.3998 69.0543 40.1645C69.0543 43.1838 67.7881 47.102 65.1765 51.9463C62.5707 56.78 58.6525 62.4869 53.4122 69.0703Z"
        fill="white"
        stroke="black"
      />
      <path
        d="M47.4678 36.2625L56.2225 45.0172"
        stroke="black"
        strokeLinecap="round"
      />
      <path
        d="M47.4678 45.0171L56.2225 36.2624"
        stroke="black"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52.2482 67.65C52.2942 67.6114 52.3368 67.5674 52.3751 67.5187C57.3416 61.1908 61.0252 55.7421 63.4597 51.1621C65.9137 46.5453 67.0065 42.9791 67.0065 40.37C67.0065 31.6005 60.1352 24.5466 51.7254 24.5466C43.3157 24.5466 36.4443 31.6005 36.4443 40.37C36.4443 42.9791 37.5372 46.5453 39.9912 51.1621C42.4257 55.7421 46.1093 61.1908 51.0758 67.5187C51.3742 67.8989 51.8928 67.9478 52.2482 67.65ZM51.7254 48.901C56.2801 48.901 59.9724 45.1562 59.9724 40.5369C59.9724 35.9175 56.2801 32.1727 51.7254 32.1727C47.1708 32.1727 43.4785 35.9175 43.4785 40.5369C43.4785 45.1562 47.1708 48.901 51.7254 48.901Z"
        fill="black"
      />
      <path
        d="M43.0029 68.3356C43.2747 68.2869 43.4556 68.0271 43.4069 67.7553C43.3583 67.4834 43.0985 67.3025 42.8266 67.3512L43.0029 68.3356ZM39.29 68.1783C39.0254 68.2575 38.8752 68.5361 38.9544 68.8007C39.0335 69.0652 39.3122 69.2155 39.5767 69.1363L39.29 68.1783ZM37.5147 69.8746C37.7683 69.7654 37.8854 69.4713 37.7762 69.2176C37.667 68.964 37.3729 68.8469 37.1192 68.9561L37.5147 69.8746ZM60.8941 67.4002C60.6226 67.3496 60.3616 67.5287 60.311 67.8001C60.2605 68.0716 60.4395 68.3327 60.711 68.3832L60.8941 67.4002ZM42.8266 67.3512C41.5454 67.5806 40.3581 67.8586 39.29 68.1783L39.5767 69.1363C40.6034 68.829 41.7532 68.5593 43.0029 68.3356L42.8266 67.3512ZM37.1192 68.9561C36.0148 69.4316 35.102 69.9763 34.4577 70.5868C33.8126 71.1979 33.3984 71.9138 33.3984 72.7153H34.3984C34.3984 72.2831 34.6186 71.8118 35.1454 71.3128C35.6728 70.8131 36.4693 70.3247 37.5147 69.8746L37.1192 68.9561ZM33.3984 72.7153C33.3984 73.7208 34.046 74.5878 35.0012 75.2976C35.9658 76.0144 37.3297 76.6387 38.9702 77.1543C42.257 78.1873 46.7669 78.8184 51.7264 78.8184V77.8184C46.8397 77.8184 42.4357 77.1952 39.27 76.2003C37.6843 75.7019 36.4373 75.1189 35.5977 74.495C34.7486 73.8641 34.3984 73.2571 34.3984 72.7153H33.3984ZM51.7264 78.8184C56.6859 78.8184 61.1957 78.1873 64.4826 77.1543C66.123 76.6387 67.4869 76.0144 68.4515 75.2976C69.4068 74.5878 70.0543 73.7208 70.0543 72.7153H69.0543C69.0543 73.2571 68.7042 73.8641 67.8551 74.495C67.0154 75.1189 65.7685 75.7019 64.1827 76.2003C61.0171 77.1952 56.613 77.8184 51.7264 77.8184V78.8184ZM70.0543 72.7153C70.0543 71.388 68.9359 70.299 67.3806 69.4596C65.7845 68.5982 63.5407 67.8931 60.8941 67.4002L60.711 68.3832C63.3037 68.8661 65.4358 69.5464 66.9057 70.3397C68.4165 71.155 69.0543 71.9898 69.0543 72.7153H70.0543Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M63.2843 63.259H62.9324C60.6819 63.259 58.8575 61.4346 58.8575 59.184C58.8575 56.9335 60.6819 55.1091 62.9324 55.1091C62.947 55.1091 62.9615 55.1091 62.976 55.1093C63.3382 51.9529 65.9172 49.506 69.0449 49.506C72.3414 49.506 75.0284 52.2243 75.1528 55.6265C77.1461 55.7553 78.9 57.6982 78.9 59.724C78.9 61.3873 77.67 63.259 76.2128 63.259H66.0796"
        fill="white"
      />
      <path
        d="M63.2843 63.259H62.9324C60.6819 63.259 58.8575 61.4346 58.8575 59.184C58.8575 56.9335 60.6819 55.1091 62.9324 55.1091C62.947 55.1091 62.9615 55.1091 62.976 55.1093C63.3382 51.9529 65.9172 49.506 69.0449 49.506C72.3414 49.506 75.0284 52.2243 75.1528 55.6265C77.1461 55.7553 78.9 57.6982 78.9 59.724C78.9 61.3873 77.67 63.259 76.2128 63.259H66.0796"
        stroke="black"
        strokeLinecap="round"
      />
    </svg>
  );
};
