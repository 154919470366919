import { useCallback, useEffect, useMemo } from "react";
import {
  AcceptPlanRequest,
  RateRequest,
  RateResponse,
} from "@Light/services/lightTypes";
import { useLight } from "@Light/services/light";
import { useScaffold } from "@Light/scaffold";
import { useMutation } from "@Light/utils/mutation";
import { clsx } from "clsx";
import { useEnroll } from "../context";
import { useForm } from "react-hook-form";
import { ProductInfo } from "./ProductInfo";
import { OfferSummary } from "./OfferSummary";
import { EnergyRate } from "./EnergyRate";
import { VehicleChargingCard } from "./VehicleChargingCard";
import { SolarBuybackCard } from "./SolarBuybackCard";
import { DocumentList } from "@Light/components/account/Documents/DocumentList";
import { useEnrollRoutes } from "../routes";
import { useAccount } from "@Light/components/page/account";
import { QueryLoader } from "@Light/components/app/QueryLoader";
import { useState } from "@Light/utils/state";
import { TransparentBillingCard } from "./TransparentBillingCard";
import { RateContext, usePlan, useRate } from "@Light/utils/plan";
import { SolarBuybackWithStorageCard } from "./SolarBuybackWithStorageCard";

export type ConfirmRateProps = {};

export const ConfirmRate: React.FC<ConfirmRateProps> = ({}) => {
  const { address } = useEnroll();
  const account = useAccount();
  const location = account.locations?.[0];
  const esi_id = address.val?.esi_id ?? location?.utility_number ?? "";
  const { useRequestPlansMutation } = useLight();
  const requestRates = useMutation<RateRequest, RateResponse[]>(
    useRequestPlansMutation,
  );
  const plan = usePlan();

  useEffect(() => {
    if (requestRates.isUninitialized) {
      requestRates.mutate({ esi_id, plan_group: plan.plan.uuid });
    }
  }, [requestRates.isUninitialized, requestRates.mutate, esi_id]);

  const rate = useMemo(() => {
    for (const requestedRate of requestRates?.data ?? []) {
      // Use the selected rate if given
      if (plan.plan.uuid && plan.plan.uuid === requestedRate.group_uuid) {
        return requestedRate;
      }

      // Use the default rate if not selected
      if (!plan.plan.uuid && requestedRate.is_default_for_app) {
        return requestedRate;
      }
    }

    // Fall back to the first rate of the set
    return requestRates?.data?.[0];
  }, [requestRates?.data, plan.plan.uuid]);

  const isError = requestRates.isError || (requestRates.isSuccess && !rate);
  const isSuccess = requestRates.isSuccess && !isError;
  return (
    <QueryLoader
      queries={[{ ...requestRates, isError, isSuccess }]}
      errorMessage="Error querying available rates. Please try again later"
    >
      <ConfirmRateB1 esi_id={esi_id} rate={rate} />
    </QueryLoader>
  );
};

export type ConfirmRateB1Props = {
  esi_id: string;
  rate?: RateResponse;
};

export const ConfirmRateB1: React.FC<ConfirmRateB1Props> = ({
  rate,
  ...props
}) => {
  if (!rate) {
    throw new Error("Cannot render ConfirmRateB1 without a selected rate");
  }

  return (
    <RateContext.Provider value={rate}>
      <ConfirmRateB2 {...props} />
    </RateContext.Provider>
  );
};

export type ConfirmRateB2Props = {
  esi_id: string;
};

export const ConfirmRateB2: React.FC<ConfirmRateB2Props> = ({ esi_id }) => {
  const scaffold = useScaffold();
  const { useAcceptPlanMutation } = useLight();
  const acceptRate = useMutation<AcceptPlanRequest>(useAcceptPlanMutation);
  const { handleSubmit, register } = useForm();
  const { serviceEvent } = useEnroll();
  const { nextRoute } = useEnrollRoutes();
  const rate = useRate();
  const serviceStartDate = useState<string>(rate.earliest_start_date);
  const redirect = nextRoute("/rate");
  const plan = usePlan();

  const mutate = useCallback(
    handleSubmit(() => {
      acceptRate.mutate({
        plan_uuid: rate.uuid,
        esi_id,
        service_event: serviceEvent.val,
        service_start_date: serviceStartDate.val,
        terms_accepted: true,
      });
    }),
    [
      handleSubmit,
      acceptRate.mutate,
      rate.uuid,
      esi_id,
      serviceEvent.val,
      serviceStartDate.val,
    ],
  );

  if (acceptRate.isSuccess && redirect) {
    const Navigate = scaffold.system.navigate;
    return <Navigate to={redirect} />;
  }

  const PageBody = scaffold.page.pageBody;
  const Divided = scaffold.page.divided;
  const DetailLabel = scaffold.page.detail.detailLabel;
  const DetailField = scaffold.page.detail.detailField;
  const Vertical = scaffold.enroll.common.vertical;
  const MutationButton = scaffold.page.mutationButton;
  const Datepicker = scaffold.system.datepicker;
  const Input = scaffold.system.input;

  let subtitle: string | undefined = "Get the plan built for you.";
  const { solarBuyback, storageCredit, vehicleCharging } = plan.features;
  if (solarBuyback && storageCredit) {
    subtitle = "Built for rooftop solar with battery storage";
  } else if (solarBuyback) {
    subtitle = "Get the plan built for homes with solar.";
  } else if (vehicleCharging) {
    subtitle = "Get the plan built for EV owners.";
  }

  return (
    <PageBody
      title="Review your plan"
      subtitle={subtitle}
      footer={
        <MutationButton
          mutation={acceptRate}
          mutateButtonText="Accept & continue"
          mutate={mutate}
          errorMessage="Error accepting offer. Please try again later."
        />
      }
    >
      <Divided gap="8">
        <div className="flex flex-col gap-5">
          <TransparentBillingCard />
          <SolarBuybackWithStorageCard />
          <SolarBuybackCard />
          <VehicleChargingCard />
          <OfferSummary />
          <EnergyRate />
        </div>
        <div className="flex flex-col gap-6">
          <div className="flex flex-col gap-1">
            <DetailLabel>Service start date</DetailLabel>
            <Datepicker
              selectedDate={serviceStartDate}
              minSelectableDate={rate.earliest_start_date}
              maxSelectableDate={rate.latest_start_date}
            />
          </div>
          <DetailField label="Seamless transition">
            <div
              className={clsx("text-sm", scaffold.page.colors.text.tertiary)}
            >
              <div className="flex flex-col">
                <div>No need to cancel your other provider.</div>
                <div>We'll handle the switch; just pay your final bill.</div>
              </div>
            </div>
          </DetailField>
        </div>
        <Vertical>
          <div className="flex flex-col gap-7">
            <DocumentList
              efl={rate.links.efl}
              yrac={rate.links.yrac}
              tos={rate.links.tos}
              hideBrokerTermsOfService={true}
            />
            <ProductInfo />
          </div>
        </Vertical>
        <label className="flex flex-row items-start gap-4">
          <Input
            type="checkbox"
            {...register("terms_accepted", { required: true })}
          />
          <div className={clsx("text-sm", scaffold.page.colors.text.tertiary)}>
            I have read, understand and accept the disclosures and terms of
            service of Light, PUCT #10340.
          </div>
        </label>
      </Divided>
    </PageBody>
  );
};
