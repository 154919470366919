import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  LoginTokenRequest,
  ConfigurationResponse,
  LoginEmailTokenRequest,
  ShopRequest,
  ShopPlan,
  SignupRequest,
} from "./apiTypes";
import { getCsrfCookie } from "@Light/services/apiHelpers";
import { createContext, useContext } from "react";

export const baseQuery = fetchBaseQuery({
  baseUrl: "/api/v1",
  prepareHeaders: (headers) => {
    const csrfToken = getCsrfCookie();
    if (csrfToken) {
      headers.set("X-CSRFToken", csrfToken);
    }
    return headers;
  },
});

export const api = createApi({
  reducerPath: "api",
  baseQuery,
  tagTypes: ["Configuration"],
  endpoints: (builder) => ({
    loginEmailToken: builder.mutation<void, LoginEmailTokenRequest>({
      query: (body) => ({
        url: "/login/email-token",
        method: "POST",
        body,
      }),
    }),
    loginToken: builder.mutation<void, LoginTokenRequest>({
      query: (body) => ({
        url: "/login/token",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Configuration"],
    }),
    logout: builder.mutation<void, void>({
      query: (body) => ({
        url: "/logout",
        method: "POST",
        body,
      }),
    }),

    getConfiguration: builder.query<ConfigurationResponse, void>({
      query: () => ({
        url: "/configuration",
        method: "GET",
      }),
      providesTags: ["Configuration"],
    }),

    shop: builder.mutation<ShopPlan[], ShopRequest>({
      query: (body) => ({
        url: "/shop",
        method: "POST",
        body,
      }),
    }),
    signup: builder.mutation<void, SignupRequest>({
      query: (body) => ({
        url: "/signup",
        method: "POST",
        body,
      }),
    }),
  }),
});

export const { useGetConfigurationQuery } = api;

export const APIContext = createContext(api);
export function useAPI() {
  return useContext(APIContext);
}
