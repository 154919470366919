import { QueryLoader } from "@Light/components/app/QueryLoader";
import { MonthlySelector } from "./MonthlySelector";
import { useMonthlyUsage } from "./interval";
import { MonthlyIntervalBody } from "./MonthlyIntervalBody";

export type MonthlyIntervalUsageProps = {};

export const MonthlyIntervalUsage: React.FC<
  MonthlyIntervalUsageProps
> = ({}) => {
  const monthlyUsage = useMonthlyUsage();
  return (
    <div className="flex flex-col gap-6">
      <div className="border-y -mx-4">
        <MonthlySelector />
      </div>
      <QueryLoader
        queries={[monthlyUsage]}
        errorMessage="Failed querying usage data."
      >
        <MonthlyIntervalBody />
      </QueryLoader>
    </div>
  );
};
