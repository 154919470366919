import { useHeader } from "@Light/components/page/header/context";
import { useScaffold } from "@Light/scaffold";
import React from "react";
import { PaymentMethodCard } from "./PaymentMethodCard";
import { PlusIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { QueryLoader } from "@Light/components/app/QueryLoader";
import { useLight } from "@Light/services/light";

export type PaymentMethodProps = {};

export const PaymentMethod: React.FC<PaymentMethodProps> = ({}) => {
  const scaffold = useScaffold();
  const { setTitle, setBackNavigate } = useHeader();
  const { useGetPaymentMethodQuery } = useLight();
  const paymentMethod = useGetPaymentMethodQuery();

  setTitle("Payment method");
  setBackNavigate("/account/");

  const PageBody = scaffold.page.pageBody;
  const Divided = scaffold.page.divided;
  const DetailLabel = scaffold.page.detail.detailLabel;
  const Link = scaffold.system.link;
  return (
    <QueryLoader
      queries={[paymentMethod]}
      errorMessage="Failed querying payment method. Please try again later"
    >
      <PageBody>
        <Divided className="pt-8">
          <div className="flex flex-col gap-2">
            <DetailLabel>Automatic payments</DetailLabel>
            <div>
              Your invoice will be available at the end of each billing cycle,
              and your credit card will be charged 16 days later
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <DetailLabel>Card details</DetailLabel>
            <PaymentMethodCard />
            <Link
              className={clsx(
                "flex flex-row items-center font-medium gap-2",
                scaffold.page.colors.text.secondary,
              )}
              to="/account/payment-method/update"
            >
              <PlusIcon className="w-6 h-6" />
              <div>Update payment method</div>
            </Link>
          </div>
        </Divided>
      </PageBody>
    </QueryLoader>
  );
};
