import React, { useCallback } from "react";
import { QuestionMarkCircleIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { WithPopover, usePopover } from "../page/popover/WithPopover";
import { useScaffold } from "@Light/scaffold";
import clsx from "clsx";
import { ServiceLink } from "@Light/components/support/ContactUs/ServiceLink";
import { ArrowRightIcon } from "@heroicons/react/24/solid";

export type SupportButtonProps = {};

export const SupportButton: React.FC<SupportButtonProps> = () => {
  return (
    <WithPopover popover={<SupportPopover />}>
      {useCallback(
        ({ open }) => (
          <QuestionMarkCircleIcon
            className="w-7 h-7 stroke-gray-500 cursor-pointer"
            onClick={open}
          />
        ),
        [],
      )}
    </WithPopover>
  );
};

export type SupportPopoverProps = {
  hideFAQ?: boolean;
};
export const SupportPopover: React.FC<SupportPopoverProps> = ({ hideFAQ }) => {
  const { close } = usePopover();
  const onClickFAQ = useCallback(() => {
    close();
  }, []);

  const scaffold = useScaffold();
  const CircleIcon = scaffold.page.circleIcon;
  const Button = scaffold.page.button;
  const Link = scaffold.system.link;
  return (
    <div className="p-4 flex flex-col gap-6">
      <div className="flex flex-row justify-between items-center">
        <CircleIcon
          className={clsx("w-10 h-10", scaffold.page.colors.background.info)}
        >
          <QuestionMarkCircleIcon className={scaffold.page.colors.text.info} />
        </CircleIcon>
        <XMarkIcon
          className={clsx("w-8 h-8 text-gray-400 cursor-pointer")}
          onClick={close}
        />
      </div>
      <div className="flex flex-col gap-2">
        <div
          className={clsx(
            "text-lg font-medium flex flex-col",
            scaffold.page.colors.text.primary,
          )}
        >
          Contact us for assistance
        </div>
      </div>
      <div className="flex flex-col gap-3">
        <ServiceLink
          label="Email"
          href={`mailto:${scaffold.page.supportEmail}`}
        >
          {scaffold.page.supportEmail}
        </ServiceLink>
        <ServiceLink
          label={
            scaffold.page.supportHours
              ? `Phone: ${scaffold.page.supportHours}`
              : "Phone"
          }
          href={`tel:${scaffold.page.supportPhone}`}
        >
          {scaffold.page.supportPhone}
        </ServiceLink>
      </div>
      {!hideFAQ && (
        <Link to="/enroll/faq" onClick={onClickFAQ}>
          <div
            className={clsx(
              "flex flex-row gap-1 items-center",
              "text-xs font-medium",
              scaffold.page.colors.text.info,
            )}
          >
            View FAQ
            <ArrowRightIcon className="w-4 h-4" />
          </div>
        </Link>
      )}
      <Button buttonType="outline" onClick={close}>
        Done
      </Button>
    </div>
  );
};
