import { Navigate, Routes, Route } from "react-router-dom";
import { Enroll } from "@Light/components/enroll";
import { RequireLoggedIn } from "@Light/components/auth/RequireLoggedIn";
import { DashRoute } from "@Light/components/dash/DashRoute";
import { WithAccount } from "@Light/components/page/account/WithAccount";
import { AccountRoute } from "./account";
import { SupportRoute } from "./support";
import { SandboxRoute } from "@Light/components/sandbox/SandboxRoute";
import { useScaffold } from "@Light/scaffold";
import { useAccount } from "@Light/components/page/account";
import { WithPlan } from "@Light/utils/plan";

export const App: React.FC = () => {
  const scaffold = useScaffold();
  const Overlay = scaffold.page.overlay;
  return (
    <RequireLoggedIn redirect="/login/">
      <WithAccount>
        <Overlay>
          <LoggedInApp />
        </Overlay>
      </WithAccount>
    </RequireLoggedIn>
  );
};

export const LoggedInApp: React.FC = () => {
  const account = useAccount();
  const enrollRoute = <Route path="/enroll/*" element={<Enroll />} />;
  const sandboxRoute = <Route path="/sandbox/*" element={<SandboxRoute />} />;
  if (!account.enrollment.is_enrollment_finalized) {
    return (
      <Routes>
        {enrollRoute}
        {sandboxRoute}
        <Route path="/*" element={<Navigate to="/enroll/" />} />
      </Routes>
    );
  }

  // These "service" routes are now supposed to be active when service has not
  // yet been activated but the customer has completed enrollment.
  const serviceRoutes = (
    <>
      <Route path="/dash/*" element={<DashRoute />} />
      <Route path="/account/*" element={<AccountRoute />} />
      <Route path="/support/*" element={<SupportRoute />} />
    </>
  );
  if (!account.enrollment.is_service_active) {
    return (
      <WithPlan>
        <Routes>
          {enrollRoute}
          {sandboxRoute}
          {serviceRoutes}
          <Route path="/*" element={<Navigate to="/dash/" />} />
        </Routes>
      </WithPlan>
    );
  }

  return (
    <WithPlan>
      <Routes>
        {sandboxRoute}
        {serviceRoutes}
        <Route path="/*" element={<Navigate to="/dash/" />} />
      </Routes>
    </WithPlan>
  );
};
