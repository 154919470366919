import { useAPI, useGetConfigurationQuery } from "@/services/api";
import { defaultScaffold } from "@Light/scaffold/default";
import { createContext, useContext } from "react";
import { Provider as ScaffoldProvider } from "@Light/scaffold/Provider";
import { LightContext } from "@Light/services/light";
import { light } from "@/services/light";
import { lightSandbox } from "@/services/lightSandbox";
import { LightSandboxContext } from "@Light/services/lightSandbox";
import { HeaderLogo } from "./HeaderLogo";
import { UpdateName } from "./account/Details/UpdateName";
import { UpdateEmail } from "./account/Details/UpdateEmail";
import { LogoutButton } from "./LogoutButton";
import { SmallLogo } from "./SmallLogo";
import { UploadInvoice } from "@Light/components/enroll/UploadInvoice";

export type AccountState = ReturnType<typeof useGetConfigurationQuery>;

export const ConfigurationContext = createContext<AccountState | undefined>(
  undefined,
);

export type WithConfigurationProps = {
  children: React.ReactNode;
};

export const WithConfiguration: React.FC<WithConfigurationProps> = ({
  children,
}: WithConfigurationProps) => {
  const { useGetConfigurationQuery } = useAPI();
  const configuration = useGetConfigurationQuery();

  if (configuration.isLoading) {
    return null;
  }

  children = (
    <ConfigurationContext.Provider value={configuration}>
      {children}
    </ConfigurationContext.Provider>
  );
  if (!configuration.data) {
    return children;
  }

  const showVehicleAuthorization = (
    configuration.data.feature_flags ?? []
  ).includes("CUSTOMER_CLIENT_ENABLE_VEHICLE_CHARGING");
  const showIntervalUsage = (configuration.data.feature_flags ?? []).includes(
    "CUSTOMER_CLIENT_SHOW_INTERVAL_USAGE",
  );
  const headerLogo = configuration.data?.logo ? HeaderLogo : undefined;

  const UploadInvoiceComponent = configuration.data
    ?.allow_comparison_invoice_uploads
    ? UploadInvoice
    : undefined;

  const scaffold = {
    ...defaultScaffold,
    page: {
      ...defaultScaffold.page,
      companyName: configuration.data.company_name,
      termsOfServiceUrl: configuration.data.terms_of_service_url ?? undefined,
      supportEmail: configuration.data.support_email,
      supportPhone: configuration.data.support_phone,
      supportHours: configuration.data.support_hours ?? "",
      header: {
        ...defaultScaffold.page.header,
        headerLogo,
        menu: {
          ...defaultScaffold.page.header.menu,
          logoutButton: LogoutButton,
        },
      },
    },
    account: {
      details: {
        updateName: UpdateName,
        updateEmail: UpdateEmail,
      },
    },
    dash: {
      showIntervalUsage,
    },
    enroll: {
      ...defaultScaffold.enroll,
      steps: {
        ...defaultScaffold.enroll.steps,
        offer: undefined,
        welcome: undefined,
        offerLogo: SmallLogo,
        uploadInvoice: UploadInvoiceComponent,
        showVehicleAuthorization,
      },
    },
  };
  return (
    <ScaffoldProvider scaffold={scaffold}>
      <LightContext.Provider value={light}>
        <LightSandboxContext.Provider value={lightSandbox}>
          {children}
        </LightSandboxContext.Provider>
      </LightContext.Provider>
    </ScaffoldProvider>
  );
};

export function useConfiguration() {
  const configuration = useContext(ConfigurationContext);
  if (!configuration || !configuration.isSuccess || configuration.error) {
    return undefined;
  }

  return configuration.data;
}

export function requireConfiguration() {
  const configuration = useConfiguration();
  if (!configuration) {
    throw new Error(
      "application configuration required to render this component",
    );
  }

  return configuration;
}

export function hasFeatureFlag(feature_flag: string) {
  const configuration = useConfiguration();
  if (!configuration) {
    return false;
  }

  return (configuration.feature_flags ?? []).includes(feature_flag);
}
