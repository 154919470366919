import { useEffect, useRef } from "react";
import { useState } from "./state";

export function useWidth<E extends HTMLElement = HTMLElement>(): [
  React.RefObject<E>,
  number,
] {
  const ref = useRef<E>(null);
  const width = useState(0);

  useEffect(() => {
    const handleResize = () => {
      if (!ref.current) {
        return;
      }
      width.setVal(ref.current.offsetWidth);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [ref.current, width.setVal]);

  return [ref, width.val];
}
