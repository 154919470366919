import React from "react";
import { useScaffold } from "@Light/scaffold";
import { useHeader } from "@Light/components/page/header/context";

const BACK_ROUTE = "/account/details/";

export type UpdateEmailProps = {};

export const UpdateEmail: React.FC<UpdateEmailProps> = ({}) => {
  const scaffold = useScaffold();
  const { setBackNavigate, setTitle } = useHeader();

  setTitle("Update your email");
  setBackNavigate(BACK_ROUTE);

  const PageBody = scaffold.page.pageBody;
  const Button = scaffold.page.button;
  const Link = scaffold.system.link;
  const Hyperlink = scaffold.page.hyperlink;
  return (
    <PageBody
      subtitle={
        <>
          To update your email address, please contact us at{" "}
          <Hyperlink href={`mailto:${scaffold.page.supportEmail}`}>
            {scaffold.page.supportEmail}
          </Hyperlink>
        </>
      }
      footer={
        <Link to={BACK_ROUTE}>
          <Button className="w-full">Return</Button>
        </Link>
      }
    />
  );
};
