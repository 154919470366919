import { useScaffold } from "@Light/scaffold";
import { IntervalChart, IntervalChartProps } from "./IntervalChart";
import { IntervalAggregation } from "./IntervalAggregation";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { Tooltip } from "react-tooltip";
import clsx from "clsx";
import { BillingCard } from "../BillingCard";
import { IntervalLegend } from "./IntervalLegend";
import { IntervalPercentages } from "./IntervalPercentages";

export type IntervalBodyProps = IntervalChartProps & {};

export const IntervalBody: React.FC<IntervalBodyProps> = (props) => {
  const { data } = props;
  const scaffold = useScaffold();
  const hasData = data.length > 0;
  return (
    <div className="flex flex-col gap-10 pb-4">
      <div className="flex flex-col gap-4">
        <IntervalAggregation data={data} />
        <div className="flex flex-col gap-2">
          <div className="flex justify-end w-full pr-10">
            <InformationCircleIcon
              data-tooltip-id="graph-tooltip"
              className="w-5 h-5 text-slate-400"
            />
          </div>
          <Tooltip id="graph-tooltip" place="top-end">
            <div className="w-48 text-xs">
              Reported meter data typically lags by a couple of days
            </div>
          </Tooltip>
          <div className="-mr-2">
            <div className="relative">
              <IntervalChart {...props} />
              {!hasData && (
                <div className="absolute w-full h-full flex justify-center items-center text-center top-0 left-0">
                  <div
                    className={clsx(
                      "w-56 text-sm",
                      scaffold.page.colors.text.tertiary,
                    )}
                  >
                    Reported meter data typically lags by a couple of days
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <IntervalLegend />
      </div>
      <IntervalPercentages data={data} />
      <BillingCard />
    </div>
  );
};
