export function SolarBuybackIcon(props: React.ComponentPropsWithoutRef<"svg">) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z"
        fill="#FFFAEB"
      />
      <g clipPath="url(#clip0_4620_19704)">
        <path
          d="M16 9.33337V10.6667M16 21.3334V22.6667M10.6667 16H9.33334M12.2094 12.2095L11.2666 11.2666M19.7906 12.2095L20.7334 11.2666M12.2094 19.7934L11.2666 20.7362M19.7906 19.7934L20.7334 20.7362M22.6667 16H21.3333M19.3333 16C19.3333 17.841 17.841 19.3334 16 19.3334C14.1591 19.3334 12.6667 17.841 12.6667 16C12.6667 14.1591 14.1591 12.6667 16 12.6667C17.841 12.6667 19.3333 14.1591 19.3333 16Z"
          stroke="#B54708"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4620_19704">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(8 8)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
