import { useScaffold } from "@Light/scaffold";
import { Vehicle } from "@Light/services/lightTypes";
import clsx from "clsx";

export type VehicleFieldProps = {
  vehicle: Vehicle;
};

export const VehicleField: React.FC<VehicleFieldProps> = ({ vehicle }) => {
  const { year, brand, model, vin } = vehicle;
  const scaffold = useScaffold();
  return (
    <div className="flex flex-col">
      <div
        className={clsx(
          "text-sm font-medium",
          scaffold.page.colors.text.primary,
        )}
      >
        {year} {brand} {model}
      </div>
      <div className={clsx("text-xs", scaffold.page.colors.text.secondary)}>
        VIN {vin}
      </div>
    </div>
  );
};
