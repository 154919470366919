import { useScaffold } from "@Light/scaffold";
import clsx from "clsx";
import React from "react";

export type ItemProps = {
  icon: React.ReactNode;
  title: string;
  children: React.ReactNode;
};

export const Item: React.FC<ItemProps> = ({ icon, title, children }) => {
  const scaffold = useScaffold();
  return (
    <div className="flex flex-row items-top gap-4">
      <div className="flex border rounded-lg p-2 w-10 h-10 items-center justify-center">
        {icon}
      </div>
      <div className="flex flex-col gap-2">
        <div className={clsx("font-medium", scaffold.page.colors.text.primary)}>
          {title}
        </div>
        <div className={clsx("text-sm", scaffold.page.colors.text.secondary)}>
          {children}
        </div>
      </div>
    </div>
  );
};
