import { useContext } from "react";
import { Scaffold } from "./scaffold";
import { ScaffoldContext } from "./context";

export function useScaffold(): Scaffold {
  const scaffold = useContext(ScaffoldContext);
  if (!scaffold) {
    throw new Error("attempting to access scaffold with none defined");
  }

  return scaffold;
}
