import clsx from "clsx";

export type AmericanExpressCardProps = React.ComponentProps<"div">;
export const AmericanExpressCard: React.FC<AmericanExpressCardProps> = ({
  className,
  ...props
}) => {
  return (
    <div
      className={clsx(
        "rounded-md border-2 w-14 h-10 p-1 flex items-center justify-center",
        className,
      )}
      {...props}
    >
      <AmericanExpressLogo style={{ position: "relative" }} />
    </div>
  );
};

export type AmericanExpressLogoProps = React.ComponentPropsWithoutRef<"svg">;
export const AmericanExpressLogo: React.FC<AmericanExpressLogoProps> = ({
  ...props
}) => {
  return (
    <svg enableBackground="new 0 0 40 40" viewBox="0 0 40 40" {...props}>
      <g id="E-Com">
        <g id="Amex_3_">
          <g id="Amex">
            <g>
              <path
                clipRule="evenodd"
                d="M34,9.5H6c-1.1,0-2,0.9-2,2v17c0,1.1,0.9,2,2,2h28      c1.1,0,2-0.9,2-2v-17C36,10.4,35.1,9.5,34,9.5z"
                fill="#3498D8"
                fillRule="evenodd"
              />
            </g>
          </g>
          <g id="Amex_1_">
            <g>
              <path
                clipRule="evenodd"
                d="M10.7,20.3h1.6l-0.8-2L10.7,20.3z M33,16.5h-4.1l-1,1.1      l-0.9-1.1h-8.7l-0.8,1.8l-0.8-1.8h-3.5v0.8l-0.4-0.8h-3l-2.9,7h3.5l0.4-1.1h1l0.4,1.1h3.9v-0.8l0.3,0.8h2l0.3-0.9v0.9h8l1-1.1      l0.9,1.1l4.1,0L30.1,20L33,16.5z M20.9,22.5h-1.1l0-3.9l-1.7,3.9h-1l-1.7-3.9v3.9h-2.3l-0.4-1.1h-2.4l-0.4,1.1H8.6l2.1-5h1.7      l1.9,4.7v-4.7h1.9l1.5,3.4l1.4-3.4h1.9V22.5z M30.8,22.5h-1.5L28,20.8l-1.5,1.7h-4.5v-5h4.6l1.4,1.6l1.5-1.6h1.4L28.7,20      L30.8,22.5z M23.1,18.5v0.9h2.5v1h-2.5v1h2.8l1.3-1.5L26,18.5H23.1z"
                fill="#FFFFFF"
                fillRule="evenodd"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
