import { useScaffold } from "@Light/scaffold";
import { BaseMenuProps } from "./BaseMenu";

export type EnrollmentMenuProps = BaseMenuProps;

export const EnrollmentMenu: React.FC<EnrollmentMenuProps> = (props) => {
  const scaffold = useScaffold();
  const BaseMenu = scaffold.page.header.menu.baseMenu;
  const MenuItem = scaffold.page.header.menu.menuItem;
  return (
    <BaseMenu {...props}>
      <MenuItem title="FAQ" route="/enroll/faq" />
    </BaseMenu>
  );
};
