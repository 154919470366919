import { useScaffold } from "@Light/scaffold";
import clsx from "clsx";

export type InfoItemProps = {
  title: string;
  children: React.ReactNode;
};

export const InfoItem: React.FC<InfoItemProps> = ({ title, children }) => {
  const scaffold = useScaffold();
  return (
    <div className="flex flex-col">
      <div
        className={clsx(
          "text-sm font-medium",
          scaffold.page.colors.text.primary,
        )}
      >
        {title}
      </div>
      <div className={clsx("text-sm", scaffold.page.colors.text.secondary)}>
        {children}
      </div>
    </div>
  );
};
