import { Routes, Route } from "react-router-dom";
import { Documents } from "./Documents";
import { PreviousDocumentsList } from "./PreviousDocumentsList";
import { PreviousDocuments } from "./PreviousDocuments";

export type DocumentsRouteProps = {
  hideRootBack?: boolean;
};

export const DocumentsRoute: React.FC<DocumentsRouteProps> = ({
  hideRootBack,
}) => {
  return (
    <Routes>
      <Route path="/" element={<Documents hideBack={hideRootBack} />} />
      <Route path="/previous" element={<PreviousDocumentsList />} />
      <Route path="/previous/:documentsUUID" element={<PreviousDocuments />} />
    </Routes>
  );
};
