import { useScaffold } from "@Light/scaffold";
import { ModeSelector } from "./ModeSelector";
import { DailyIntervalUsage } from "./DailyIntervalUsage";
import { MonthlyIntervalUsage } from "./MonthlyIntervalUsage";
import { useSearchParams } from "@Light/utils/context";
import { Notification } from "../Notification";

export type IntervalUsageProps = {};

export const IntervalUsage: React.FC<IntervalUsageProps> = ({}) => {
  const searchParams = useSearchParams()[0];
  const selectedMode = searchParams.get("mode") === "year" ? "year" : "month";
  const scaffold = useScaffold();
  const PageBody = scaffold.page.pageBody;
  const Title = scaffold.page.title;
  return (
    <PageBody>
      <div className="flex flex-col gap-6 pt-8">
        <Notification />
        <div className="flex justify-between">
          <Title>Energy usage</Title>
          <ModeSelector selectedMode={selectedMode} />
        </div>
        {selectedMode === "month" ? (
          <DailyIntervalUsage />
        ) : (
          <MonthlyIntervalUsage />
        )}
      </div>
    </PageBody>
  );
};
