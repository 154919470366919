import { Navigate, Route, Routes } from "react-router-dom";
import { EnodeAuthorization } from "./EnodeAuthorization";
import { useLight } from "@Light/services/light";
import { CheckAuthorized } from "./CheckAuthorized";
import { PickVehicles } from "./PickVehicles";
import { Vehicle } from "@Light/services/lightTypes";
import { QueryLoader } from "@Light/components/app/QueryLoader";

export type VehicleAuthorizationProps = {};

export const VehicleAuthorization: React.FC<
  VehicleAuthorizationProps
> = ({}) => {
  const { useGetVehiclesQuery } = useLight();
  const vehicles = useGetVehiclesQuery();

  return (
    <QueryLoader
      queries={[vehicles]}
      errorMessage="Failed loading vehicles. Please try again later."
    >
      <VehicleAuthorizationB1 vehicles={vehicles.data?.data as Vehicle[]} />
    </QueryLoader>
  );
};

export type VehicleAuthorizationB1Props = {
  vehicles: Vehicle[];
};

export const VehicleAuthorizationB1: React.FC<VehicleAuthorizationB1Props> = ({
  vehicles,
}) => {
  const hasVehicles = vehicles.length > 0;
  const redirect = hasVehicles
    ? "/enroll/authorize-vehicles/pick"
    : "/enroll/authorize-vehicles/authorize";
  return (
    <Routes>
      <Route path="/authorize" element={<EnodeAuthorization />} />
      <Route path="/check-authorized" element={<CheckAuthorized />} />
      <Route path="/pick" element={<PickVehicles vehicles={vehicles} />} />
      <Route path="/*" element={<Navigate to={redirect} />} />
    </Routes>
  );
};
