import React, { useMemo } from "react";
import clsx from "clsx";
import { useScaffold } from "@Light/scaffold";

export type DollarAmountProps = React.ComponentProps<"div"> & {
  amount: string;
  isPastDue?: boolean;
};
export const DollarAmount: React.FC<DollarAmountProps> = React.forwardRef<
  HTMLDivElement,
  DollarAmountProps
>(({ amount, isPastDue, className }, ref) => {
  const isNegative = amount.length > 0 && amount[0] === "-";
  const amountText = useMemo(
    () => (isNegative ? amount.slice(1) : amount),
    [isNegative, amount],
  );
  const scaffold = useScaffold();
  const textColor = isPastDue
    ? scaffold.page.colors.text.error
    : isNegative
      ? scaffold.page.colors.text.success
      : "";
  return (
    <div ref={ref} className={clsx(className, textColor)}>
      {isNegative ? "-" : ""}${amountText}
    </div>
  );
});
