import { useMonthlyUsage, useMonthlyIntervalParams } from "./interval";
import clsx from "clsx";
import { useScaffold } from "@Light/scaffold";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";

export type MonthlySelectorProps = {};

export const MonthlySelector: React.FC<MonthlySelectorProps> = ({}) => {
  const scaffold = useScaffold();
  const { selectedYear } = useMonthlyIntervalParams();
  const monthlyUsage = useMonthlyUsage();

  const prevMonth = selectedYear.subtract(1, "year");
  const nextMonth = selectedYear.add(1, "year");

  const prevLink = `/dash/usage?${new URLSearchParams({
    month: `${prevMonth.month() + 1}`,
    year: `${prevMonth.year()}`,
    mode: "year",
  })}`;
  const nextLink = `/dash/usage?${new URLSearchParams({
    month: `${nextMonth.month() + 1}`,
    year: `${nextMonth.year()}`,
    mode: "year",
  })}`;

  const hasPrev = monthlyUsage.isSuccess && Boolean(monthlyUsage.data.previous);
  const hasNext = monthlyUsage.isSuccess && Boolean(monthlyUsage.data.next);

  const Link = scaffold.system.link;
  return (
    <div className="flex flex-row justify-between items-center p-2">
      <div className="w-6 h-6">
        {hasPrev && (
          <Link to={prevLink}>
            <ChevronLeftIcon />
          </Link>
        )}
      </div>
      <div className={clsx("text-lg", scaffold.page.colors.text.secondary)}>
        {selectedYear.format("YYYY")}
      </div>
      <div className="w-6 h-6">
        {hasNext && (
          <Link to={nextLink}>
            <ChevronRightIcon />
          </Link>
        )}
      </div>
    </div>
  );
};
