import { useScaffold } from "@Light/scaffold";
import { Card } from "./Card";
import clsx from "clsx";
import { useRate } from "@Light/utils/plan";

export type SolarBuybackCardProps = {};

export const SolarBuybackCard: React.FC<SolarBuybackCardProps> = ({}) => {
  const scaffold = useScaffold();
  const rate = useRate();
  const storageCreditDollars = parseFloat(rate.storage_monthly_credit_dollars);
  const exportRate = parseFloat(rate.export_rate_cents_per_kwh);
  const assumedMonthlyExportKwh = rate.assumed_export_kwh;
  if (storageCreditDollars || !exportRate || !assumedMonthlyExportKwh) {
    return null;
  }

  const estDollarAnnualSavings = Math.round(
    (exportRate * assumedMonthlyExportKwh * 12) / 100,
  );

  return (
    <Card label="A typical customer earns">
      <div className="flex flex-col gap-3">
        <div>
          <span
            className={clsx(
              "text-2xl font-medium",
              scaffold.page.colors.text.primary,
            )}
          >
            ${estDollarAnnualSavings}
          </span>
          <span
            className={clsx(
              "text-lg font-medium",
              scaffold.page.colors.text.primary,
            )}
          >
            /year in buyback credits
          </span>
        </div>
        <div
          className={clsx(
            "text-sm",
            "font-normal",
            scaffold.page.colors.text.tertiary,
          )}
        >
          Our solar buyback plan gives credit when your panels produce more
          energy than you need.
        </div>
      </div>
    </Card>
  );
};
