import { BaseQueryFn, createApi } from "@reduxjs/toolkit/query/react";
import {
  LightAccount,
  AddressSearchResult,
  RateRequest,
  RateResponse,
  AcceptPlanResponse,
  AcceptPlanRequest,
  CreatePaymentSessionResponse,
  EnsurePaymentMethodAddedRequest,
  UpdateBillingAddressRequest,
  BillingAddressResponse,
  PeriodUsage,
  Page,
  BillingInvoice,
  BillingInvoiceDetails,
  BillingInvoiceDetailsRequest,
  ESIIDSearchRequest,
  LocationDocuments,
  LocationRequest,
  PaymentMethodResponse,
  CancelServiceRequest,
  SupportInfo,
  UpdateIdentityRequest,
  UpdateAccount,
  RateGroup,
  AuthorizeVehiclesResponse,
  AuthorizeVehiclesRequest,
  Vehicle,
  DailyUsageDataResponse,
  DayUsageRequest,
  MonthlyUsageDataResponse,
  MonthUsageRequest,
  ComparisonInvoice,
} from "./lightTypes";
import { baseQuery } from "./lightTokenRefresh";
import { createContext, useContext } from "react";

export function createLightApi(baseQuery: BaseQueryFn) {
  return createApi({
    reducerPath: "lightApi",
    baseQuery,
    tagTypes: [
      "Account",
      "BillingAddress",
      "PaymentMethod",
      "BillingInvoices",
      "Vehicles",
    ],
    endpoints: (builder) => ({
      getAccount: builder.query<LightAccount, void>({
        query: () => "",
        providesTags: ["Account"],
      }),
      updateAccount: builder.mutation<LightAccount, UpdateAccount>({
        query: (body) => ({
          url: "",
          method: "PATCH",
          body,
        }),
        invalidatesTags: ["Account"],
      }),
      searchAddress: builder.mutation<AddressSearchResult[], string>({
        query: (query) => ({
          url: "enroll/address-search",
          method: "POST",
          body: { query },
        }),
      }),
      searchPreliminaryAddress: builder.query<AddressSearchResult[], void>({
        query: () => "enroll/preliminary-address-search",
      }),
      searchESIID: builder.mutation<AddressSearchResult, ESIIDSearchRequest>({
        query: (body) => ({
          url: "enroll/esi-id-search",
          method: "POST",
          body,
        }),
      }),
      requestPlans: builder.mutation<RateResponse[], RateRequest>({
        query: (body) => ({
          url: "enroll/plans/request",
          method: "POST",
          body,
        }),
      }),
      acceptPlan: builder.mutation<AcceptPlanResponse, AcceptPlanRequest>({
        query: (body) => ({
          url: "enroll/plans/accept",
          method: "POST",
          body,
        }),
        invalidatesTags: ["Account"],
      }),
      uploadInvoice: builder.mutation<void, File>({
        query: (file) => {
          const body = new FormData();
          // pdf is the only supported file type
          body.append("Content-Type", "application/pdf");
          body.append("file", file);

          return {
            url: "enroll/comparison-invoice",
            method: "POST",
            body,
          };
        },
      }),
      getComparisonInvoice: builder.query<ComparisonInvoice, void>({
        query: () => "enroll/comparison-invoice",
      }),
      createPaymentSession: builder.mutation<
        CreatePaymentSessionResponse,
        void
      >({
        query: (body) => ({
          url: "/billing/payment-session",
          method: "POST",
          body,
        }),
      }),
      ensurePaymentMethodAdded: builder.mutation<
        void,
        EnsurePaymentMethodAddedRequest
      >({
        query: (body) => ({
          url: "/billing/ensure-payment-method-added",
          method: "POST",
          body,
        }),
        invalidatesTags: ["Account", "PaymentMethod"],
      }),
      getPaymentMethod: builder.query<PaymentMethodResponse, void>({
        query: () => "/billing/payment-method",
        providesTags: ["PaymentMethod"],
      }),
      getBillingAddress: builder.query<BillingAddressResponse, void>({
        query: () => "/billing/address",
        providesTags: ["BillingAddress"],
      }),
      updateBillingAddress: builder.mutation<void, UpdateBillingAddressRequest>(
        {
          query: (body) => ({
            url: "/billing/address",
            method: "PUT",
            body,
          }),
          invalidatesTags: ["Account", "BillingAddress"],
        },
      ),
      getBillingInvoices: builder.query<Page<BillingInvoice>, void>({
        query: () => "billing/invoices",
        providesTags: ["BillingInvoices"],
      }),
      getBillingInvoice: builder.query<
        BillingInvoiceDetails,
        BillingInvoiceDetailsRequest
      >({
        query: ({ invoiceNumber }) =>
          `billing/invoices/${encodeURIComponent(invoiceNumber)}`,
      }),
      getLocationPlan: builder.query<RateResponse, LocationRequest>({
        query: ({ uuid }) => `locations/${encodeURIComponent(uuid)}/plan`,
      }),
      getLocationMeterReadUsage: builder.query<PeriodUsage[], LocationRequest>({
        query: ({ uuid }) =>
          `locations/${encodeURIComponent(uuid)}/usage/meter-reads`,
      }),
      getLocationDocuments: builder.query<LocationDocuments, LocationRequest>({
        query: ({ uuid }) => `locations/${encodeURIComponent(uuid)}/documents`,
      }),
      getAllLocationsDocuments: builder.query<
        Page<LocationDocuments>,
        LocationRequest
      >({
        query: ({ uuid }) =>
          `locations/${encodeURIComponent(uuid)}/all-documents`,
      }),
      cancelService: builder.mutation<void, CancelServiceRequest>({
        query: ({ location_uuid, ...body }) => ({
          url: `locations/${encodeURIComponent(location_uuid)}/cancel-service`,
          method: "POST",
          body,
        }),
        invalidatesTags: ["Account"],
      }),
      getSupportInfo: builder.query<SupportInfo, LocationRequest>({
        query: ({ uuid }) =>
          `locations/${encodeURIComponent(uuid)}/support-info`,
      }),
      updateIdentity: builder.mutation<void, UpdateIdentityRequest>({
        query: (body) => ({
          url: "enroll/identity",
          method: "PATCH",
          body,
        }),
        invalidatesTags: ["Account"],
      }),
      finalizeEnrollment: builder.mutation<void, void>({
        query: () => ({
          url: "enroll/finalize",
          method: "POST",
        }),
        invalidatesTags: ["Account"],
      }),
      rateGroups: builder.query<RateGroup[], void>({
        query: () => ({
          url: "enroll/plan-groups",
          method: "GET",
        }),
      }),
      getVehicles: builder.query<Page<Vehicle>, void>({
        query: () => ({
          url: "vehicles/",
          method: "GET",
        }),
        providesTags: ["Vehicles"],
      }),
      enrollVehicles: builder.mutation<Page<Vehicle>, string[]>({
        query: (body) => ({
          url: "vehicles/enroll",
          method: "POST",
          body,
        }),
        invalidatesTags: ["Vehicles"],
      }),
      authorizeVehicles: builder.mutation<
        AuthorizeVehiclesResponse,
        AuthorizeVehiclesRequest
      >({
        query: (body) => ({
          url: "vehicles/authorize",
          method: "POST",
          body,
        }),
      }),
      checkVehiclesAuthorized: builder.mutation<void, void>({
        query: () => ({
          url: "vehicles/check-authorized",
          method: "POST",
        }),
        invalidatesTags: ["Vehicles"],
      }),
      getDailyUsage: builder.query<DailyUsageDataResponse, DayUsageRequest>({
        query: ({ uuid, month, year }) => {
          const path = `locations/${encodeURIComponent(uuid)}/usage/daily`;
          if (!month && !year) {
            return path;
          }

          const searchParams: { month?: string; year?: string } = {};
          if (month) {
            searchParams.month = `${month}`;
          }

          if (year) {
            searchParams.year = `${year}`;
          }

          return `${path}?${new URLSearchParams(searchParams)}`;
        },
      }),
      getMonthlyUsage: builder.query<
        MonthlyUsageDataResponse,
        MonthUsageRequest
      >({
        query: ({ uuid, year }) => {
          const path = `locations/${encodeURIComponent(uuid)}/usage/monthly`;
          if (!year) {
            return path;
          }

          const searchParams: { year?: string } = {};
          if (year) {
            searchParams.year = `${year}`;
          }

          return `${path}?${new URLSearchParams(searchParams)}`;
        },
      }),
    }),
  });
}

export const light = createLightApi(baseQuery);

export const {
  useGetAccountQuery,
  useLazyGetAccountQuery,
  useSearchAddressMutation,
  useSearchPreliminaryAddressQuery,
  useSearchESIIDMutation,
  useRequestPlansMutation,
  useAcceptPlanMutation,
  useUploadInvoiceMutation,
  useGetComparisonInvoiceQuery,
  useCreatePaymentSessionMutation,
  useEnsurePaymentMethodAddedMutation,
  useGetPaymentMethodQuery,
  useGetBillingAddressQuery,
  useUpdateBillingAddressMutation,
  useGetBillingInvoicesQuery,
  useGetBillingInvoiceQuery,
  useGetLocationMeterReadUsageQuery,
  useGetLocationDocumentsQuery,
  useGetAllLocationsDocumentsQuery,
  useCancelServiceMutation,
} = light;

export const LightContext = createContext(light);
export function useLight() {
  return useContext(LightContext);
}
