import { useScaffold } from "@Light/scaffold";
import { createContext, useCallback, useContext, useMemo } from "react";
import { useAccount } from "@Light/components/page/account";
import { useEnroll } from "./context";
import { usePlan } from "@Light/utils/plan";

export type EnrollRouteState = {
  routes: string[];
  routeMap: Record<string, boolean>;
};

export const EnrollRouteContext = createContext<EnrollRouteState | undefined>(
  undefined,
);

export type WithEnrollRoutesProps = {
  children: React.ReactNode;
};

export const WithEnrollRoutes: React.FC<WithEnrollRoutesProps> = ({
  children,
}) => {
  const routes = enrollRoutes();
  const routeMap = useMemo(() => {
    return Object.fromEntries(routes.map((route) => [route, true]));
  }, [routes]);
  return (
    <EnrollRouteContext.Provider
      value={{
        routes,
        routeMap,
      }}
    >
      {children}
    </EnrollRouteContext.Provider>
  );
};

function enrollRoutes(): string[] {
  const scaffold = useScaffold();
  const account = useAccount();
  const { address, serviceEvent } = useEnroll();
  const plan = usePlan();
  const isIdentityVerified = Boolean(account.enrollment?.is_identity_verified);
  const enrollmentFinalized = Boolean(
    account.enrollment?.is_enrollment_finalized,
  );
  const isVehicleChargingPlan = Boolean(plan.features.vehicleCharging);
  if (enrollmentFinalized) {
    const { showVehicleAuthorization } = scaffold.enroll.steps;
    if (showVehicleAuthorization && isVehicleChargingPlan) {
      return ["/authorize-vehicles/*", "/confirmed"];
    }

    return ["/confirmed"];
  }

  const routes = [];
  const hasOffer = Boolean(scaffold.enroll.steps.offer);
  if (hasOffer) {
    routes.push("/offer");
  }

  const hasWelcome = Boolean(scaffold.enroll.steps.welcome);
  if (hasWelcome) {
    routes.push("/welcome");
  }

  const hasUploadInvoice = Boolean(scaffold.enroll.steps.uploadInvoice);
  if (hasUploadInvoice) {
    routes.push("/upload-invoice");
  }

  routes.push("/service-address/*");

  const hasAcceptedPlan = account.enrollment?.has_accepted_plan;
  if (hasAcceptedPlan || (address.val && serviceEvent.val)) {
    routes.push("/rate");
  }

  if (hasAcceptedPlan) {
    routes.push("/verify-identity");

    if (isIdentityVerified) {
      routes.push("/setup-payment");
    }

    const hasPaymentMethod = Boolean(account.enrollment?.has_payment_method);
    if (hasPaymentMethod) {
      routes.push("/confirm-and-enroll");
    }
  }

  return routes;
}

export function useEnrollRoutes() {
  const state = useContext(EnrollRouteContext);
  if (!state) {
    throw new Error(
      "useEnrollRoutes must be used as part of an EnrollRouteContext",
    );
  }

  const { routes, routeMap } = state;

  const getRoute = useCallback((path: string) => {
    // Remove starting "/" and trailing "*" characters
    path = path.replace(/^\/+/, "").replace(/\*$/, "");
    return `/enroll/${path}`;
  }, []);

  const hasRoute = useCallback(
    (path: string) => {
      return routeMap[path] ?? false;
    },
    [routeMap],
  );

  const nextRoute = useCallback(
    (path: string) => {
      const index = routes.indexOf(path);
      if (index < 0) {
        return undefined;
      }

      const nextRoute = routes[index + 1];
      if (!nextRoute) {
        return undefined;
      }

      return getRoute(nextRoute);
    },
    [routes, getRoute],
  );

  return {
    routes,
    getRoute,
    hasRoute,
    nextRoute,
  };
}
