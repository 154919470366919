import ReactDOM from "react-dom/client";
import store from "./app/store";
import "./main.css";
import { Provider } from "react-redux";
import { StripeProvider } from "@Light/app/stripe";
import { AppRoutes } from "@/components/AppRoutes";
import { BrowserRouter } from "react-router-dom";
import { AnalyticsProvider } from "@Light/app/analytics";
import { initSentry, SentryAddContext } from "@Light/app/sentry";

initSentry("standalone");

ReactDOM.createRoot(document.getElementById("root")!).render(
  <Provider store={store}>
    <StripeProvider>
      <BrowserRouter>
        <AnalyticsProvider useLocalStorage>
          <AppRoutes />
          <SentryAddContext />
        </AnalyticsProvider>
      </BrowserRouter>
    </StripeProvider>
  </Provider>,
);
