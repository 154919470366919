import { Scaffold } from "@Light/scaffold";
import { createContext, useContext } from "react";
import {
  SetURLSearchParams,
  To,
  useNavigate as useNavigateOriginal,
  useSearchParams as useSearchParamsOriginal,
  useParams as useParamsOriginal,
} from "react-router-dom";
import { useScaffold as useScaffoldOriginal } from "@Light/scaffold";
import { useDispatch as useDispatchOriginal } from "react-redux";

export type MockContextState = {
  scaffold?: Scaffold;
  navigate?: (path: To) => void;
  searchParams?: [URLSearchParams, SetURLSearchParams];
  params?: Record<string, string>;
  dispatch?: (action: any) => void;
};

export const MockContext = createContext<MockContextState | undefined>(
  undefined,
);

export function useScaffold() {
  return useContext(MockContext)?.scaffold ?? useScaffoldOriginal();
}

export function useNavigate() {
  return useContext(MockContext)?.navigate ?? useNavigateOriginal();
}

export function useSearchParams() {
  return useContext(MockContext)?.searchParams ?? useSearchParamsOriginal();
}

export function useParams() {
  return useContext(MockContext)?.params ?? useParamsOriginal();
}

export function useDispatch() {
  return useContext(MockContext)?.dispatch ?? useDispatchOriginal();
}
