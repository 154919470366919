import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export type AuthState = {
  token: string | null;
  expiresAt: Date | null;
};

const initialState: AuthState = {
  token: null,
  expiresAt: null,
};

export type TokenReceived = {
  token: string;
  expiresAt: string;
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    tokenReceived(state, action: PayloadAction<TokenReceived>) {
      state.token = action.payload.token;
      state.expiresAt = new Date(action.payload.expiresAt);
    },
    loggedOut(state) {
      state.token = null;
      state.expiresAt = null;
    },
  },
});

export const { tokenReceived, loggedOut } = authSlice.actions;

export default authSlice.reducer;
