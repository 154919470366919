import { ShopPlan } from "@/services/apiTypes";
import { useScaffold } from "@Light/scaffold";
import clsx from "clsx";

export type StorageCreditProps = {
  plan: ShopPlan;
};

export const StorageCredit: React.FC<StorageCreditProps> = ({ plan }) => {
  const scaffold = useScaffold();
  const { storage_monthly_credit_dollars } = plan;
  if (parseFloat(storage_monthly_credit_dollars) === 0) {
    return null;
  }
  return (
    <div className="flex flex-col gap-1">
      <div className="flex flex-col gap-2">
        <div className={clsx(scaffold.page.colors.text.primary)}>
          <span className={clsx("text-2xl font-medium")}>
            $
            {Intl.NumberFormat("en-US", { maximumFractionDigits: 2 }).format(
              parseFloat(storage_monthly_credit_dollars),
            )}
          </span>
          <span className="font-medium">/mo battery storage credit</span>
        </div>
      </div>
      <div className={clsx("text-sm", scaffold.page.colors.text.secondary)}>
        Your reward for helping to balance the grid
      </div>
    </div>
  );
};
