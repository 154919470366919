import { useScaffold } from "@Light/scaffold";
import { clsx } from "clsx";

export type CardProps = {
  label: string;
  children: React.ReactNode;
};

export const Card: React.FC<CardProps> = ({ label, children }) => {
  const scaffold = useScaffold();
  return (
    <div
      className={clsx(
        "border rounded-lg px-4 py-6 w-full",
        scaffold.page.colors.border.plain,
      )}
    >
      <div className="flex flex-col gap-2">
        <div className={clsx("text-xs", scaffold.page.colors.text.tertiary)}>
          {label}
        </div>
        {children}
      </div>
    </div>
  );
};
