import { useScaffold } from "@Light/scaffold";
import { usePlan } from "@Light/utils/plan";
import clsx from "clsx";

export type IntervalLegendProps = {};

export const IntervalLegend: React.FC<IntervalLegendProps> = ({}) => {
  const plan = usePlan();
  const hasSolarBuyback = Boolean(plan.features.solarBuyback);
  const hasVehicleCharging = Boolean(plan.features.vehicleCharging);
  if (!hasSolarBuyback && !hasVehicleCharging) {
    return null;
  }
  return (
    <div className="flex justify-center">
      <div className="flex flex-row gap-6">
        <Item label={hasSolarBuyback ? "Import" : "Home"} color="#8ED1D9" />
        {hasSolarBuyback && (
          <>
            <Item label="Export" color="rgb(253 224 71)" />
          </>
        )}
        {hasVehicleCharging && (
          <>
            <Item label="Unlimited vehicle charging" color="#1570EF" />
            <Item label="Other charging" color="#C07A79" />
          </>
        )}
      </div>
    </div>
  );
};

export type ItemProps = {
  label: string;
  color: string;
};

export const Item: React.FC<ItemProps> = ({ label, color }) => {
  const scaffold = useScaffold();
  return (
    <div className="flex flex-row gap-2 items-center">
      <div className="w-2 h-2" style={{ background: color }} />
      <div className={clsx("text-2xs", scaffold.page.colors.text.tertiary)}>
        {label}
      </div>
    </div>
  );
};
