import clsx from "clsx";
import React from "react";

export type HyperlinkProps = React.ComponentProps<"a"> & {};

export const Hyperlink: React.FC<HyperlinkProps> = React.forwardRef<
  HTMLAnchorElement,
  HyperlinkProps
>(({ className, ...props }, ref) => {
  return (
    <a
      ref={ref}
      className={clsx("underline", className)}
      rel="noopener noreferrer"
      {...props}
    />
  );
});
