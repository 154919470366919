import { useScaffold } from "@Light/scaffold";
import { useHeader } from "../page/header/context";
import { useLightSandbox } from "@Light/services/lightSandbox";
import { useMutation } from "@Light/utils/mutation";
import { useEffect } from "react";
import { light } from "@Light/services/light";
import { useDispatch } from "react-redux";

export type SandboxProps = {};

export const Sandbox: React.FC<SandboxProps> = ({}) => {
  const scaffold = useScaffold();
  const { setTitle, setBackNavigate } = useHeader();
  const {
    useSeedDemoDataMutation,
    useDeleteDemoDataMutation,
    useDeleteEnrollmentMutation,
  } = useLightSandbox();
  const dispatch = useDispatch();

  setTitle("Sandbox **DEMO ONLY**");
  setBackNavigate("/");

  const seedDemoData = useMutation(useSeedDemoDataMutation);
  const deleteDemoData = useMutation(useDeleteDemoDataMutation);
  const deleteEnrollment = useMutation(useDeleteEnrollmentMutation);

  useEffect(() => {
    if (
      !seedDemoData.isSuccess &&
      !deleteDemoData.isSuccess &&
      !deleteEnrollment.isSuccess
    ) {
      return;
    }
    // Tell redux to invalidate our account data as all of these mutations can
    dispatch(light.util.invalidateTags(["Account", "BillingInvoices"]));
  }, [
    seedDemoData.isSuccess,
    deleteDemoData.isSuccess,
    deleteEnrollment.isSuccess,
  ]);

  const PageBody = scaffold.page.pageBody;
  const MutationButton = scaffold.page.mutationButton;
  return (
    <PageBody>
      <div className="pt-8 flex flex-col gap-4">
        <MutationButton
          mutation={seedDemoData}
          mutateButtonText="Seed Demo Data"
          mutate={seedDemoData.mutate}
          errorMessage="Failed seeding demo data."
          buttonStyle="error"
        />
        <MutationButton
          mutation={deleteDemoData}
          mutateButtonText="Delete Demo Data"
          mutate={deleteDemoData.mutate}
          errorMessage="Failed deleting demo data."
          buttonStyle="error"
        />
        <MutationButton
          mutation={deleteEnrollment}
          mutateButtonText="Delete Enrollment"
          mutate={deleteEnrollment.mutate}
          errorMessage="Failed deleting enrollment."
          buttonStyle="error"
        />
      </div>
    </PageBody>
  );
};
