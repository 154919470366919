import { useScaffold } from "@Light/scaffold";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import { Tooltip } from "react-tooltip";

export type ESIIDFieldProps = {
  children: React.ReactNode;
};

export const ESIIDField: React.FC<ESIIDFieldProps> = ({ children }) => {
  const scaffold = useScaffold();
  const DetailField = scaffold.page.detail.detailField;
  return (
    <DetailField
      label={
        <div className="flex flex-row gap-0.5">
          <div>ESI ID</div>
          <ESIIDTooltip>
            <QuestionMarkCircleIcon className="w-5 h-5" />
          </ESIIDTooltip>
        </div>
      }
    >
      <div id="account-esi-id">{children}</div>
    </DetailField>
  );
};

export type ESIIDTooltipProps = React.ComponentProps<"div"> & {
  children: React.ReactNode;
};

export const ESIIDTooltip: React.FC<ESIIDTooltipProps> = ({
  children,
  ...props
}) => {
  return (
    <>
      <div data-tooltip-id="esi-id-tooltip" {...props}>
        {children}
      </div>
      <Tooltip id="esi-id-tooltip" place="top-start">
        <div className="w-72 text-xs cursor-pointer">
          ESI IDs are unique service identification numbers assigned to every
          electric meter on a home or business in the state of Texas.
        </div>
      </Tooltip>
    </>
  );
};
