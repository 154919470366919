import { useEffect, useRef } from "react";
import { useState } from "./state";

export function useHover<E extends HTMLElement = HTMLElement>(): [
  React.RefObject<E>,
  boolean,
] {
  const ref = useRef<E>(null);
  const isHovered = useState(false);

  useEffect(() => {
    const handleMouseMove = (event: any) => {
      if (!ref.current) {
        return;
      }
      const rect = ref.current.getBoundingClientRect();
      const cursorX = event.clientX;
      const cursorY = event.clientY;
      if (
        cursorX >= rect.left &&
        cursorX <= rect.right &&
        cursorY >= rect.top &&
        cursorY <= rect.bottom
      ) {
        isHovered.setVal(true);
      } else {
        isHovered.setVal(false);
      }
    };
    document.addEventListener("mousemove", handleMouseMove);
    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, [ref.current, isHovered.setVal]);

  return [ref, isHovered.val];
}
