import { BillingInvoice } from "@Light/services/lightTypes";
import dayjs from "dayjs";
import { useMemo } from "react";
import { useState } from "@Light/utils/state";

export function usePaymentDays(invoice: BillingInvoice | undefined) {
  // We do not want this "now" to change over the life of this component.
  const now = useState(dayjs()).val;
  return useMemo(
    () => (invoice ? dayjs(invoice.payment_due_date).diff(now, "days") : 0),
    [invoice?.payment_due_date, now],
  );
}

export function useIsPastDue(invoice: BillingInvoice | undefined) {
  const paymentDays = usePaymentDays(invoice);
  return invoice && paymentDays < 0 && !invoice.paid_at;
}
