import { useCallback, useEffect } from "react";
import {
  BillingAddressResponse,
  UpdateBillingAddressRequest,
} from "@Light/services/lightTypes";
import { useLight } from "@Light/services/light";
import { useScaffold } from "@Light/scaffold";
import { Mutation, useMutation } from "@Light/utils/mutation";
import { FieldValues, UseFormRegister, useForm } from "react-hook-form";
import { clsx } from "clsx";
import { useLocation } from "@Light/components/page/account";

export type UpdateBillingAddressForm = {
  onSubmit: () => void;
  disabled: boolean;
  mutation: Mutation<UpdateBillingAddressRequest>;
  form: React.ReactNode;
};

export function useUpdateBillingAddressForm(): UpdateBillingAddressForm {
  const { useGetBillingAddressQuery, useUpdateBillingAddressMutation } =
    useLight();
  const billingAddress = useGetBillingAddressQuery();
  const mutation = useMutation<UpdateBillingAddressRequest>(
    useUpdateBillingAddressMutation,
  );
  const { register, handleSubmit, watch, setValue } = useForm();
  const { sameAsServiceAddress, address1, address2, city, state, postalCode } =
    watch();
  const disabled =
    !billingAddress.data ||
    (sameAsServiceAddress && billingAddress.data.same_as_service_address) ||
    (!sameAsServiceAddress &&
      address1 === billingAddress.data.address_1 &&
      address2 === billingAddress.data.address_2 &&
      city === billingAddress.data.city &&
      state === billingAddress.data.state &&
      postalCode === billingAddress.data.postal_code);

  useEffect(() => {
    setValue(
      "sameAsServiceAddress",
      billingAddress.data?.same_as_service_address ?? true,
    );
  }, [setValue, billingAddress.data?.same_as_service_address]);

  const onSubmit = useCallback(
    handleSubmit(
      ({
        sameAsServiceAddress,
        address1,
        address2,
        city,
        state,
        postalCode,
      }: any) => {
        mutation.mutate({
          same_as_service_address: sameAsServiceAddress,
          address: sameAsServiceAddress
            ? undefined
            : {
                address_1: address1,
                address_2: address2,
                city: city,
                state: state,
                postal_code: postalCode,
              },
        });
      },
    ),
    [handleSubmit, mutation.mutate],
  );

  if (billingAddress.isLoading) {
    return {
      onSubmit,
      disabled,
      mutation,
      form: null,
    };
  }

  const form = (
    <UpdateBillingAddress
      billingAddress={billingAddress.data}
      register={register}
      sameAsServiceAddress={sameAsServiceAddress}
    />
  );

  return {
    onSubmit,
    disabled,
    mutation,
    form,
  };
}

export type UpdateBillingAddressProps = {
  billingAddress?: BillingAddressResponse;
  register: UseFormRegister<FieldValues>;
  sameAsServiceAddress: boolean;
};

export const UpdateBillingAddress: React.FC<UpdateBillingAddressProps> = ({
  billingAddress,
  register,
  sameAsServiceAddress,
}) => {
  const scaffold = useScaffold();
  const location = useLocation();

  const DetailField = scaffold.page.detail.detailField;
  const DetailLabel = scaffold.page.detail.detailLabel;
  const Field = scaffold.system.field;
  const Label = scaffold.system.label;
  const Input = scaffold.system.input;
  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row items-center gap-3">
        <div>
          <Input type="checkbox" {...register("sameAsServiceAddress")} />
        </div>
        <div className={clsx("text-sm", scaffold.page.colors.text.secondary)}>
          Use my service address as my billing address
        </div>
      </div>
      {sameAsServiceAddress && (
        <DetailField label="Billing address">
          <div className="flex flex-col">
            <div>{location.address_1}</div>
            {location.address_2 !== "" && <div>{location.address_2}</div>}
            <span>
              {location.city}, {location.state} {location.postal_code}
            </span>
          </div>
        </DetailField>
      )}
      {!sameAsServiceAddress && (
        <div className="flex flex-col gap-2">
          <DetailLabel>Billing address</DetailLabel>
          <Field>
            <Label>Address</Label>
            <Input
              type="text"
              {...register("address1", {
                required: true,
                value: billingAddress?.address_1,
              })}
              disabled={sameAsServiceAddress}
            />
          </Field>
          <Field>
            <Label>Address 2</Label>
            <Input
              type="text"
              {...register("address2", {
                value: billingAddress?.address_2,
              })}
              disabled={sameAsServiceAddress}
            />
          </Field>
          <div className="flex flex-row">
            <div className="w-2/3">
              <Field>
                <Label>City</Label>
                <Input
                  type="text"
                  {...register("city", {
                    required: true,
                    value: billingAddress?.city,
                  })}
                  disabled={sameAsServiceAddress}
                />
              </Field>
            </div>
            <div className="w-1/3 ml-4">
              <Field>
                <Label>State</Label>
                <Input
                  type="text"
                  {...register("state", {
                    required: true,
                    pattern: /^[a-zA-Z]{2}$/,
                    value: billingAddress?.state,
                  })}
                  disabled={sameAsServiceAddress}
                />
              </Field>
            </div>
          </div>
          <div className="w-1/2 mt-2">
            <Field>
              <Label>Zip</Label>
              <Input
                type="text"
                {...register("postalCode", {
                  required: true,
                  pattern: /^\d{5}$/,
                  value: billingAddress?.postal_code,
                })}
                disabled={sameAsServiceAddress}
              />
            </Field>
          </div>
        </div>
      )}
    </div>
  );
};
