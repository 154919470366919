import { useScaffold } from "@Light/scaffold";
import clsx from "clsx";

export type ItemProps = {
  icon: React.ReactNode;
  title: string;
  children: React.ReactNode;
};

export const Item: React.FC<ItemProps> = ({ icon, title, children }) => {
  const scaffold = useScaffold();
  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-row">
        <div className="border-2 rounded-lg p-2">{icon}</div>
      </div>
      <div className="flex flex-col">
        <div
          className={clsx(
            "font-medium text-lg",
            scaffold.page.colors.text.primary,
          )}
        >
          {title}
        </div>
        <div className={clsx(scaffold.page.colors.text.secondary)}>
          {children}
        </div>
      </div>
    </div>
  );
};
