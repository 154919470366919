import { useAPI } from "@/services/api";
import { SignupRequest } from "@/services/apiTypes";
import { useAnalytics } from "@Light/app/analytics";
import { PageBody } from "@Light/components/page/PageBody";
import { useScaffold } from "@Light/scaffold";
import { useMutation } from "@Light/utils/mutation";
import clsx from "clsx";
import { useCallback } from "react";
import { Navigate, useSearchParams } from "react-router-dom";

export type EmailSentProps = {};

export const EmailSent: React.FC<EmailSentProps> = ({}) => {
  const scaffold = useScaffold();
  const [searchParams] = useSearchParams();
  const { planUUID, attribution } = useAnalytics();
  const email = searchParams.get("email") ?? "";
  const { useSignupMutation } = useAPI();
  const signup = useMutation<SignupRequest, void>(useSignupMutation);
  const resend = useCallback(() => {
    signup.mutate({ email, plan: planUUID, attribution });
  }, [signup.mutate]);
  if (email === "") {
    return <Navigate to="/signup" />;
  }
  return (
    <PageBody
      title="Check your inbox"
      subtitle={
        <div className="flex flex-col gap-2">
          <div>
            <span className={clsx(scaffold.page.colors.text.secondary)}>
              Please follow the link sent to
            </span>{" "}
            <span
              className={clsx("font-medium", scaffold.page.colors.text.primary)}
            >
              {email}
            </span>{" "}
            <span>to continue.</span>
          </div>
          <div>
            <span>You can also click</span>{" "}
            <span
              onClick={resend}
              className={clsx(
                "font-medium cursor-pointer",
                scaffold.page.colors.text.primary,
              )}
            >
              here to resend
            </span>
            <span>.</span>
          </div>
        </div>
      }
    />
  );
};
