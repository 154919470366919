import { useScaffold } from "@Light/scaffold";
import { useLight } from "@Light/services/light";
import React from "react";
import { QueryLoader } from "@Light/components/app/QueryLoader";
import { useLocation } from "@Light/components/page/account";
import { SupportInfo } from "@Light/services/lightTypes";
import clsx from "clsx";
import { ServiceLink } from "./ServiceLink";
import { useHeader } from "@Light/components/page/header/context";

export type ContactUsProps = {};

export const ContactUs: React.FC<ContactUsProps> = ({}) => {
  const { useGetSupportInfoQuery } = useLight();
  const { uuid } = useLocation();
  const supportInfo = useGetSupportInfoQuery({ uuid });
  const { setTitle, setBackNavigate } = useHeader();

  setTitle("Contact us");
  setBackNavigate("/support/");

  const scaffold = useScaffold();
  const PageBody = scaffold.page.pageBody;
  return (
    <PageBody>
      <QueryLoader
        queries={[supportInfo]}
        errorMessage="Failed loading support info. Please try again later."
      >
        <ContactUsB1 supportInfo={supportInfo.data as SupportInfo} />
      </QueryLoader>
    </PageBody>
  );
};

export type ContactUsB1Props = ContactUsProps & {
  supportInfo: SupportInfo;
};

export const ContactUsB1: React.FC<ContactUsB1Props> = ({ supportInfo }) => {
  const scaffold = useScaffold();
  const Divided = scaffold.page.divided;
  const InfoItem = scaffold.page.infoItem;
  return (
    <Divided className="pt-8">
      <div
        className={clsx(
          "p-2 border-2 rounded-xl",
          scaffold.page.colors.border.info,
          scaffold.page.colors.background.info,
        )}
      >
        <div className="flex flex-col gap-4">
          <InfoItem title="Outages and service requests">
            For outages, emergencies, or service requests, please contact your
            transmission and distribution utility directly
          </InfoItem>
          <div className="text-xs font-semibold">{supportInfo.tdu_name}</div>
          <ServiceLink label="Phone" href={`tel:${supportInfo.tdu_phone}`}>
            {supportInfo.tdu_phone}
          </ServiceLink>
        </div>
      </div>
      <div className="flex flex-col gap-8">
        <InfoItem title="Billing and general inquiries">
          For questions related to your bill, your contract, or for other
          non-outage-related questions, contact us by email or phone
        </InfoItem>
        <div className="flex flex-col gap-4">
          <div className="text-xs font-semibold">{supportInfo.tdu_name}</div>
          <ServiceLink
            label="Email"
            href={`mailto:${scaffold.page.supportEmail}`}
          >
            {scaffold.page.supportEmail}
          </ServiceLink>
          <ServiceLink
            label={
              scaffold.page.supportHours
                ? `Phone: ${scaffold.page.supportHours}`
                : "Phone"
            }
            href={`tel:${scaffold.page.supportPhone}`}
          >
            {scaffold.page.supportPhone}
          </ServiceLink>
        </div>
      </div>
    </Divided>
  );
};
