import { useScaffold } from "@Light/scaffold";
import { BilllingInvoiceChargeGroup } from "@Light/services/lightTypes";
import clsx from "clsx";
import { useState } from "react";
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/24/solid";
import { Charge } from "./Charge";
import { DollarAmount } from "./DollarAmount";

export type ChargeGroupProps = {
  chargeGroup: BilllingInvoiceChargeGroup;
};

export const ChargeGroup: React.FC<ChargeGroupProps> = ({ chargeGroup }) => {
  const scaffold = useScaffold();
  const [expanded, setExpanded] = useState(false);
  return (
    <div className="flex flex-col gap-2 w-full">
      <div
        className="flex flex-row justify-between items-center w-full cursor-pointer"
        onClick={() => setExpanded(!expanded)}
      >
        <div className={clsx("font-medium", scaffold.page.colors.text.primary)}>
          {chargeGroup.name}
        </div>
        <div className="flex flex-row gap-2 items-center">
          <DollarAmount
            className={clsx("font-medium", scaffold.page.colors.text.primary)}
            amount={chargeGroup.total}
          />
          {expanded ? (
            <ChevronUpIcon className="w-4 h-4" />
          ) : (
            <ChevronDownIcon className="w-4 h-4" />
          )}
        </div>
      </div>
      {expanded ? (
        <div className="flex flex-col gap-2 w-full">
          {chargeGroup.charges.map((charge) => (
            <Charge key={charge.type} charge={charge} />
          ))}
        </div>
      ) : null}
    </div>
  );
};
