import { FetchBaseQueryError } from "@reduxjs/toolkit/query";

export type BaseMutation<T> = {
  data?: T;
  error?: FetchBaseQueryError;
  isUninitialized: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
};

export type Mutation<Q = void, T = void> = BaseMutation<T> & {
  mutate: (query: Q) => void;
};

export function useMutation<Q = void, T = void>(mutation: any): Mutation<Q, T> {
  const [mutate, baseMutation] = mutation();
  return {
    ...baseMutation,
    mutate,
  };
}

export function errorForField<Q = void, T = void>(
  mutation: Mutation<Q, T>,
  fieldName: string,
): string | undefined {
  if (!mutation.error) {
    return undefined;
  }

  const { error } = mutation;
  if (typeof error.status !== "number") {
    return undefined;
  }

  const data = error.data as any;
  const validationData = data?.validation_data ?? data?.detail;
  if (!Array.isArray(validationData)) {
    return undefined;
  }

  for (const item of validationData) {
    for (const loc of item?.loc ?? []) {
      if (loc === fieldName) {
        return item.msg;
      }
    }
  }

  return undefined;
}
