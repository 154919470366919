import React, { createContext, useCallback, useContext } from "react";
import { Popover } from "@headlessui/react";
import { State, useState } from "@Light/utils/state";
import { AnimatePresence, motion } from "framer-motion";
import clsx from "clsx";
import { useHover } from "@Light/utils/hover";

export type PopoverState = {
  open: State<boolean>;
};

export const PopoverContext = createContext<PopoverState | undefined>(
  undefined,
);

export function usePopover() {
  const popover = useContext(PopoverContext);
  if (!popover) {
    throw new Error("WithPopover context must be used with usePopover");
  }

  const open = useCallback(() => {
    popover.open.setVal(true);
  }, [popover.open.setVal]);

  const close = useCallback(() => {
    popover.open.setVal(false);
  }, [popover.open.setVal]);

  return { open, close };
}

export type Style = "center" | "top";

export type WithPopoverProps = {
  children: React.FC<ReturnType<typeof usePopover>>;
  popover: React.ReactNode;
  style?: Style;
  className?: string;
};

export const WithPopover: React.FC<WithPopoverProps> = ({
  popover,
  children,
  style,
  className,
}) => {
  const open = useState<boolean>(false);
  const state = { open };
  const [ref, isPopoverHovered] = useHover<HTMLDivElement>();

  style ??= "center";
  const outerStyleClass = (() => {
    if (style === "center") {
      return "flex flex-col justify-center items-center";
    }

    if (style === "top") {
      return "flex flex-col";
    }

    return "";
  })();

  const innerStyleClass = (() => {
    if (style === "center") {
      return "w-[90%] rounded-xl";
    }

    return "";
  })();

  const onClickBackground = useCallback(() => {
    if (isPopoverHovered) {
      return;
    }

    open.setVal(false);
  }, [isPopoverHovered, open.setVal]);

  return (
    <Popover className={className}>
      {() => (
        <PopoverContext.Provider value={state}>
          <PopoverChildren>{children}</PopoverChildren>
          <AnimatePresence initial={false}>
            {open.val && (
              <>
                <Popover.Overlay
                  static
                  as={motion.div}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className="fixed inset-0 z-10"
                />
                <Popover.Panel
                  static
                  as={motion.div}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{
                    opacity: 0,
                    transition: { duration: 0.2 },
                  }}
                  className={clsx(
                    "absolute inset-0 top-0 z-10 bg-gray-300/60 backdrop-blur h-full",
                    outerStyleClass,
                  )}
                  onClick={onClickBackground}
                >
                  <div
                    ref={ref}
                    className={clsx(
                      "flex flex-col justify-center flex-center",
                      "rounded-xl bg-gray-50 shadow-2xl shadow-gray-900/20",
                      innerStyleClass,
                    )}
                  >
                    {popover}
                  </div>
                </Popover.Panel>
              </>
            )}
          </AnimatePresence>
        </PopoverContext.Provider>
      )}
    </Popover>
  );
};

export type PopoverChildrenProps = {
  children: React.FC<ReturnType<typeof usePopover>>;
};

export const PopoverChildren: React.FC<PopoverChildrenProps> = ({
  children,
}) => {
  const popover = usePopover();
  const Children = children;
  return <Children {...popover} />;
};
