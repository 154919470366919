import { useScaffold } from "@Light/scaffold";
import { BaseMenuProps } from "./BaseMenu";
import { useAccount } from "../../account";

export type PendingServiceMenuProps = BaseMenuProps;

export const PendingServiceMenu: React.FC<PendingServiceMenuProps> = (
  props,
) => {
  const account = useAccount();
  const scaffold = useScaffold();
  const Divided = scaffold.page.divided;
  const BaseMenu = scaffold.page.header.menu.baseMenu;
  const MenuItem = scaffold.page.header.menu.menuItem;
  return (
    <BaseMenu {...props}>
      <Divided>
        <div className="flex flex-col gap-6">
          <MenuItem title="FAQ" route="/enroll/faq" />
          <MenuItem title="Documents" route="/account/documents/" />
        </div>

        {account.app.is_sandbox ? (
          <MenuItem title="Sandbox **DEMO ONLY**" route="/sandbox" />
        ) : null}
      </Divided>
    </BaseMenu>
  );
};
