import { useLocation } from "@Light/components/page/account";
import { useLight } from "@Light/services/light";
import { createContext, useContext } from "react";
import { useScaffold } from "./context";
import {
  useRateGroup,
  planFeaturesForRateGroup,
  PlanFeatures,
} from "./rate_group";
import { RateGroup, RateResponse } from "@Light/services/lightTypes";

export type Plan = {
  plan: RateGroup;
  features: PlanFeatures;
};

export const PlanContext = createContext<Plan | undefined>(undefined);

export type WithLocationPlanProps = {
  children?: React.ReactNode;
};

export const WithLocationPlan: React.FC<WithLocationPlanProps> = ({
  children,
}) => {
  const scaffold = useScaffold();
  const location = useLocation();
  const { useGetLocationPlanQuery } = useLight();
  const rate = useGetLocationPlanQuery({ uuid: location.uuid });
  const plan = useRateGroup(rate.data?.group_uuid);

  if (rate.isError) {
    const Field = scaffold.system.field;
    const ErrorMessage = scaffold.system.errorMessage;
    return (
      <Field>
        <ErrorMessage>
          Failed querying rate data. Please try again later
        </ErrorMessage>
      </Field>
    );
  }

  if (!rate.isSuccess || !plan) {
    return null;
  }

  const features = planFeaturesForRateGroup(plan);
  return (
    <PlanContext.Provider value={{ plan, features }}>
      {children}
    </PlanContext.Provider>
  );
};

export type WithPlanProps = {
  planUUID?: string;
  children?: React.ReactNode;
};

export const WithPlan: React.FC<WithPlanProps> = ({ planUUID, children }) => {
  const plan = useRateGroup(planUUID);
  if (!plan) {
    return null;
  }

  const features = planFeaturesForRateGroup(plan);
  return (
    <PlanContext.Provider value={{ plan, features }}>
      {children}
    </PlanContext.Provider>
  );
};

export function usePlan(): Plan {
  const plan = useContext(PlanContext);
  if (!plan) {
    throw new Error("could not find plan when calling usePlan");
  }

  return plan;
}

export const RateContext = createContext<RateResponse | undefined>(undefined);

export function useRate(): RateResponse {
  const rate = useContext(RateContext);
  if (!rate) {
    throw new Error("could not find rate when calling useRate");
  }

  return rate;
}
