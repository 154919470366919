import React from "react";
import clsx from "clsx";

export type LinkListProps = React.ComponentProps<"div">;
export const LinkList: React.FC<LinkListProps> = React.forwardRef<
  HTMLDivElement,
  LinkListProps
>(({ className, ...props }, ref) => {
  return (
    <div
      {...props}
      ref={ref}
      className={clsx(
        "w-full flex flex-col rounded rounded-lg border",
        className,
      )}
    />
  );
});
