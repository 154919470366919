import { VehicleField } from "@Light/components/page/detail/VehicleField";
import { InputProps } from "@Light/components/system/Input";
import { useScaffold } from "@Light/scaffold";
import { useLight } from "@Light/services/light";
import { Page, Vehicle } from "@Light/services/lightTypes";
import { useMutation } from "@Light/utils/mutation";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import React, { useCallback } from "react";
import { FieldValues, UseFormRegister, useForm } from "react-hook-form";
import { Navigate } from "react-router-dom";

export type PickVehiclesProps = {
  vehicles: Vehicle[];
};

export const PickVehicles: React.FC<PickVehiclesProps> = ({ ...props }) => {
  const { useEnrollVehiclesMutation } = useLight();
  const enrollVehicles = useMutation<string[], Page<Vehicle>>(
    useEnrollVehiclesMutation,
  );
  const { register, handleSubmit } = useForm();

  const mutate = useCallback(
    handleSubmit((inputs) => {
      const body = [];
      for (const [vehicle_id, shouldEnroll] of Object.entries(inputs)) {
        if (shouldEnroll) {
          body.push(vehicle_id);
        }
      }
      enrollVehicles.mutate(body);
    }),
    [handleSubmit],
  );

  if (enrollVehicles.isSuccess) {
    return <Navigate to="/enroll/confirmed" />;
  }

  const scaffold = useScaffold();
  const PageBody = scaffold.page.pageBody;
  const MutationButton = scaffold.page.mutationButton;
  return (
    <PageBody
      title="Pick vehicles to enroll"
      footer={
        <MutationButton
          mutation={enrollVehicles}
          mutate={mutate}
          mutateButtonText="Enroll"
          errorMessage="Failed to enroll vehicles. Please try again later."
        />
      }
    >
      <div className="flex flex-col gap-8">
        <PickVehiclesList {...props} register={register} />
        <AuthorizeOther />
      </div>
    </PageBody>
  );
};

export type PickVehiclesListProps = PickVehiclesProps & {
  register: UseFormRegister<FieldValues>;
};

export const PickVehiclesList: React.FC<PickVehiclesListProps> = ({
  vehicles,
  register,
}) => {
  const scaffold = useScaffold();

  if (vehicles.length == 0) {
    return (
      <div
        className={clsx(
          "border-2 rounded-lg p-4",
          scaffold.page.colors.border.plain,
          scaffold.page.colors.text.primary,
        )}
      >
        <div className="flex justify-center">No vehicles found.</div>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-4 px-4">
      {vehicles.map((vehicle) => (
        <VehicleInput
          key={vehicle.vehicle_id}
          vehicle={vehicle}
          register={register}
        />
      ))}
    </div>
  );
};

export type VehicleInputProps = InputProps & {
  vehicle: Vehicle;
  register: UseFormRegister<FieldValues>;
};

export const VehicleInput: React.FC<VehicleInputProps> = ({
  vehicle,
  register,
  ...props
}) => {
  const scaffold = useScaffold();
  const Input = scaffold.system.input;
  return (
    <div className="flex flex-row items-center gap-6">
      <Input type="checkbox" {...register(vehicle.vehicle_id)} {...props} />
      <VehicleField vehicle={vehicle} />
    </div>
  );
};

export type AuthorizeOtherProps = {};

export const AuthorizeOther: React.FC<AuthorizeOtherProps> = ({}) => {
  const scaffold = useScaffold();
  const Link = scaffold.system.link;
  return (
    <Link
      to="/enroll/authorize-vehicles/authorize"
      className={clsx("text-sm", scaffold.page.colors.text.secondary)}
    >
      <div className="flex flex-row gap-2 items-center">
        <ArrowLeftIcon className="w-4 h-4" />
        Return to authorize another vendor
      </div>
    </Link>
  );
};
