export type VehicleChargingIconProps = {};

export const VehicleChargingIcon: React.FC<VehicleChargingIconProps> = ({}) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z"
        fill="#EEF4FF"
      />
      <path
        d="M11.3335 16.6667H13.3335M9.3335 14.0001L10.6668 14.6667L11.5139 12.1255C11.6888 11.6009 11.7762 11.3386 11.9384 11.1447C12.0815 10.9735 12.2654 10.841 12.4732 10.7593C12.7084 10.6667 12.9849 10.6667 13.5378 10.6667H18.4625C19.0155 10.6667 19.2919 10.6667 19.5272 10.7593C19.7349 10.841 19.9188 10.9735 20.062 11.1447C20.2241 11.3386 20.3116 11.6009 20.4864 12.1255L21.3335 14.6667L22.6668 14.0001M18.6668 16.6667H20.6668M12.5335 14.6667H19.4668C20.5869 14.6667 21.147 14.6667 21.5748 14.8847C21.9511 15.0765 22.2571 15.3824 22.4488 15.7588C22.6668 16.1866 22.6668 16.7466 22.6668 17.8667V19.6667C22.6668 19.9765 22.6668 20.1314 22.6412 20.2602C22.536 20.7891 22.1225 21.2026 21.5936 21.3078C21.4648 21.3334 21.3099 21.3334 21.0002 21.3334H20.6668C19.9305 21.3334 19.3335 20.7365 19.3335 20.0001C19.3335 19.816 19.1843 19.6667 19.0002 19.6667H13.0002C12.8161 19.6667 12.6668 19.816 12.6668 20.0001C12.6668 20.7365 12.0699 21.3334 11.3335 21.3334H11.0002C10.6904 21.3334 10.5355 21.3334 10.4067 21.3078C9.87779 21.2026 9.46432 20.7891 9.35912 20.2602C9.3335 20.1314 9.3335 19.9765 9.3335 19.6667V17.8667C9.3335 16.7466 9.3335 16.1866 9.55148 15.7588C9.74323 15.3824 10.0492 15.0765 10.4255 14.8847C10.8533 14.6667 11.4134 14.6667 12.5335 14.6667Z"
        stroke="#3538CD"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
