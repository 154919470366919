import { useUpdatePaymentMethodForm } from "@Light/components/page/form/UpdatePaymentMethod";
import { useHeader } from "@Light/components/page/header/context";
import {
  PaymentSession,
  usePaymentSession,
} from "@Light/components/page/payment/PaymentSession";
import { useScaffold } from "@Light/scaffold";
import { Elements } from "@stripe/react-stripe-js";
import { useStripe } from "@Light/app/stripe";
import React, { useCallback, useEffect } from "react";
import { WithStripe } from "@Light/components/app/WithStripe";
import {
  WithPopover,
  usePopover,
} from "@Light/components/page/popover/WithPopover";
import { BACK_ROUTE, PaymentUpdatedPopover } from "./PaymentUpdatedPopover";

export type UpdatePaymentMethodProps = {
  hideBack?: boolean;
};

export const UpdatePaymentMethod: React.FC<UpdatePaymentMethodProps> = ({
  hideBack,
  ...props
}) => {
  const { setTitle, setBackNavigate } = useHeader();

  setTitle("Update payment method");

  if (!hideBack) {
    setBackNavigate(BACK_ROUTE);
  }

  return (
    <WithStripe>
      <PaymentSession>
        <UpdatePaymentMethodWithSession {...props} />
      </PaymentSession>
    </WithStripe>
  );
};

export type UpdatePaymentMethodWithSessionProps = {};

export const UpdatePaymentMethodWithSession: React.FC<
  UpdatePaymentMethodWithSessionProps
> = ({ ...props }) => {
  const stripe = useStripe();
  const paymentSession = usePaymentSession();
  return (
    <WithPopover popover={<PaymentUpdatedPopover />}>
      {useCallback(
        () => (
          <Elements
            stripe={stripe.stripePromise}
            options={{ clientSecret: paymentSession.client_secret }}
          >
            <UpdatePaymentMethodWithElements {...props} />
          </Elements>
        ),
        [],
      )}
    </WithPopover>
  );
};

export type UpdatePaymentMethodWithElementsProps = {};
export const UpdatePaymentMethodWithElements: React.FC<
  UpdatePaymentMethodWithElementsProps
> = ({}) => {
  const scaffold = useScaffold();
  const { onSubmit, mutation, form, stripeError } =
    useUpdatePaymentMethodForm(true);
  const { open } = usePopover();

  useEffect(() => {
    if (mutation.isSuccess) {
      open();
    }
  }, [open, mutation.isSuccess]);

  const PageBody = scaffold.page.pageBody;
  const MutationButton = scaffold.page.mutationButton;
  return (
    <form>
      <PageBody
        footer={
          <MutationButton
            mutation={mutation}
            mutateButtonText="Update payment method"
            mutate={onSubmit}
            errorMessage={
              stripeError ??
              "Error updating payment method. Please try again later."
            }
          />
        }
      >
        <div className="pt-8">{form}</div>
      </PageBody>
    </form>
  );
};
