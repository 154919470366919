import { useScaffold } from "@Light/scaffold";
import { clsx } from "clsx";
import { useMenu } from "./BaseMenu";

export type MenuItemProps = {
  title: string;
  route?: string;
};

export const MenuItem: React.FC<MenuItemProps> = ({ title, route }) => {
  const scaffold = useScaffold();
  const { close } = useMenu();
  const className = "font-medium text-lg";
  if (route) {
    const Link = scaffold.system.link;
    return (
      <Link className={className} to={route} onClick={close}>
        {title}
      </Link>
    );
  }

  return (
    <div className={clsx("cursor-pointer", className)} onClick={close}>
      {title}
    </div>
  );
};
