import { ShopPlan } from "@/services/apiTypes";
import { Hyperlink } from "@Light/components/page/Hyperlink";
import { ArrowRightEndOnRectangleIcon } from "@heroicons/react/24/outline";

export type EFLLinkProps = {
  plan: ShopPlan;
};

export const EFLLink: React.FC<EFLLinkProps> = ({ plan }) => {
  return (
    <Hyperlink
      className="flex flex-row gap-2 items-center no-underline cursor-pointer"
      href={plan.efl_link}
      target="_blank"
      rel="noopener noreferrer"
    >
      <div className="font-medium text-sm">Electricity Facts Label</div>
      <ArrowRightEndOnRectangleIcon className="w-6" />
    </Hyperlink>
  );
};
