import { useScaffold } from "@Light/scaffold";
import { ServiceEvent } from "@Light/services/lightTypes";
import { ESIIDTooltip } from "./ESIIDField";

export type ServiceAddressFieldProps = {
  address_1: string;
  address_2?: string;
  city: string;
  state: string;
  postal_code: string;
  esi_id: string;
  service_event?: ServiceEvent;
};

export const ServiceAddressField: React.FC<ServiceAddressFieldProps> = ({
  address_1,
  address_2,
  city,
  state,
  postal_code,
  esi_id,
  service_event,
}) => {
  const scaffold = useScaffold();
  const label =
    service_event === "switch"
      ? "Service address (Switch service)"
      : service_event === "move_in"
        ? "Service address (Moving in)"
        : "Service address";
  const DetailField = scaffold.page.detail.detailField;
  return (
    <DetailField label={label}>
      <div className="flex flex-col gap-2">
        <div>
          <div>{address_1}</div>
          {address_2 !== "" && <div>{address_2}</div>}
          <span>
            {city}, {state} {postal_code}
          </span>
        </div>
        <div className="flex flex-row gap-2">
          <div className="flex flex-row">
            <ESIIDTooltip className="underline decoration-slate-400 decoration-dashed cursor-help">
              ESI ID
            </ESIIDTooltip>
            :
          </div>
          <div>{esi_id}</div>
        </div>
      </div>
    </DetailField>
  );
};
