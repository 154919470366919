import clsx from "clsx";
import React from "react";

export type CircleIconProps = React.ComponentProps<"div"> & {};

export const CircleIcon: React.FC<CircleIconProps> = React.forwardRef(
  ({ className, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={clsx(
          "p-2 rounded-3xl flex justify-center items-center",
          className,
        )}
        {...props}
      />
    );
  },
);
