import dayjs from "dayjs";
import { Item } from "./Item";
import { useScaffold } from "@Light/scaffold";

export type DocumentListProps = {
  efl?: string;
  yrac?: string;
  tos?: string;
  contract_start?: string;
  contract_end?: string;
  hideBrokerTermsOfService?: boolean;
};

export const DocumentList: React.FC<DocumentListProps> = ({
  efl,
  tos,
  yrac,
  contract_start,
  contract_end,
  hideBrokerTermsOfService,
}) => {
  let dateString = undefined;
  if (contract_start && contract_end) {
    dateString = `${dayjs(contract_start).format("MMMM YYYY")} - ${dayjs(contract_end).format("MMMM YYYY")}`;
  }
  const scaffold = useScaffold();
  const { termsOfServiceUrl } = scaffold.page;
  return (
    <div className="flex flex-col gap-5">
      <Item
        title="Electricity Facts Label"
        href={efl ?? ""}
        description={dateString}
      />
      <Item
        title="Your Rights As a Customer"
        href={yrac ?? ""}
        description={dateString}
      />
      <Item
        title="Terms of Service"
        href={tos ?? ""}
        description={dateString}
      />
      {!hideBrokerTermsOfService && termsOfServiceUrl && (
        <Item title="Broker Terms of Service" href={termsOfServiceUrl} />
      )}
    </div>
  );
};
