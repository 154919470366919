import { ChevronRightIcon } from "@heroicons/react/24/solid";
export type LinkItemProps = {
  leftIcon?: React.ReactNode;
  text: string;
};
export const LinkItem: React.FC<LinkItemProps> = ({ leftIcon, text }) => {
  return (
    <div className="flex flex-row justify-between items-center w-full p-4 cursor-pointer">
      <div className="flex flex-row items-center gap-4 font-medium">
        {leftIcon}
        <div className="">{text}</div>
      </div>
      <ChevronRightIcon className="w-4 h-4" />
    </div>
  );
};
