import { useScaffold } from "@Light/scaffold";
import { ChartDatum } from "./chartData";
import { useWidth } from "@Light/utils/width";
import clsx from "clsx";
import { useState } from "@Light/utils/state";
import { useEffect, useMemo } from "react";
import { usePlan } from "@Light/utils/plan";

export type IntervalPercentagesProps = {
  data: ChartDatum[];
};

export const IntervalPercentages: React.FC<IntervalPercentagesProps> = ({
  data,
}) => {
  const plan = usePlan();
  const { vehicleCharging } = plan.features;
  const consumptionTotal = useMemo(
    () => data.reduce((acc, datum) => acc + datum.consumption, 0.0),
    [data],
  );
  const vehicleChargingTotal = useMemo(
    () => data.reduce((acc, datum) => acc + datum.vehicle_charging, 0.0),
    [data],
  );
  const eligibleVehicleChargingTotal = useMemo(
    () =>
      data.reduce((acc, datum) => acc + datum.eligible_vehicle_charging, 0.0),
    [data],
  );
  if (vehicleCharging === undefined) {
    return null;
  }

  const { chargeStart, chargeEnd } = vehicleCharging;
  return (
    <div className="flex flex-col gap-2">
      <Item
        label="Home"
        percentage={
          ((consumptionTotal - vehicleChargingTotal) / consumptionTotal) * 100
        }
        color="#8ED1D9"
      />
      <Item
        label={`Unlimited vehicle charging (${chargeStart} - ${chargeEnd})`}
        percentage={(eligibleVehicleChargingTotal / consumptionTotal) * 100}
        color="#1570EF"
      />
      <Item
        label={`Other vehicle charging (${chargeEnd} - ${chargeStart})`}
        percentage={
          ((vehicleChargingTotal - eligibleVehicleChargingTotal) /
            consumptionTotal) *
          100
        }
        color="#C07A79"
      />
    </div>
  );
};

export type ItemProps = {
  label: string;
  percentage: number;
  color: string;
};

export const Item: React.FC<ItemProps> = ({ label, percentage, color }) => {
  const barWidth = useState(0);
  const [ref, width] = useWidth<HTMLDivElement>();
  useEffect(() => {
    barWidth.setVal((width * percentage) / 100);
  }, [ref, width, percentage, barWidth.setVal]);
  const scaffold = useScaffold();
  return (
    <div className="flex flex-col">
      <div className={clsx("text-sm", scaffold.page.colors.text.secondary)}>
        {label}
      </div>
      <div className="flex flex-row gap-2 items-center">
        <div
          ref={ref}
          className="rounded-xl h-2 w-full"
          style={{ background: "#EAECF0" }}
        >
          <div
            className="rounded-xl h-2"
            style={{
              background: color,
              width: barWidth.val,
            }}
          />
        </div>
        <div
          className={clsx("text-sm w-10", scaffold.page.colors.text.secondary)}
        >
          {Intl.NumberFormat("en-US", { maximumFractionDigits: 0 }).format(
            percentage,
          )}
          %
        </div>
      </div>
    </div>
  );
};
