import { useScaffold } from "@Light/scaffold";
import { Logo } from "./Logo";

export type NotFoundProps = {};

export const NotFound: React.FC<NotFoundProps> = ({}) => {
  const scaffold = useScaffold();
  const PageBody = scaffold.page.pageBody;
  const Title = scaffold.page.title;
  const Subtitle = scaffold.page.subtitle;
  const Link = scaffold.system.link;
  const Button = scaffold.page.button;
  return (
    <PageBody
      className="pt-20"
      footer={
        <div className="flex flex-col items-center gap-2">
          <Link to="/enroll/service-address/by-esi-id" className="w-full">
            <Button className="w-full">Search with ESI ID</Button>
          </Link>
          <Link to="/enroll/service-address/" className="w-full">
            <Button buttonType="outline" className="w-full">
              Try again
            </Button>
          </Link>
        </div>
      }
    >
      <div className="flex flex-col gap-12">
        <div className="flex flex-col items-center gap-8">
          <Logo />
          <div className="flex flex-col text-center gap-4">
            <Title>
              Your area may not allow you to choose your electricity provider
            </Title>
            <div className="flex flex-col gap-4">
              <Subtitle>
                {scaffold.page.companyName} is only available in electricity
                retail choice locations.
              </Subtitle>
              <Subtitle>
                If you are eligible but could not find your address, search with
                your ESI ID below.
              </Subtitle>
            </div>
          </div>
        </div>
      </div>
    </PageBody>
  );
};
