import React, { useContext, useState, createContext, ReactNode } from "react";
import { loadStripe, Stripe } from "@stripe/stripe-js";

export type StripeConfiguration = {
  stripePromise: Promise<Stripe | null> | null;
  setPublicKey: (publicKey: string) => void;
};

const StripeContext = createContext<StripeConfiguration>({
  stripePromise: null,
  setPublicKey: () => {},
});

export const StripeProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [stripePromise, setStripePromise] =
    useState<Promise<Stripe | null> | null>(null);

  const setPublicKey = (publicKey: string) => {
    if (!stripePromise) {
      setStripePromise(loadStripe(publicKey));
    }
  };

  return (
    <StripeContext.Provider value={{ stripePromise, setPublicKey }}>
      {children}
    </StripeContext.Provider>
  );
};

export const useStripe = () => {
  const context = useContext(StripeContext);

  if (context === undefined) {
    throw new Error("useStripe must be used within a StripeProvider");
  }

  return context;
};
