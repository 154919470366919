import { MutationButton } from "@Light/components/page/MutationButton";
import { useLocation } from "@Light/components/page/account";
import { useHeader } from "@Light/components/page/header/context";
import { useScaffold } from "@Light/scaffold";
import { useLight } from "@Light/services/light";
import { CancelServiceRequest } from "@Light/services/lightTypes";
import { useMutation } from "@Light/utils/mutation";
import { useState } from "@Light/utils/state";
import clsx from "clsx";
import dayjs from "dayjs";
import React, { useCallback } from "react";
import { useForm } from "react-hook-form";

export type CancelServiceProps = {};

export const CancelService: React.FC<CancelServiceProps> = ({}) => {
  const scaffold = useScaffold();
  const { setTitle, setBackNavigate } = useHeader();
  const { useCancelServiceMutation } = useLight();
  const cancelService = useMutation<CancelServiceRequest>(
    useCancelServiceMutation,
  );
  const today = dayjs();
  const finalServiceDate = useState(today.format("YYYY-MM-DD"));
  const maxSelectableDate = useState(
    today.add(30, "days").format("YYYY-MM-DD"),
  ).val;
  const location = useLocation();
  const { register, handleSubmit } = useForm();

  setTitle("Cancel my service");
  setBackNavigate("/account/service-details/move-or-cancel");

  const onSubmit = useCallback(
    handleSubmit(() => {
      cancelService.mutate({
        location_uuid: location.uuid,
        final_service_date: finalServiceDate.val,
      });
    }),
    [handleSubmit, cancelService.mutate, location.uuid, finalServiceDate.val],
  );

  if (cancelService.isSuccess) {
    const Navigate = scaffold.system.navigate;
    return <Navigate to="/account/service-details/cancel/confirmed" />;
  }

  const PageBody = scaffold.page.pageBody;
  const Subtitle = scaffold.page.subtitle;
  const Input = scaffold.system.input;
  const Datepicker = scaffold.system.datepicker;
  return (
    <form onSubmit={onSubmit}>
      <PageBody
        footer={
          <MutationButton
            buttonType="outline"
            mutation={cancelService}
            mutateButtonText={
              <div
                className={clsx(
                  "font-semibold",
                  scaffold.page.colors.text.error,
                )}
              >
                Cancel service
              </div>
            }
            errorMessage="Error cancelling service. Please try again later."
            className={clsx("border-2", scaffold.page.colors.border.error)}
          />
        }
      >
        <div className="pt-8 flex flex-col gap-6">
          <Subtitle>
            Fill out the information below to cancel your electricity service
            with {scaffold.page.companyName}
          </Subtitle>
          <div className="flex flex-col gap-1">
            <Subtitle className="text-sm font-semibold">
              Please choose your final date of service
            </Subtitle>
            <Datepicker
              selectedDate={finalServiceDate}
              minSelectableDate={today.format("YYYY-MM-DD")}
              maxSelectableDate={maxSelectableDate}
            />
          </div>
          <Subtitle className="text-sm font-semibold">
            Please confirm your request to cancel electricity service on the
            date specified above
          </Subtitle>
          <div className="flex flex-row items-center gap-3">
            <Input
              type="checkbox"
              {...register("confirm", { required: true })}
            />
            <div className={clsx("text-sm", scaffold.page.colors.text.primary)}>
              Yes, I confirm
            </div>
          </div>
        </div>
      </PageBody>
    </form>
  );
};
