import dayjs from "dayjs";
import { useMemo } from "react";
import { useState } from "@Light/utils/state";
import { InvoiceStatus, InvoiceStatusProps } from "../Billing/InvoiceStatus";
import clsx from "clsx";
import { useScaffold } from "@Light/scaffold";

export type InvoiceStatusChipProps = InvoiceStatusProps;
export const InvoiceStatusChip: React.FC<InvoiceStatusChipProps> = ({
  invoice,
  className,
}) => {
  // We do not want this "now" to change over the life of this component.
  const now = useState(dayjs()).val;
  const paymentDays = useMemo(
    () => dayjs(invoice.payment_due_date).diff(now, "days"),
    [invoice.payment_due_date, now],
  );
  const scaffold = useScaffold();
  const commonClassName = "rounded px-1 py-0.5 pt-1 border";
  if (invoice.paid_at) {
    return (
      <InvoiceStatus
        invoice={invoice}
        className={clsx(
          commonClassName,
          "bg-green-100 border-green-200",
          className,
        )}
      />
    );
  }
  if (paymentDays < 0) {
    return (
      <InvoiceStatus
        invoice={invoice}
        className={clsx(
          commonClassName,
          "border-red-200",
          scaffold.page.colors.background.error,
          className,
        )}
      />
    );
  }
  return (
    <InvoiceStatus
      invoice={invoice}
      className={clsx(
        commonClassName,
        "bg-orange-100 border-orange-200",
        className,
      )}
    />
  );
};
