export function NoTerminationIcon(
  props: React.ComponentPropsWithoutRef<"svg">,
) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z"
        fill="#F8F9FC"
      />
      <g clipPath="url(#clip0_2070_67444)">
        <path
          d="M13.9999 10.6668V9.3335M17.9999 21.3335V22.6668M10.6666 14.0002H9.33325M21.3333 18.0002H22.6666M11.2761 11.2763L10.3333 10.3335M20.7238 20.724L21.6666 21.6668M15.9999 19.7714L14.5857 21.1856C13.5443 22.227 11.8559 22.227 10.8145 21.1856C9.77307 20.1442 9.77307 18.4558 10.8145 17.4144L12.2287 16.0002M19.7712 16.0002L21.1854 14.5859C22.2268 13.5446 22.2268 11.8561 21.1854 10.8147C20.144 9.77332 18.4555 9.77332 17.4141 10.8147L15.9999 12.2289"
          stroke="#363F72"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2070_67444">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(8 8)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
