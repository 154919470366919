import { ShopPlan } from "@/services/apiTypes";
import { Button } from "@Light/components/page/Button";
import { Link } from "@Light/components/system/Link";

export type SelectButtonProps = {
  plan: ShopPlan;
};

export const SelectButton: React.FC<SelectButtonProps> = ({ plan }) => {
  return (
    <Link
      className="w-full"
      to={`/shop/signup?${new URLSearchParams({ plan: plan.group_uuid })}`}
    >
      <Button className="bg-blue-600 hover:bg-blue-300 w-full">Select</Button>
    </Link>
  );
};
