export type ProgressBarProps = {
  progress: string;
};

export const ProgressBar: React.FC<ProgressBarProps> = ({ progress }) => {
  return (
    <div className="w-full">
      <div className={"h-1.5 bg-sky-500"} style={{ width: progress }} />
    </div>
  );
};
