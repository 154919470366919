import { WithHeader } from "@Light/components/page/header";
import { Navigate, Route, Routes } from "react-router-dom";
import { Overlay } from "@Light/components/page/Overlay";
import { useHeader } from "@Light/components/page/header/context";
import { useMemo } from "react";
import { Signup } from "./signup";
import { EmailSent } from "./email-sent";

export type SignupRoutesProps = {};

export const SignupRoutes: React.FC<SignupRoutesProps> = ({}) => {
  return (
    <Overlay>
      <WithHeader>
        <SignupRoutesWithinHeader />
      </WithHeader>
    </Overlay>
  );
};

export type SignupRoutesWithinHeaderProps = {};

export const SignupRoutesWithinHeader: React.FC<
  SignupRoutesWithinHeaderProps
> = ({}) => {
  const { setRightButton } = useHeader();
  setRightButton(useMemo(() => <div />, []));
  return (
    <Routes>
      <Route path="/" element={<Signup />} />
      <Route path="/email-sent" element={<EmailSent />} />
      <Route path="/*" element={<Navigate to="/" />} />
    </Routes>
  );
};
