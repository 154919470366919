import { useScaffold } from "@Light/scaffold";
import clsx from "clsx";
import React from "react";

export type ServiceButtonProps = React.ComponentProps<"div"> & {
  icon: React.ReactNode;
  title: string;
};

export const ServiceButton: React.FC<ServiceButtonProps> = React.forwardRef<
  HTMLDivElement,
  ServiceButtonProps
>(({ icon, title, children, className, ...props }, ref) => {
  const scaffold = useScaffold();
  const DetailField = scaffold.page.detail.detailField;
  return (
    <div
      ref={ref}
      className={clsx(
        "p-4 border rounded-xl cursor-pointer",
        scaffold.page.colors.border.plain,
        className,
      )}
      {...props}
    >
      <div className="flex flex-row gap-4">
        {icon}
        <DetailField label={title}>{children}</DetailField>
      </div>
    </div>
  );
});
