import clsx from "clsx";

export type DiscoverCardProps = React.ComponentProps<"div">;
export const DiscoverCard: React.FC<DiscoverCardProps> = ({
  className,
  ...props
}) => {
  return (
    <div
      className={clsx(
        "rounded-md border-2 w-14 h-10 flex justify-center items-center",
        className,
      )}
      {...props}
    >
      <DiscoverLogo
        style={{
          position: "relative",
          width: "70px",
        }}
      />
    </div>
  );
};

export type DiscoverLogoProps = React.ComponentPropsWithoutRef<"svg">;
export const DiscoverLogo: React.FC<DiscoverLogoProps> = ({ ...props }) => {
  return (
    <svg viewBox="0 0 48 48" {...props}>
      <g id="Icons">
        <path
          d="M38.49878,34.82568H9.50122c-1.21976,0-2.20862-0.98886-2.20862-2.20862V15.38294   c0-1.21987,0.98886-2.20862,2.20862-2.20862h28.99755c1.21987,0,2.20862,0.98875,2.20862,2.20862v17.23412   C40.7074,33.83682,39.71865,34.82568,38.49878,34.82568z"
          style={{ fill: "#FFFFFF" }}
        />
        <path
          d="M10.35647,26.19808c0-1.47491,0-2.93341,0-4.39659c0.01707-0.00413,0.03261-0.01152,0.04827-0.01141   c0.48725,0.00348,0.97614-0.01609,1.46143,0.01707c0.77763,0.05316,1.42969,0.3693,1.8253,1.06941   c0.77904,1.37859-0.0711,3.10203-1.61135,3.28456c-0.38734,0.04566-0.78208,0.02783-1.17356,0.03631   C10.72664,26.20123,10.54661,26.19808,10.35647,26.19808z M11.22129,25.44263c0.16275,0,0.31625,0.00815,0.46866-0.00174   c0.33919-0.02196,0.65902-0.10252,0.92287-0.33658c0.60586-0.5377,0.6401-1.55862,0.07403-2.14241   c-0.40855-0.42137-0.9295-0.43051-1.46556-0.418C11.22129,23.51253,11.22129,24.47225,11.22129,25.44263z"
          style={{ fill: "#303030" }}
        />
        <path
          d="M34.74805,21.79834c0.01642-0.00391,0.02598-0.00826,0.03566-0.00815   c0.48714,0.00337,0.97472-0.0012,1.46154,0.01544c0.17057,0.00587,0.34495,0.04686,0.50845,0.10045   c0.55487,0.18155,0.86319,0.65858,0.82644,1.24292c-0.02098,0.33342-0.10545,0.63706-0.35593,0.87612   c-0.15447,0.14731-0.33995,0.23558-0.54226,0.29385c-0.02935,0.00837-0.0587,0.01609-0.10089,0.02761   c0.45975,0.6151,0.91461,1.22379,1.38392,1.8515c-0.20982,0-0.39572,0-0.58151,0c-0.12959,0-0.25928-0.00424-0.38843,0.00163   c-0.07088,0.00326-0.10991-0.02359-0.14785-0.08067c-0.35854-0.53889-0.72044-1.07528-1.07996-1.61352   c-0.03664-0.0549-0.07077-0.10176-0.1586-0.06664c0,0.57749,0,1.1615,0,1.75224c-0.29332,0-0.57401,0-0.86059,0   C34.74805,24.72675,34.74805,23.26597,34.74805,21.79834z M35.6095,23.81964c0.26994,0.00022,0.5365,0.01728,0.78024-0.11774   c0.2245-0.12448,0.30396-0.32886,0.30114-0.57433c-0.00271-0.23645-0.09241-0.4254-0.30928-0.53607   c-0.24396-0.12458-0.5066-0.10763-0.77209-0.09838C35.6095,22.93765,35.6095,23.37272,35.6095,23.81964z"
          style={{ fill: "#303030" }}
        />
        <path
          d="M31.74691,21.79421c0.80937,0,1.61211,0,2.423,0c0,0.24808,0,0.49127,0,0.74349   c-0.51769,0-1.03441,0-1.55938,0c0,0.32668,0,0.64456,0,0.97429c0.49867,0,0.99452,0,1.49796,0c0,0.2545,0,0.49715,0,0.7499   c-0.50018,0-0.99614,0-1.49894,0c0,0.39702,0,0.78404,0,1.18356c0.52095,0,1.04007,0,1.56351,0c0,0.25341,0,0.49421,0,0.74382   c-0.80893,0-1.61384,0-2.42616,0C31.74691,24.72773,31.74691,23.26738,31.74691,21.79421z"
          style={{ fill: "#303030" }}
        />
        <path
          d="M18.45018,22.21297c-0.15187,0.19829-0.2982,0.3893-0.44812,0.58488   c-0.16459-0.17546-0.33853-0.3169-0.57477-0.34701c-0.23645-0.03-0.45779,0.00859-0.63347,0.18449   c-0.1896,0.1896-0.16753,0.47269,0.05925,0.61336c0.15198,0.09414,0.32407,0.15611,0.48704,0.23254   c0.24895,0.11708,0.50649,0.21917,0.74512,0.35484c0.34495,0.19601,0.50041,0.51335,0.51606,0.907   c0.02077,0.52085-0.13687,0.96168-0.56879,1.27662c-0.32581,0.23765-0.70044,0.30407-1.09594,0.28081   c-0.60195-0.03522-1.04398-0.33418-1.36392-0.8135c0.17732-0.1897,0.35071-0.37495,0.51737-0.55324   c0.10785,0.12437,0.1934,0.24972,0.30429,0.3456c0.24754,0.21406,0.54194,0.28396,0.85992,0.20503   c0.32288-0.08012,0.51682-0.39354,0.44899-0.70066c-0.03533-0.15981-0.12817-0.28168-0.27091-0.35321   c-0.16089-0.08045-0.33071-0.14329-0.49671-0.21351c-0.17862-0.07545-0.36157-0.14209-0.53487-0.22819   c-0.55508-0.27591-0.72512-0.75741-0.56704-1.35794c0.13229-0.50215,0.59096-0.85373,1.15811-0.90395   c0.53433-0.04751,1.00984,0.10643,1.4236,0.44877C18.42572,22.18405,18.43377,22.19525,18.45018,22.21297z"
          style={{ fill: "#303030" }}
        />
        <path
          d="M27.12768,21.78953c0.30124,0,0.59368-0.0025,0.8859,0.00391   c0.02729,0.00054,0.06414,0.05229,0.07838,0.08762c0.30385,0.75871,0.60477,1.51884,0.90667,2.27852   c0.07415,0.18666,0.14905,0.373,0.2307,0.57727c0.02086-0.04631,0.0363-0.07795,0.04946-0.11024   c0.36865-0.91656,0.73763-1.8328,1.1042-2.7499c0.02468-0.06153,0.05207-0.09088,0.12437-0.08947   c0.27581,0.00478,0.55183,0.00196,0.84383,0.00196c-0.01663,0.0437-0.02837,0.07751-0.04217,0.11024   c-0.60685,1.44089-1.21433,2.88123-1.81889,4.32288c-0.0287,0.0686-0.06371,0.09208-0.13524,0.08795   c-0.0985-0.00576-0.19818,0.00381-0.29635-0.00424c-0.03467-0.00283-0.08491-0.03087-0.09708-0.06034   c-0.61129-1.46839-1.21911-2.9383-1.82703-4.40822C27.13073,21.82867,27.13149,21.81791,27.12768,21.78953z"
          style={{ fill: "#303030" }}
        />
        <path
          d="M22.27646,22.96016c-0.13839-0.10371-0.25656-0.20808-0.3893-0.28885   c-0.51204-0.31179-1.16986-0.23884-1.63168,0.1722c-0.68946,0.61379-0.65739,1.81171,0.06306,2.36658   c0.5402,0.41605,1.29347,0.39474,1.8091-0.05131c0.04327-0.03751,0.08632-0.07545,0.14905-0.13002c0,0.06577,0,0.11132,0,0.15698   c-0.00011,0.25917-0.00141,0.51824,0.0012,0.77752c0.00054,0.05501-0.01653,0.08404-0.06784,0.1086   c-1.05202,0.50204-2.34136,0.15959-2.96755-0.80818c-0.86427-1.33576-0.18036-3.16193,1.47818-3.51992   c0.51281-0.11045,1.01049-0.05544,1.48176,0.1847c0.03055,0.01555,0.07045,0.05316,0.07088,0.08121   C22.27831,22.32244,22.27646,22.63554,22.27646,22.96016z"
          style={{ fill: "#303030" }}
        />
        <path
          d="M27.11148,23.99761c0.01969,1.17258-0.94548,2.12991-2.16883,2.12807   c-1.2315-0.00185-2.1772-0.91863-2.17024-2.15296c0.00685-1.16552,0.96483-2.08425,2.17111-2.09165   C26.17232,21.87357,27.13105,22.83905,27.11148,23.99761z"
          style={{ fill: "#FF6F00" }}
        />
        <path
          d="M15.23183,26.19253c-0.28603,0-0.564,0-0.84905,0c0-1.46589,0-2.92624,0-4.39496   c0.28211,0,0.56216,0,0.84905,0C15.23183,23.26053,15.23183,24.72099,15.23183,26.19253z"
          style={{ fill: "#303030" }}
        />
      </g>
    </svg>
  );
};
