import { useScaffold } from "@Light/scaffold";
import { BillingInvoice } from "@Light/services/lightTypes";
import { InvoiceCell } from "./InvoiceCell";
import { clsx } from "clsx";

export type InvoiceGroupProps = {
  title: string;
  invoices: BillingInvoice[];
};

export const InvoiceGroup: React.FC<InvoiceGroupProps> = ({
  title,
  invoices,
}: InvoiceGroupProps) => {
  const scaffold = useScaffold();
  const Divided = scaffold.page.divided;
  return (
    <div className="flex flex-col gap-6">
      <div
        className={clsx(
          "text-lg font-medium",
          scaffold.page.colors.text.primary,
        )}
      >
        {title}
      </div>
      <Divided>
        {invoices.map((invoice: BillingInvoice) => (
          <InvoiceCell key={invoice.number} invoice={invoice} />
        ))}
      </Divided>
    </div>
  );
};
