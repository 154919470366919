import { AddressSearchResult, ServiceEvent } from "@Light/services/lightTypes";
import { State } from "@Light/utils/state";
import { createContext, useContext } from "react";

export type EnrollContextState = {
  address: State<AddressSearchResult | null>;
  serviceEvent: State<ServiceEvent>;
};

export const EnrollContext = createContext<EnrollContextState | undefined>(
  undefined,
);

export function useEnroll() {
  const state = useContext(EnrollContext);
  if (!state) {
    throw new Error("useEnroll must be used as part of an EnrollContext");
  }

  return state;
}
