import { BaseQueryFn, createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "./lightTokenRefresh";
import { createContext, useContext } from "react";

export function createLightSandboxApi(baseQuery: BaseQueryFn) {
  return createApi({
    reducerPath: "lightSandboxApi",
    baseQuery,
    tagTypes: ["Account", "BillingAddress", "PaymentMethod"],
    endpoints: (builder) => ({
      seedDemoData: builder.mutation<void, void>({
        query: () => ({
          url: "sandbox/seed-demo-data",
          method: "POST",
        }),
      }),
      deleteDemoData: builder.mutation<void, void>({
        query: () => ({
          url: "sandbox/delete-demo-data",
          method: "POST",
        }),
      }),
      deleteEnrollment: builder.mutation<void, void>({
        query: () => ({
          url: "sandbox/delete-enrollment",
          method: "POST",
        }),
      }),
    }),
  });
}

export const lightSandbox = createLightSandboxApi(baseQuery);

export const LightSandboxContext = createContext(lightSandbox);
export function useLightSandbox() {
  return useContext(LightSandboxContext);
}
