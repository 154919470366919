import { usePlan } from "@Light/utils/plan";
import { useMemo } from "react";

export type ChartDatum = {
  label: string;
  consumption: number;
  generation: number;
  vehicle_charging: number;
  eligible_vehicle_charging: number;
};

export type ChartDataProps = {
  labels: string[];
  data: ChartDatum[];
};

export function useChartData({ labels, data }: ChartDataProps) {
  const plan = usePlan();
  const { solarBuyback } = plan.features;
  const dataMap = useMemo(() => {
    return data.reduce(
      (acc, datum) => {
        acc[datum.label] = datum;
        return acc;
      },
      {} as Record<string, ChartDatum>,
    );
  }, [data]);
  // Hack to differentiate the border radius of daily vs monthly usage data.
  const borderRadius = data.length < 15 ? 5 : 10;
  return useMemo(() => {
    const datasets = [
      {
        id: 1,
        label: solarBuyback ? "Import" : "Home",
        data: labels.map((label) => {
          const datum = dataMap[label];
          if (!datum) {
            return null;
          }
          return Math.max(datum.consumption - datum.vehicle_charging, 0.0);
        }),
        backgroundColor: "#8ED1D9",
        borderRadius,
        stack: "stack",
      },
      {
        id: 2,
        label: "Export",
        data: labels.map((label) => {
          const datum = dataMap[label];
          if (!datum || !solarBuyback) {
            return null;
          }
          return -1 * datum.generation;
        }),
        backgroundColor: "rgb(253 224 71)",
        borderRadius,
        stack: "stack",
      },
      {
        id: 3,
        label: "Other vehicle charging",
        data: labels.map((label) => {
          const datum = dataMap[label];
          if (!datum) {
            return null;
          }
          return datum.vehicle_charging - datum.eligible_vehicle_charging;
        }),
        backgroundColor: "#C07A79",
        borderRadius,
        stack: "stack",
      },
      {
        id: 4,
        label: "Unlimited vehicle charging",
        data: labels.map((label) => {
          const datum = dataMap[label];
          if (!datum) {
            return null;
          }
          return datum.eligible_vehicle_charging;
        }),
        backgroundColor: "#1570EF",
        borderRadius,
        stack: "stack",
      },
    ];
    return { labels, datasets };
  }, [labels, dataMap]);
}
