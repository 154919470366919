import React, { useRef, useEffect, useCallback } from "react";
import { useScaffold } from "@Light/scaffold";
import { useState, State } from "@Light/utils/state";
import { OnBack, HeaderContext } from "./context";
import { HeaderPopover } from "./HeaderPopover";
import clsx from "clsx";

export type WithHeaderProps = {
  children: React.ReactNode;
};

export function WithHeader({ children }: WithHeaderProps) {
  const onBack = useState<OnBack | undefined>(undefined);
  const parentRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLElement>(null);

  const contentHeight = useState<number | undefined>(undefined);
  const keyboardOpen = useState<boolean>(false);
  const isHidden = useState(false);
  const divider = useState(true);
  const titleText = useState<string | undefined>(undefined);
  const leftButton = useState<React.ReactNode | undefined>(undefined);
  const rightButton = useState<React.ReactNode | undefined>(undefined);

  const updateContentHeight = useCallback(() => {
    if (!parentRef.current || !headerRef.current) {
      return;
    }

    const { visualViewport } = window;
    const isKeyboardOpen = Boolean(
      visualViewport && visualViewport.height < parentRef.current.offsetHeight,
    );
    const parentHeight = parentRef.current.offsetHeight;
    const siblingHeight = headerRef.current.offsetHeight;
    const computedHeight = parentHeight - siblingHeight;
    contentHeight.setVal(computedHeight);
    keyboardOpen.setVal(isKeyboardOpen);
  }, [
    parentRef.current,
    parentRef.current?.offsetHeight,
    headerRef.current,
    headerRef.current?.offsetHeight,
    window.visualViewport?.height,
    isHidden.val,
    divider.val,
    titleText.val,
    leftButton.val,
    rightButton.val,
    contentHeight.val,
    contentHeight.setVal,
    keyboardOpen.setVal,
  ]);

  useEffect(() => {
    window.addEventListener("resize", updateContentHeight);
    window.visualViewport?.addEventListener("resize", updateContentHeight);
    updateContentHeight();
    return () => {
      window.removeEventListener("resize", updateContentHeight);
      window.visualViewport?.removeEventListener("resize", updateContentHeight);
    };
  }, [updateContentHeight, window.visualViewport]);

  const scaffold = useScaffold();
  const Header = scaffold.page.header.header;
  const EmptyHeader = scaffold.page.header.emptyHeader;
  return (
    <HeaderContext.Provider
      value={{
        onBack,
        contentHeight,
        keyboardOpen,
        isHidden,
        divider,
        titleText,
        leftButton,
        rightButton,
      }}
    >
      <div
        className={clsx(
          "flex flex-col w-full h-full",
          !isHidden.val && divider.val ? "divide-y" : undefined,
        )}
        ref={parentRef}
      >
        {isHidden.val ? (
          <EmptyHeader
            onBack={onBack}
            ref={headerRef}
            leftButton={leftButton.val}
            rightButton={rightButton.val}
          />
        ) : (
          <Header
            onBack={onBack}
            ref={headerRef}
            titleLink="/"
            leftButton={leftButton.val}
            rightButton={rightButton.val}
          >
            {titleText.val}
          </Header>
        )}
        <div className="h-full">{children}</div>
      </div>
    </HeaderContext.Provider>
  );
}

export type HeaderProps = React.ComponentProps<"header"> & {
  onBack: State<OnBack | undefined>;
  titleLink?: string;
  leftButton?: React.ReactNode;
  rightButton?: React.ReactNode;
};

export const Header: React.FC<HeaderProps> = React.forwardRef(function Header(
  { onBack, titleLink, leftButton, rightButton, ...props }: HeaderProps,
  ref: React.ForwardedRef<HTMLElement>,
) {
  const scaffold = useScaffold();
  const MenuHeader = scaffold.page.header.menu.menuHeader;
  const BackButton = scaffold.page.header.backButton;
  return (
    <MenuHeader
      {...props}
      ref={ref}
      titleLink={titleLink}
      leftButton={leftButton ?? <BackButton onBack={onBack} />}
      rightButton={rightButton ?? <HeaderPopover />}
    />
  );
});

export const EmptyHeader: React.FC<HeaderProps> = React.forwardRef(
  ({ onBack, leftButton, rightButton, className, ...props }, ref) => {
    return <header ref={ref} className={clsx("h-24", className)} {...props} />;
  },
);
