import { useScaffold } from "@Light/scaffold";
import React, { useCallback } from "react";
import { ServiceButton } from "./ServiceButton";
import { XCircleIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { useNavigate } from "@Light/utils/context";

export type CancelServiceButtonProps = {};

export const CancelServiceButton: React.FC<CancelServiceButtonProps> = ({}) => {
  const navigate = useNavigate();
  const scaffold = useScaffold();
  const CircleIcon = scaffold.page.circleIcon;
  return (
    <ServiceButton
      icon={
        <CircleIcon
          className={clsx("w-10 h-10", scaffold.page.colors.background.error)}
        >
          <XCircleIcon className={clsx(scaffold.page.colors.text.error)} />
        </CircleIcon>
      }
      title="Cancel my service"
      onClick={useCallback(() => {
        navigate("/account/service-details/cancel/");
      }, [navigate])}
    >
      Switch off your electricity service and cancel your plan
    </ServiceButton>
  );
};
