import { useScaffold } from "@Light/scaffold";
import React from "react";
import { Item } from "./Item";
import { useAccount } from "@Light/components/page/account";
import { useLight } from "@Light/services/light";
import { QueryLoader } from "@Light/components/app/QueryLoader";
import { BillingAddressResponse } from "@Light/services/lightTypes";
import { useHeader } from "@Light/components/page/header/context";

export type DetailsProps = {};
export const Details: React.FC<DetailsProps> = ({}) => {
  const { useGetBillingAddressQuery } = useLight();
  const { setTitle, setBackNavigate } = useHeader();
  const billingAddressQuery = useGetBillingAddressQuery();
  setTitle("Account details");
  setBackNavigate("/account/");
  return (
    <QueryLoader
      queries={[billingAddressQuery]}
      errorMessage="Error loading billing address. Please try again later."
    >
      <DetailsLoaded
        billingAddress={billingAddressQuery.data as BillingAddressResponse}
      />
    </QueryLoader>
  );
};

export type DetailsLoadedProps = {
  billingAddress: BillingAddressResponse;
};
export const DetailsLoaded: React.FC<DetailsLoadedProps> = ({
  billingAddress,
}) => {
  const scaffold = useScaffold();
  const account = useAccount();
  const PageBody = scaffold.page.pageBody;
  const Divided = scaffold.page.divided;
  const DetailField = scaffold.page.detail.detailField;
  return (
    <PageBody>
      <Divided className="pt-8">
        <Item link="/account/details/update-name">
          <DetailField label="Name">
            {account.first_name} {account.last_name}
          </DetailField>
        </Item>
        <Item link="/account/details/update-email">
          <DetailField label="Email address">{account.email}</DetailField>
        </Item>
        <Item link="/account/details/update-billing-address">
          <DetailField label="Billing address">
            <div className="flex flex-col">
              <div>
                {billingAddress.address_1}
                {billingAddress.address_2
                  ? `, ${billingAddress.address_2}`
                  : ""}
              </div>
              <div>
                {billingAddress.city}, {billingAddress.state}{" "}
                {billingAddress.postal_code}
              </div>
            </div>
          </DetailField>
        </Item>
      </Divided>
    </PageBody>
  );
};
