import { MapPinIcon } from "@heroicons/react/24/outline";
import { AddressSearchResult } from "@Light/services/lightTypes";
import { clsx } from "clsx";
import { useScaffold } from "@Light/scaffold";
import { useCallback } from "react";
import { useEnroll } from "../context";
import { useNavigate } from "@Light/utils/context";

export type AddressResultProps = {
  result: AddressSearchResult;
  multipleSameAddress?: boolean;
};

export function AddressResult({
  result,
  multipleSameAddress,
}: AddressResultProps) {
  const scaffold = useScaffold();
  const { address } = useEnroll();
  const navigate = useNavigate();
  const { address_1, address_2, city, state, postal_code } = result;
  const addressStr = address_2 ? `${address_1}, ${address_2}` : address_1;
  const onClick = useCallback(() => {
    address.setVal(result);
    navigate("/enroll/service-address/service-event");
  }, [address.setVal, result, navigate]);
  return (
    <div
      className={clsx(
        "flex px-2 py-4 cursor-pointer gap-3",
        scaffold.page.colors.hover.highlight,
      )}
      onClick={onClick}
    >
      <MapPinIcon
        className={clsx(
          "w-6 h-6 flex-shrink-0 font-medium stroke-2",
          scaffold.page.colors.text.primary,
        )}
      />
      <div className="flex flex-col gap-1">
        <div className="pick-address-list-item">
          <span
            className={clsx("font-medium", scaffold.page.colors.text.primary)}
          >
            {addressStr}
          </span>
          <span className={scaffold.page.colors.text.secondary}>
            , {city}, {state} {postal_code}
          </span>
        </div>
        {multipleSameAddress && (
          <div className={clsx(scaffold.page.colors.text.secondary, "text-sm")}>
            {result.esi_id} (ESI ID)
          </div>
        )}
      </div>
    </div>
  );
}
