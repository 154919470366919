import React from "react";
import { useScaffold } from "@Light/scaffold";
import { EnvelopeIcon, XMarkIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { usePopover } from "@Light/components/page/popover/WithPopover";

export type MoveServicePopoverProps = {};
export const MoveServicePopover: React.FC<MoveServicePopoverProps> = ({}) => {
  const scaffold = useScaffold();

  const { close } = usePopover();

  const CircleIcon = scaffold.page.circleIcon;
  const Subtitle = scaffold.page.subtitle;
  const Hyperlink = scaffold.page.hyperlink;
  const OutlineButton = scaffold.system.outlineButton;
  return (
    <div className="p-4 flex flex-col gap-8">
      <div className="flex flex-row justify-between items-center">
        <CircleIcon
          className={clsx("w-10 h-10", scaffold.page.colors.background.info)}
        >
          <EnvelopeIcon className={clsx(scaffold.page.colors.text.info)} />
        </CircleIcon>
        <XMarkIcon
          className={clsx("w-6 h-6 text-gray-400 cursor-pointer")}
          onClick={close}
        />
      </div>
      <div className="flex flex-col gap-2">
        <div
          className={clsx(
            "text-lg font-medium flex flex-col",
            scaffold.page.colors.text.primary,
          )}
        >
          Email us to update
        </div>
        <Subtitle>
          To update your service address, please email us directly at{" "}
          <Hyperlink href={`mailto:${scaffold.page.supportEmail}`}>
            {scaffold.page.supportEmail}
          </Hyperlink>
        </Subtitle>
      </div>
      <Hyperlink href={`mailto:${scaffold.page.supportEmail}`}>
        <OutlineButton className="w-full">Email us</OutlineButton>
      </Hyperlink>
    </div>
  );
};
