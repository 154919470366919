import { useScaffold } from "@Light/scaffold";
import { Item } from "./Item";
import {
  HomeIcon,
  BoltIcon,
  SunIcon,
  ArrowPathIcon,
} from "@heroicons/react/24/outline";

export type SolarOfferItemsProps = {};

export const SolarOfferItems: React.FC<SolarOfferItemsProps> = ({}) => {
  const scaffold = useScaffold();
  const Divided = scaffold.page.divided;
  return (
    <Divided gap="4">
      <Item
        icon={<HomeIcon className="w-6 h-6" />}
        title="All of your power in one place"
      >
        Detailed energy insights and billing managed in one application.
      </Item>
      <Item
        icon={<BoltIcon className="w-6 h-6" />}
        title="100% renewable grid electricity"
      >
        Power your home at a competitive rate with energy from sustainable
        sources.
      </Item>
      <Item icon={<SunIcon className="w-6 h-6" />} title="Solar buyback match">
        Get paid the same rate for your excess energy as you pay us.
      </Item>
      <Item
        icon={<ArrowPathIcon className="w-6 h-6" />}
        title="Credits roll over and never expire"
      >
        Receive unlimited bill credits for the excess energy you generate.
      </Item>
    </Divided>
  );
};
