import { EnvelopeIcon, ArrowLeftIcon } from "@heroicons/react/24/outline";
import { clsx } from "clsx";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { useCallback } from "react";
import { useAPI } from "@/services/api";
import { useScaffold } from "@Light/scaffold";

export type CheckEmailProps = {};

export const CheckEmail: React.FC<CheckEmailProps> = ({}) => {
  const searchParams = useSearchParams()[0];
  const email = searchParams.get("email") ?? "";

  const { useLoginEmailTokenMutation } = useAPI();
  const [loginEmailToken] = useLoginEmailTokenMutation();

  const scaffold = useScaffold();
  const Title = scaffold.page.title;
  const Subtitle = scaffold.page.subtitle;
  const Button = scaffold.system.button;

  const resendEmail = useCallback(() => {
    loginEmailToken({ email });
  }, [loginEmailToken, email]);

  const navigate = useNavigate();
  const onBack = useCallback(() => {
    navigate("/login");
  }, [navigate]);

  if (!email) {
    return <Navigate to="/login" />;
  }

  return (
    <div className="flex items-center justify-center">
      <div className="flex flex-col items-center justify-center text-center gap-8 w-64">
        <EnvelopeIcon className="w-8 h-8" />
        <div className="flex flex-col items-center justify-center text-center gap-2">
          <Title>Check your email</Title>
          <Subtitle>
            <span>We sent an authentication email to</span>{" "}
            <span className="font-bold">{email}</span>
          </Subtitle>
        </div>
        <Button className="w-full" onClick={resendEmail}>
          Resend email
        </Button>
        <div
          className={clsx(
            "flex flex-row items-center gap-1 cursor-pointer text-sm",
            scaffold.page.colors.text.secondary,
          )}
          onClick={onBack}
        >
          <ArrowLeftIcon className="w-4 h-4" />
          <div>Back to log in</div>
        </div>
      </div>
    </div>
  );
};
