import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getCsrfCookie } from "./apiHelpers";

const REFRESH_BEFORE_EXPIRATION = 60000; // 1 minute

type TokenStore = {
  token: string | null;
  expiresAt: Date | null;
  isRefreshing: boolean;
};

const tokenStore: TokenStore = {
  token: null,
  expiresAt: null,
  isRefreshing: false,
};

const checkNeedsRefreshedToken = ({
  token,
  expiresAt,
  isRefreshing,
}: TokenStore) => {
  const needsRefreshing =
    !token ||
    !expiresAt ||
    // refresh at least 1 minute before the token expires
    expiresAt.getTime() - Date.now() < REFRESH_BEFORE_EXPIRATION;

  return needsRefreshing && !isRefreshing;
};

export const baseUrl = `${import.meta.env.VITE_LIGHT_BASE_API}/v1/account`;

export const baseQuery = fetchBaseQuery({
  baseUrl,
  prepareHeaders: async (headers) => {
    const needsRefreshing = checkNeedsRefreshedToken(tokenStore);

    if (needsRefreshing) {
      tokenStore.isRefreshing = true;

      try {
        const tokenResponse = await fetch("/api/energy/token", {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": getCsrfCookie() || "",
          },
        }).then((res) => res.json());

        tokenStore.token = tokenResponse.token;
        tokenStore.expiresAt = new Date(tokenResponse.expires_at);
      } catch (error) {
        console.error("Failed to refresh token", error);
      }

      tokenStore.isRefreshing = false;
    }

    if (tokenStore.token) {
      headers.set("Authorization", `Bearer ${tokenStore.token}`);
    }

    return headers;
  },
});
