import { useMonthlyUsage, useMonthlyIntervalParams } from "./interval";
import { useCallback, useMemo } from "react";
import dayjs from "dayjs";
import { IntervalBody } from "./IntervalBody";
import { MonthlyUsageDataResponse } from "@Light/services/lightTypes";

export type MonthlyIntervalBodyProps = {};

export const MonthlyIntervalBody: React.FC<MonthlyIntervalBodyProps> = ({}) => {
  const monthlyUsage = useMonthlyUsage();
  if (!monthlyUsage.isSuccess) {
    return null;
  }

  return <MonthlyIntervalBodyB1 monthlyUsage={monthlyUsage.data} />;
};

export type MonthlyIntervalBodyB1Props = {
  monthlyUsage: MonthlyUsageDataResponse;
};

export const MonthlyIntervalBodyB1: React.FC<MonthlyIntervalBodyB1Props> = ({
  monthlyUsage,
}) => {
  const { selectedYear } = useMonthlyIntervalParams();
  const months = useMemo(() => {
    const startOfYear = selectedYear.startOf("year");
    const endOfYear = selectedYear.endOf("year");
    const months = [];
    for (
      let date = startOfYear;
      date.isBefore(endOfYear) || date.isSame(endOfYear);
      date = date.add(1, "month")
    ) {
      months.push(date);
    }
    return months;
  }, [selectedYear]);
  const labels = useMemo(
    () => months.map((month) => month.format("MMM")),
    [months],
  );
  const data = useMemo(() => {
    return monthlyUsage.months?.map((month) => ({
      label: dayjs(`${month.month}-1`).format("MMM"),
      consumption: parseFloat(month.consumption),
      generation: parseFloat(month.generation),
      vehicle_charging: parseFloat(month.vehicle_charging),
      eligible_vehicle_charging: parseFloat(month.eligible_vehicle_charging),
    }));
  }, [monthlyUsage.months]);
  const title = useCallback(
    (items: { dataIndex: number }[]) => {
      const dataIndex = items[0]?.dataIndex;
      if (dataIndex === undefined) {
        return "";
      }
      const month = monthlyUsage.months?.[dataIndex];
      if (!month) {
        return "";
      }
      return dayjs(`${month?.month}-1`).format("MMMM");
    },
    [monthlyUsage.months],
  );
  return <IntervalBody title={title} labels={labels} data={data} />;
};
