import { useCallback, useMemo } from "react";
import dayjs from "dayjs";

import { Bar } from "react-chartjs-2";
import {
  Chart,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
} from "chart.js";
import { PeriodUsage } from "@Light/services/lightTypes";
import { useScaffold } from "@Light/scaffold";
import { useHover } from "@Light/utils/hover";

Chart.register(CategoryScale, LinearScale, BarElement, Tooltip);

export type UsageChartProps = {
  periodUsage: PeriodUsage[];
};

export const UsageChart: React.FC<UsageChartProps> = ({
  periodUsage,
}: UsageChartProps) => {
  const [ref, isHovered] = useHover<HTMLDivElement>();

  const scaffold = useScaffold();
  const backgroundColor = isHovered
    ? scaffold.page.colors.chart.unselected
    : scaffold.page.colors.chart.secondary;
  const reverseReads = useMemo(() => {
    // Allocate a new aray since reverse modifies the original array, and
    // that's a big no-no in React.
    const reverseReads = [...periodUsage];
    reverseReads.reverse();
    return reverseReads;
  }, [periodUsage]);
  const chartData = useMemo(() => {
    const labels = reverseReads.map((read) =>
      dayjs(read.period_end).format("MMM"),
    );
    const datasets = [
      {
        id: 1,
        label: "",
        data: reverseReads.map((read) => Math.round(read.total_kwh)),
        backgroundColor,
        hoverBackgroundColor: scaffold.page.colors.chart.secondary,
        borderRadius: 3,
      },
    ];
    return { labels, datasets };
  }, [reverseReads, backgroundColor]);
  const title = useCallback(
    (items: { dataIndex: number }[]) => {
      const { dataIndex } = items[0];
      const read = reverseReads[dataIndex];
      return dayjs(read?.period_end).format("MMM D 'YY");
    },
    [reverseReads],
  );
  const label = useCallback((context: { parsed: { y: number } }) => {
    return `${context.parsed.y} kWh`;
  }, []);
  return (
    <div ref={ref} className="w-full aspect-[2/1]">
      <Bar
        data={chartData}
        options={{
          responsive: true,
          maintainAspectRatio: true,
          interaction: {
            intersect: false,
            mode: "index",
          },
          scales: {
            y: {
              ticks: { display: false },
              border: { display: false },
            },
            x: {
              grid: { display: false },
              border: { display: false },
              ticks: {
                maxRotation: 0,
                minRotation: 0,
              },
            },
          },
          plugins: {
            tooltip: {
              enabled: true,
              displayColors: false,
              position: "nearest",
              callbacks: {
                title,
                label,
              },
            },
          },
        }}
      />
    </div>
  );
};
