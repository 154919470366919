import { Elements } from "@stripe/react-stripe-js";
import { useStripe } from "@Light/app/stripe";
import { useScaffold } from "@Light/scaffold";
import {
  PaymentSession,
  usePaymentSession,
} from "@Light/components/page/payment/PaymentSession";
import { useUpdatePaymentMethodForm } from "@Light/components/page/form/UpdatePaymentMethod";
import { useEffect, useState } from "react";
import { useNavigate } from "@Light/utils/context";
import { useAccount } from "@Light/components/page/account";

export type PaymentSetupProps = {};

export const PaymentSetup: React.FC<PaymentSetupProps> = () => {
  return (
    <PaymentSession>
      <PaymentSetupWithSession />
    </PaymentSession>
  );
};

export const PaymentSetupWithSession: React.FC = () => {
  const stripe = useStripe();
  const paymentSession = usePaymentSession();
  return (
    <Elements
      stripe={stripe.stripePromise}
      options={{ clientSecret: paymentSession.client_secret }}
    >
      <PaymentSessionWithElements />
    </Elements>
  );
};

export const PaymentSessionWithElements: React.FC = () => {
  const [failedStripeAttempts, setFailedStripeAttempts] = useState(0);
  const navigate = useNavigate();
  const account = useAccount();

  const scaffold = useScaffold();
  const { onSubmit, mutation, form, stripeError } =
    useUpdatePaymentMethodForm(false);

  // keep track of failed stripe attempts so we can show a different error message
  useEffect(() => {
    if (stripeError) {
      setFailedStripeAttempts((prev) => prev + 1);
    }
  }, [stripeError]);

  useEffect(() => {
    if (mutation.isSuccess && account.enrollment?.has_payment_method) {
      navigate("/enroll/confirm-and-enroll");
    }
  }, [mutation.isSuccess, account.enrollment?.has_payment_method, navigate]);

  let fullStripeErrorMessage = stripeError;
  if (failedStripeAttempts > 1) {
    fullStripeErrorMessage += ` You can also contact support at ${scaffold.page.supportEmail} for help.`;
  }

  const PageBody = scaffold.page.pageBody;
  const MutationButton = scaffold.page.mutationButton;
  return (
    <form onSubmit={onSubmit}>
      <PageBody
        title="Add a payment method"
        subtitle="You won't be charged until your next bill is due."
        progress="50%"
      >
        <div className="flex flex-col gap-6">
          {form}
          <MutationButton
            mutation={mutation}
            mutateButtonText="Add & continue"
            mutate={onSubmit}
            errorMessage={
              fullStripeErrorMessage ??
              "Error submitting payment information. Please try again later."
            }
          />
        </div>
      </PageBody>
    </form>
  );
};
