import React from "react";
import clsx from "clsx";
import { useScaffold } from "@Light/scaffold";

export type DetailLabelProps = React.ComponentProps<"div">;

export const DetailLabel: React.FC<DetailLabelProps> = React.forwardRef<
  HTMLDivElement,
  DetailLabelProps
>(({ children }, ref) => {
  const scaffold = useScaffold();
  return (
    <div
      ref={ref}
      className={clsx("font-medium text-sm", scaffold.page.colors.text.primary)}
    >
      {children}
    </div>
  );
});
