import { useAPI } from "@/services/api";
import { SignupRequest } from "@/services/apiTypes";
import { MutationButton } from "@Light/components/page/MutationButton";
import { PageBody } from "@Light/components/page/PageBody";
import { Input } from "@Light/components/system/Input";
import { useScaffold } from "@Light/scaffold";
import { useMutation } from "@Light/utils/mutation";
import clsx from "clsx";
import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { Navigate } from "react-router-dom";
import { Link } from "../system/Link";
import { useAnalytics } from "@Light/app/analytics";

export type SignupProps = {};

export const Signup: React.FC<SignupProps> = ({}) => {
  const scaffold = useScaffold();
  const { planUUID, attribution } = useAnalytics();
  const { useSignupMutation } = useAPI();
  const signup = useMutation<SignupRequest, void>(useSignupMutation);
  const { register, handleSubmit, watch } = useForm();
  const { email } = watch();
  const mutate = handleSubmit(
    useCallback(
      ({ email }) => {
        signup.mutate({
          email,
          plan: planUUID,
          attribution,
        });
      },
      [signup.mutate],
    ),
  );
  if (signup.isSuccess) {
    const searchParams = new URLSearchParams({
      email,
    });
    return <Navigate to={`/signup/email-sent?${searchParams}`} />;
  }
  return (
    <PageBody
      title={`Welcome to ${scaffold.page.companyName}`}
      subtitle={
        <>In order to get your rate, we need to verify your email address.</>
      }
    >
      <form className="flex flex-col gap-6">
        <Input
          type="email"
          placeholder="Email"
          {...register("email", { required: true })}
        />
        <div className="flex flex-col gap-2">
          <MutationButton
            mutateButtonText="Submit"
            mutation={signup}
            mutate={mutate}
            errorMessage="Failed selecting the plan. Please try again later."
          />
          <div className={clsx("text-sm", scaffold.page.colors.text.tertiary)}>
            <span>If you've already enrolled in a plan, click</span>{" "}
            <span
              className={clsx("font-medium", scaffold.page.colors.text.primary)}
            >
              <Link to="/login">here</Link>
            </span>{" "}
            <span>to log in.</span>
          </div>
        </div>
      </form>
    </PageBody>
  );
};
