import { ShopPlan } from "@/services/apiTypes";
import { checkSolarBuybackMatch } from "./utils";
import clsx from "clsx";
import { useScaffold } from "@Light/scaffold";
import { Term } from "./term";

export type SolarBuybackMatchProps = {
  plan: ShopPlan;
};

export const SolarBuybackMatch: React.FC<SolarBuybackMatchProps> = ({
  plan,
}) => {
  const scaffold = useScaffold();
  const hasSolarBuybackMatch = checkSolarBuybackMatch(plan);
  if (!hasSolarBuybackMatch) {
    return null;
  }

  const { storage_monthly_credit_dollars } = plan;
  if (parseFloat(storage_monthly_credit_dollars) !== 0) {
    return null;
  }

  const { export_rate_cents_per_kwh } = plan;
  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-col gap-2">
        <div className={clsx(scaffold.page.colors.text.primary)}>
          <span className={clsx("text-2xl font-medium")}>
            {Intl.NumberFormat("en-US", { maximumFractionDigits: 2 }).format(
              parseFloat(export_rate_cents_per_kwh),
            )}
            ¢
          </span>
          <span className="font-medium">/kWh matched solar buyback rate</span>
        </div>
      </div>
      <Term plan={plan} />
    </div>
  );
};
