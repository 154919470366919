import { useScaffold } from "@Light/scaffold";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { clsx } from "clsx";
import { createContext, useContext } from "react";

export type MenuContextType = {
  close: () => void;
};

export const MenuContext = createContext<MenuContextType | undefined>(
  undefined,
);

export function useMenu() {
  const menu = useContext(MenuContext);
  if (!menu) {
    throw new Error("MenuContext required for useMenu");
  }

  return menu;
}

export type BaseMenuProps = React.ComponentProps<"div"> & {
  close: () => void;
};

export const BaseMenu: React.FC<BaseMenuProps> = ({
  close,
  className,
  children,
  ...props
}) => {
  const scaffold = useScaffold();
  const MenuHeader = scaffold.page.header.menu.menuHeader;
  const Divided = scaffold.page.divided;
  const LogoutButton = scaffold.page.header.menu.logoutButton;
  return (
    <div {...props} className={clsx("w-full flex flex-col", className)}>
      <MenuContext.Provider value={{ close }}>
        <MenuHeader
          onClick={close}
          className="cursor-pointer"
          rightButton={
            <div className="h-10 w-10 flex items-center justify-center">
              <XMarkIcon className="h-6 w-6 cursor-pointer" />
            </div>
          }
        />
        <Divided className="p-6">
          <div className="flex flex-col gap-6">{children}</div>
          <div className="flex flex-col gap-4">
            <LogoutButton />
          </div>
        </Divided>
      </MenuContext.Provider>
    </div>
  );
};
