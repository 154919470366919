import { useScaffold } from "@Light/scaffold";
import { BaseMenuProps } from "./BaseMenu";
import { useLight } from "@Light/services/light";

export type MenuProps = BaseMenuProps;

export const Menu: React.FC<MenuProps> = (props) => {
  const { useGetAccountQuery } = useLight();
  const account = useGetAccountQuery();
  const scaffold = useScaffold();
  if (account.data?.enrollment.is_enrollment_finalized) {
    const ServiceMenu = scaffold.page.header.menu.serviceMenu;
    return <ServiceMenu {...props} />;
  }

  const EnrollmentMenu = scaffold.page.header.menu.enrollmentMenu;
  return <EnrollmentMenu {...props} />;
};
