import { User } from "@Light/services/apiTypes";
import { createContext, useContext } from "react";

export type UserState = User;
export const UserContext = createContext<UserState | undefined>(undefined);

export function useUser() {
  const user = useContext(UserContext);
  if (!user) {
    throw new Error("RequireLoggedIn context required to use useUser");
  }

  return user;
}
