import React, { useCallback } from "react";
import { useScaffold } from "@Light/scaffold";
import { useHeader } from "@Light/components/page/header/context";
import { useMutation } from "@Light/utils/mutation";
import { useForm } from "react-hook-form";
import { Navigate } from "react-router-dom";
import { useLight } from "@Light/services/light";
import { UpdateAccount } from "@Light/services/lightTypes";
import { useAccount } from "@Light/components/page/account";

const BACK_ROUTE = "/account/details/";

export type UpdateNameProps = {};

export const UpdateName: React.FC<UpdateNameProps> = ({}) => {
  const scaffold = useScaffold();
  const { setBackNavigate, setTitle } = useHeader();
  const { useUpdateAccountMutation } = useLight();
  const account = useAccount();
  const updateAccount = useMutation<UpdateAccount>(useUpdateAccountMutation);
  const { register, handleSubmit, watch } = useForm();
  const { first_name, last_name } = watch();
  const disabled =
    account.first_name === first_name && account.last_name === last_name;

  setTitle("Update your name");
  setBackNavigate(BACK_ROUTE);

  const mutate = useCallback(
    handleSubmit(({ first_name, last_name }: any) => {
      updateAccount.mutate({ first_name, last_name });
    }),
    [handleSubmit],
  );

  if (updateAccount.isSuccess) {
    return <Navigate to={BACK_ROUTE} />;
  }

  const PageBody = scaffold.page.pageBody;
  const MutationButton = scaffold.page.mutationButton;
  const Field = scaffold.system.field;
  const Label = scaffold.system.label;
  const Input = scaffold.system.input;
  return (
    <form onSubmit={mutate}>
      <PageBody
        footer={
          <MutationButton
            mutate={mutate}
            mutation={updateAccount}
            mutateButtonText="Confirm"
            errorMessage="Error updating your name. Please try again later."
            disabled={disabled}
          />
        }
      >
        <div className="pt-8 flex flex-col gap-4">
          <Field>
            <Label>First name</Label>
            <Input
              {...register("first_name", {
                required: true,
                value: account.first_name,
              })}
            />
          </Field>
          <Field>
            <Label>Last name</Label>
            <Input
              {...register("last_name", {
                required: true,
                value: account.last_name,
              })}
            />
          </Field>
        </div>
      </PageBody>
    </form>
  );
};
