import clsx from "clsx";
import { useScaffold } from "@Light/scaffold";
import { ChartDatum } from "./chartData";
import { usePlan } from "@Light/utils/plan";

export type IntervalAggregationProps = {
  data: ChartDatum[];
};

export const IntervalAggregation: React.FC<IntervalAggregationProps> = ({
  data,
}) => {
  const scaffold = useScaffold();
  const plan = usePlan();
  const totalConsumption = data.reduce((acc, datum) => {
    return acc + datum.consumption;
  }, 0.0);
  const totalGeneration = data.reduce((acc, datum) => {
    return acc + datum.generation;
  }, 0.0);
  const eligibleVehicleCharging = data.reduce((acc, datum) => {
    return acc + datum.eligible_vehicle_charging;
  }, 0.0);
  const hasSolarBuyback = Boolean(plan.features.solarBuyback);
  const hasVehicleCharging = Boolean(plan.features.vehicleCharging);
  return (
    <div className="flex flex-row justify-between">
      <Item label={hasSolarBuyback ? "Import total" : "Total"}>
        {totalConsumption === 0.0 ? (
          <div className={clsx(scaffold.page.colors.text.primary, "text-3xl")}>
            --
          </div>
        ) : (
          <div className="flex flex-row items-end gap-1">
            <div
              className={clsx(scaffold.page.colors.text.primary, "text-3xl")}
            >
              {Intl.NumberFormat("en-US", {
                maximumFractionDigits: 0,
              }).format(totalConsumption)}
            </div>
            <div className="text-sm pb-0.5">kWh</div>
          </div>
        )}
      </Item>
      {hasSolarBuyback && (
        <Item label="Export total">
          {totalGeneration === 0.0 ? (
            <div
              className={clsx(scaffold.page.colors.text.primary, "text-3xl")}
            >
              --
            </div>
          ) : (
            <div className="flex flex-row items-end gap-1">
              <div
                className={clsx(scaffold.page.colors.text.primary, "text-3xl")}
              >
                {Intl.NumberFormat("en-US", {
                  maximumFractionDigits: 0,
                }).format(totalGeneration)}
              </div>
              <div className="text-sm pb-0.5">kWh</div>
            </div>
          )}
        </Item>
      )}
      {hasVehicleCharging && (
        <Item label="Unlimited vehicle charging">
          {eligibleVehicleCharging === 0.0 ? (
            <div
              className={clsx(scaffold.page.colors.text.primary, "text-3xl")}
            >
              --
            </div>
          ) : (
            <div className="flex flex-row items-end gap-1">
              <div
                className={clsx(scaffold.page.colors.text.primary, "text-3xl")}
              >
                {Intl.NumberFormat("en-US", {
                  maximumFractionDigits: 0,
                }).format(eligibleVehicleCharging)}
              </div>
              <div className="text-sm pb-0.5">kWh</div>
            </div>
          )}
        </Item>
      )}
    </div>
  );
};

export type ItemProps = {
  label: string;
  children: React.ReactNode;
};

export const Item: React.FC<ItemProps> = ({ label, children }) => {
  const scaffold = useScaffold();
  return (
    <div className="flex flex-col">
      <div className={clsx("text-xs", scaffold.page.colors.text.tertiary)}>
        {label}
      </div>
      <div>{children}</div>
    </div>
  );
};
