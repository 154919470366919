import { ShopPlan } from "@/services/apiTypes";
import { useScaffold } from "@Light/scaffold";
import clsx from "clsx";
import { Rate } from "./rate";
import { SelectButton } from "./select-button";
import { SellingPoints } from "./selling-points";
import { EFLLink } from "./efl-link";
import { SolarBuybackMatch } from "./solar-buyback-match";
import { StorageCredit } from "./storage-credit";

export type PlanCardProps = {
  plan: ShopPlan;
};

export const PlanCard: React.FC<PlanCardProps> = ({ plan }) => {
  const scaffold = useScaffold();
  return (
    <div
      className={clsx(
        "border rounded-xl p-4",
        scaffold.page.colors.border.plain,
      )}
    >
      <div className="flex flex-col gap-6">
        <div
          className={clsx(
            "text-2xl font-medium",
            scaffold.page.colors.text.tertiary,
          )}
        >
          {scaffold.page.companyName} {plan.name} plan
        </div>
        <SolarBuybackMatch plan={plan} />
        <StorageCredit plan={plan} />
        <Rate plan={plan} />
        <SelectButton plan={plan} />
        <SellingPoints plan={plan} />
        <EFLLink plan={plan} />
      </div>
    </div>
  );
};
