import { configureStore } from "@reduxjs/toolkit";
import { api } from "@/services/api";
import { light } from "@/services/light";
import { lightSandbox } from "@Light/services/lightSandbox";
import lightAuth from "../slices/lightAuth";

const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    [light.reducerPath]: light.reducer,
    [lightSandbox.reducerPath]: lightSandbox.reducer,
    lightAuth,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat(
      api.middleware,
      light.middleware,
      lightSandbox.middleware,
    );
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
