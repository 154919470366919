import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { User, LoginEmail, LoginToken, UpdateUser } from "./apiTypes";
import { getCsrfCookie } from "./apiHelpers";
import { createContext, useContext } from "react";

export const baseQuery = fetchBaseQuery({
  baseUrl: "/api",
  prepareHeaders: (headers) => {
    const csrfToken = getCsrfCookie();
    if (csrfToken) {
      headers.set("X-CSRFToken", csrfToken);
    }
    return headers;
  },
});

export const api = createApi({
  reducerPath: "api",
  baseQuery,
  tagTypes: ["User"],
  endpoints: (builder) => ({
    getUser: builder.query<User, void>({
      query: () => "user",
      providesTags: ["User"],
    }),
    updateUser: builder.mutation<User, UpdateUser>({
      query: (body) => ({
        url: "user",
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["User"],
    }),
    loginEmail: builder.mutation<void, LoginEmail>({
      query: (body) => ({
        url: "login/email-token",
        method: "POST",
        body,
      }),
    }),
    loginToken: builder.mutation<void, LoginToken>({
      query: (body) => ({
        url: "login/token",
        method: "POST",
        body,
      }),
      invalidatesTags: ["User"],
    }),
    logout: builder.mutation<void, void>({
      query: () => ({
        url: "logout",
        method: "POST",
      }),
      invalidatesTags: ["User"],
    }),
  }),
});

export const {
  useGetUserQuery,
  useUpdateUserMutation,
  useLoginEmailMutation,
  useLoginTokenMutation,
  useLogoutMutation,
} = api;

export const APIContext = createContext(api);
export function useAPI() {
  return useContext(APIContext);
}
