import clsx from "clsx";
import { CreditCardIcon } from "@heroicons/react/24/outline";

export type GenericCardProps = React.ComponentProps<"div">;
export const GenericCard: React.FC<GenericCardProps> = ({
  className,
  ...props
}) => {
  return (
    <div
      className={clsx(
        "rounded-md border-2 w-14 h-10 p-1 flex justify-center items-center",
        className,
      )}
      {...props}
    >
      <CreditCardIcon className="w-8 h-8" />
    </div>
  );
};
