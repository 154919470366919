import { Navigate, Routes, Route } from "react-router-dom";
import { useHeader } from "@Light/components/page/header/context";
import { PaymentMethod } from "./PaymentMethod";
import { UpdatePaymentMethod } from "./UpdatePaymentMethod";

export const PaymentMethodRoute: React.FC = () => {
  const { setDivider } = useHeader();
  setDivider(false);
  return (
    <Routes>
      <Route path="/" element={<PaymentMethod />} />
      <Route path="/update" element={<UpdatePaymentMethod />} />
      <Route path="/*" element={<Navigate to="/accounts/payment-method/" />} />
    </Routes>
  );
};
