import { useScaffold } from "@Light/scaffold";
import clsx from "clsx";

export type ServiceEventButtonProps = React.ComponentProps<"div"> & {
  isSelected: boolean;
  onChange: () => void;
};

export const ServiceEventButton: React.FC<ServiceEventButtonProps> = ({
  isSelected,
  onChange,
  children,
  className,
  ...props
}) => {
  const scaffold = useScaffold();
  const Input = scaffold.system.input;
  return (
    <div
      className={clsx(
        "flex flex-row gap-3 items-center",
        "p-3 rounded-xl border",
        scaffold.page.colors.border.plain,
        "cursor-pointer",
        "text-sm",
        className,
      )}
      onClick={onChange}
      {...props}
    >
      <div className="flex w-4">
        <Input
          type="radio"
          className="cursor-pointer"
          checked={isSelected}
          onChange={onChange}
        />
      </div>
      {children}
    </div>
  );
};
