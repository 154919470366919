import { useMemo } from "react";
import { WithHeaderProps } from "../page/header/Header";
import { useHeader } from "../page/header/context";
import { SupportButton } from "./SupportButton";
import { useScaffold } from "@Light/scaffold";

export const WithEnrollmentHeader: React.FC<WithHeaderProps> = ({
  children,
  ...props
}) => {
  const scaffold = useScaffold();
  const WithHeader = scaffold.page.header.withHeader;
  return (
    <WithHeader {...props}>
      <WithEnrollmentHeaderB1>{children}</WithEnrollmentHeaderB1>
    </WithHeader>
  );
};

export type WithEnrollmentHeaderB1Props = {
  children?: React.ReactNode;
};

export const WithEnrollmentHeaderB1: React.FC<WithEnrollmentHeaderB1Props> = ({
  children,
}) => {
  const { setLeftButton } = useHeader();

  setLeftButton(useMemo(() => <SupportButton />, []));

  return children;
};
