import { Field, Label } from "@Light/components/system/Fieldset";
import { useEffect } from "react";
import { useDebouncedCallback } from "use-debounce";
import { AddressSearchResult } from "@Light/services/lightTypes";
import { State, useState } from "@Light/utils/state";
import { Mutation, useMutation } from "@Light/utils/mutation";
import { useScaffold } from "@Light/scaffold";
import { useLight } from "@Light/services/light";
import { useSearchPreliminaryAddress } from "../preliminaryAddressHook";
import { useEnroll } from "../context";
import { useNavigate } from "@Light/utils/context";
import { AddressNotFoundLink } from "./AddressNotFoundLink";

export type CheckAddressProps = {};
export const CheckAddress: React.FC<CheckAddressProps> = (props) => {
  const { useSearchAddressMutation } = useLight();
  return (
    <BaseCheckAddress
      {...props}
      query={useState("")}
      searchedQuery={useState("")}
      previousAddresses={useState<AddressSearchResult[] | undefined>(undefined)}
      searchAddress={useMutation(useSearchAddressMutation)}
    />
  );
};

export type BaseCheckAddressProps = CheckAddressProps & {
  query: State<string>;
  searchedQuery: State<string>;
  previousAddresses: State<AddressSearchResult[] | undefined>;
  searchAddress: Mutation<string, AddressSearchResult[]>;
};

export const BaseCheckAddress: React.FC<BaseCheckAddressProps> = ({
  query,
  searchedQuery,
  previousAddresses,
  searchAddress,
}) => {
  const scaffold = useScaffold();
  const { address } = useEnroll();
  const debouncedSearch = useDebouncedCallback((q: string) => {
    searchedQuery.setVal(q);
    searchAddress.mutate(q);
  }, 300);
  const onQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newQuery = e.target.value;
    query.setVal(newQuery);

    if (newQuery.length > 2) {
      // Directly call debounced function here
      debouncedSearch(newQuery);
    } else {
      previousAddresses.setVal(undefined);
    }
  };

  const { data: addresses } = searchAddress;
  useEffect(() => {
    if (addresses) {
      previousAddresses.setVal(addresses);
    }
  }, [addresses]);

  // if there is a preliminary address, then we will automatically select it and move to the next step
  const preliminaryResults = useSearchPreliminaryAddress()[1];
  const navigate = useNavigate();

  useEffect(() => {
    if (preliminaryResults.length === 1 && !address.val) {
      address.setVal(preliminaryResults[0]);
      navigate("/enroll/service-address/service-event");
    }
  }, [preliminaryResults, address, navigate]);

  const hasAddresses =
    previousAddresses.val && previousAddresses.val.length > 0;
  const showAddresses =
    hasAddresses &&
    (searchedQuery.val === query.val ||
      query.val.startsWith(searchedQuery.val));

  const { isLoading } = searchAddress;
  const isError =
    searchAddress.isError ||
    (previousAddresses.val && previousAddresses.val.length === 0);
  const PageBody = scaffold.page.pageBody;
  const Spinner = scaffold.system.spinner;
  const Input = scaffold.system.input;
  const AddressResult = scaffold.enroll.addressResult;

  // check if there are multiple of the same address in the previous addresses
  const seenAddresses = new Set();

  const multipleSameAddress = previousAddresses.val?.some((result) => {
    const addressStr = `${result.address_1}, ${result.address_2}`;
    if (seenAddresses.has(addressStr)) {
      return true;
    }
    seenAddresses.add(addressStr);
    return false;
  });

  const ErrorMessage = scaffold.system.errorMessage;
  return (
    <PageBody
      title="What is your address?"
      subtitle={
        <>
          Enter your address to confirm that {scaffold.page.companyName} plans
          are available in your area.
        </>
      }
      className="pb-4"
    >
      <div className="flex flex-col gap-24">
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-x-2 h-full gap-2">
            <div className="flex flex-col gap-8">
              <Field>
                <Label>Search address</Label>
                <Input
                  value={query.val}
                  onChange={onQueryChange}
                  data-record="true"
                  isError={isError}
                />
                {isLoading && !showAddresses && (
                  <div className="flex mt-4 justify-center">
                    <Spinner className="ml-2 w-6 h-6" />
                  </div>
                )}
                {isError && <ErrorMessage>No results found.</ErrorMessage>}
              </Field>
              {isError && (
                <div className="flex justify-center">
                  <AddressNotFoundLink />
                </div>
              )}
            </div>
            {showAddresses ? (
              <div className="flex flex-col gap-8">
                <div className="flex flex-col p-1 text-nowrap overflow-hidden">
                  {previousAddresses.val?.map((result, i) => (
                    <AddressResult
                      key={i}
                      result={result}
                      multipleSameAddress={multipleSameAddress}
                    />
                  ))}
                </div>
                <div className="flex justify-center">
                  <AddressNotFoundLink />
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </PageBody>
  );
};
