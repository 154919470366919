import { useScaffold } from "@Light/scaffold";
import { useState } from "@Light/utils/state";
import {
  Input as HeadlessInput,
  type InputProps as HeadlessInputProps,
  Select as HeadlessSelect,
  type SelectProps as HeadlessSelectProps,
  Button as HeadlessButton,
  type ButtonProps as HeadlessButtonProps,
} from "@headlessui/react";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { clsx } from "clsx";
import React, { forwardRef, useEffect, useRef } from "react";

const dateTypes = ["date", "datetime-local", "month", "time", "week"];
type DateType = (typeof dateTypes)[number];

export type InputOldProps = {
  type?:
    | "email"
    | "number"
    | "password"
    | "search"
    | "tel"
    | "text"
    | "url"
    | DateType;
} & HeadlessInputProps;
export const InputOld: (props: InputProps) => JSX.Element | React.ReactNode =
  forwardRef<HTMLInputElement, InputProps>(function Input(
    { className, ...props },
    ref,
  ) {
    return (
      <span
        data-slot="control"
        className={clsx([
          className,

          // Basic layout
          "relative block w-full",

          // Background color + shadow applied to inset pseudo element, so shadow blends with border in light mode
          "before:absolute before:inset-px before:rounded-[calc(theme(borderRadius.lg)-1px)] before:bg-white before:shadow",

          // Background color is moved to control and shadow is removed in dark mode so hide `before` pseudo
          "dark:before:hidden",

          // Focus ring
          "after:pointer-events-none after:absolute after:inset-0 after:rounded-lg after:ring-inset after:ring-transparent sm:after:focus-within:ring-2 sm:after:focus-within:ring-blue-500",

          // Disabled state
          "has-[[data-disabled]]:opacity-50 before:has-[[data-disabled]]:bg-zinc-950/5 before:has-[[data-disabled]]:shadow-none",

          // Invalid state
          "before:has-[[data-invalid]]:shadow-red-500/10",
        ])}
      >
        <HeadlessInput
          ref={ref}
          className={clsx([
            // Date classes
            props.type &&
              dateTypes.includes(props.type) && [
                "[&::-webkit-datetime-edit-fields-wrapper]:p-0",
                "[&::-webkit-date-and-time-value]:min-h-[1.5em]",
                "[&::-webkit-datetime-edit]:inline-flex",
                "[&::-webkit-datetime-edit]:p-0",
                "[&::-webkit-datetime-edit-year-field]:p-0",
                "[&::-webkit-datetime-edit-month-field]:p-0",
                "[&::-webkit-datetime-edit-day-field]:p-0",
                "[&::-webkit-datetime-edit-hour-field]:p-0",
                "[&::-webkit-datetime-edit-minute-field]:p-0",
                "[&::-webkit-datetime-edit-second-field]:p-0",
                "[&::-webkit-datetime-edit-millisecond-field]:p-0",
                "[&::-webkit-datetime-edit-meridiem-field]:p-0",
              ],

            // Basic layout
            "relative block w-full appearance-none rounded-lg px-[calc(theme(spacing[3.5])-1px)] py-[calc(theme(spacing[2.5])-1px)] sm:px-[calc(theme(spacing[3])-1px)] sm:py-[calc(theme(spacing[1.5])-1px)]",

            // Typography
            "text-base/6 text-zinc-950 placeholder:text-zinc-500 sm:text-sm/6 dark:text-white",

            // Border
            "border border-zinc-950/10 data-[hover]:border-zinc-950/20 dark:border-white/10 dark:data-[hover]:border-white/20",

            // Background color
            "bg-transparent dark:bg-white/5",

            // Hide default focus styles
            "focus:outline-none",

            // Invalid state
            "data-[invalid]:border-red-500 data-[invalid]:data-[hover]:border-red-500 data-[invalid]:dark:border-red-500 data-[invalid]:data-[hover]:dark:border-red-500",

            // Disabled state
            "data-[disabled]:border-zinc-950/20 dark:data-[hover]:data-[disabled]:border-white/15 data-[disabled]:dark:border-white/15 data-[disabled]:dark:bg-white/[2.5%]",
          ])}
          {...props}
        />
      </span>
    );
  });

export type InputProps = HeadlessInputProps & {
  isError?: boolean;
  isLoading?: boolean;
  inputButton?: React.ReactNode;
};
export const Input: React.FC<InputProps> = React.forwardRef<
  HTMLInputElement,
  InputProps
>(({ className, isError, isLoading, inputButton, ...props }, ref) => {
  const { type } = props;
  if (type === "checkbox") {
    return (
      <HeadlessInput
        ref={ref}
        data-slot="control"
        className={clsx("border", className)}
        {...props}
      />
    );
  }

  const rounded = inputButton ? "rounded-l-lg" : "rounded-lg";
  return (
    <div className="flex flex-row w-full">
      <InputBorder isError={Boolean(isError)} isLoading={Boolean(isLoading)}>
        <HeadlessInput
          ref={ref}
          data-slot="control"
          className={clsx(
            "border",
            borderStatusStyle({ isError }),
            "w-full",
            "text-base/6 text-zinc-950 placeholder:text-zinc-500 sm:text-sm/6 dark:text-white",
            rounded,
            className,
          )}
          {...props}
        />
      </InputBorder>
      {inputButton}
    </div>
  );
});

export function borderStatusStyle({ isError }: { isError?: boolean }) {
  const borderStyle = isError
    ? "border-red-300 focus:border-red-400 pr-8"
    : "border-zinc-950/10 focus:border-blue-400";
  const ringStyle = isError
    ? "ring-red-300 focus:ring-4 focus:ring-red-300"
    : "ring-none focus:ring-4 focus:ring-blue-300";
  return clsx(borderStyle, ringStyle);
}

export type InputBorderProps = {
  isError: boolean;
  isLoading: boolean;
  children: React.ReactNode;
};

export const InputBorder: React.FC<InputBorderProps> = ({
  isError,
  isLoading,
  children,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const topOffset = useState(0);
  useEffect(() => {
    if (!ref.current) {
      topOffset.setVal(0);
      return;
    }
    topOffset.setVal((ref.current?.offsetHeight - 20) / 2);
  }, [ref.current?.offsetHeight]);
  const scaffold = useScaffold();
  const Spinner = scaffold.system.spinner;
  return (
    <div ref={ref} className="relative w-full">
      {children}
      {(isError || isLoading) && topOffset.val !== 0 && (
        <div className="absolute right-2" style={{ top: topOffset.val }}>
          {isError && <ExclamationCircleIcon className="w-5 text-red-600" />}
          {isLoading && <Spinner className="w-5" />}
        </div>
      )}
    </div>
  );
};

export type InputButtonProps = HeadlessButtonProps;
export const InputButton: React.FC<InputButtonProps> = ({
  className,
  children,
  ...props
}: InputButtonProps) => {
  const scaffold = useScaffold();
  return (
    <HeadlessButton
      {...props}
      className={clsx(
        "border-y border-r rounded-r-lg px-3 font-medium cursor-pointer",
        scaffold.page.colors.text.primary,
        className,
      )}
    >
      {children}
    </HeadlessButton>
  );
};

export type CheckboxInputProps = HeadlessInputProps & {};
export const CheckboxInput: React.FC<CheckboxInputProps> = React.forwardRef<
  HTMLInputElement,
  CheckboxInputProps
>(({ className, ...props }, ref) => {
  return (
    <HeadlessInput
      ref={ref}
      data-slot="control"
      className={clsx("border", className)}
      {...props}
    />
  );
});

export type SelectProps = HeadlessSelectProps & {
  isError?: boolean;
};

export const Select: React.FC<SelectProps> = React.forwardRef<
  HTMLSelectElement,
  SelectProps
>(({ isError, className, ...props }, ref) => {
  const scaffold = useScaffold();
  return (
    <HeadlessSelect
      ref={ref}
      data-slot="control"
      className={clsx(
        "border rounded-lg",
        borderStatusStyle({ isError }),
        scaffold.page.colors.border.plain,
        scaffold.page.colors.text.primary,
        className,
      )}
      {...props}
    />
  );
});
