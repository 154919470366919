import { useMemo } from "react";
import { useScaffold } from "@Light/scaffold";

export type Query = {
  isLoading?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  data?: any;
  error?: any;
};

export type QueryLoaderProps = {
  queries: Query[];
  errorMessage: string;
  children?: React.ReactNode;
};

export const QueryLoader: React.FC<QueryLoaderProps> = ({
  queries,
  errorMessage,
  children,
}) => {
  const scaffold = useScaffold();
  const queryLoading = useMemo(() => {
    return queries.some((query) =>
      Boolean(query.isLoading || (!query.isSuccess && !query.isError)),
    );
  }, [queries]);
  const hasError = useMemo(() => {
    return queries.some((query) => Boolean(query.isError));
  }, [queries]);

  if (queryLoading) {
    const Spinner = scaffold.system.spinner;
    return (
      <div className="flex items-center justify-center h-40">
        <Spinner className="w-8 h-8" />
      </div>
    );
  }

  if (hasError) {
    const Field = scaffold.system.field;
    const ErrorMessage = scaffold.system.errorMessage;
    return (
      <Field>
        <ErrorMessage>{errorMessage}</ErrorMessage>
      </Field>
    );
  }

  return children;
};
