import { BillingInvoice } from "@Light/services/lightTypes";
import { clsx } from "clsx";
import { usePaymentDays } from "./common";
import { useScaffold } from "@Light/scaffold";

export type InvoiceStatusProps = React.ComponentProps<"span"> & {
  invoice: BillingInvoice;
};

export const InvoiceStatus: React.FC<InvoiceStatusProps> = ({
  className,
  invoice,
}) => {
  const paymentDays = usePaymentDays(invoice);
  const scaffold = useScaffold();
  if (invoice.paid_at) {
    return (
      <span className={clsx(scaffold.page.colors.text.success, className)}>
        Paid
      </span>
    );
  }
  if (paymentDays < 0) {
    return (
      <span className={clsx(scaffold.page.colors.text.error, className)}>
        Payment outstanding
      </span>
    );
  }
  if (paymentDays === 0) {
    return (
      <span className={clsx(scaffold.page.colors.text.warn, className)}>
        Autopay today
      </span>
    );
  }
  return (
    <span className={clsx(scaffold.page.colors.text.warn, className)}>
      Autopay in {paymentDays} {paymentDays === 1 ? "day" : "days"}
    </span>
  );
};
