import { Mutation } from "@Light/utils/mutation";
import { useScaffold } from "@Light/scaffold";
import clsx from "clsx";
import { ButtonProps } from "./Button";

export type MutationButtonProps = ButtonProps & {
  mutation: Mutation<any, any>;
  mutateButtonText: React.ReactNode;
  mutate?: () => void;
  errorMessage?: string;
};

export const MutationButton: React.FC<MutationButtonProps> = ({
  mutation,
  mutateButtonText,
  mutate,
  errorMessage,
  className,
  ...props
}) => {
  const scaffold = useScaffold();
  const Spinner = scaffold.system.spinner;
  const Field = scaffold.system.field;
  const ErrorMessage = scaffold.system.errorMessage;
  const Button = scaffold.page.button;
  return (
    <div className="flex flex-col gap-2">
      {mutation.error && errorMessage ? (
        <Field>
          <ErrorMessage>{errorMessage}</ErrorMessage>
        </Field>
      ) : null}
      <div className="flex justify-center">
        <Button
          type="submit"
          className={clsx("w-full", className)}
          onClick={mutate}
          disabled={Boolean(mutation.isLoading)}
          {...props}
        >
          {mutation.isLoading ? (
            <div className="flex items-center justify-center">
              <Spinner className="h-6 w-6" />
            </div>
          ) : (
            mutateButtonText
          )}
        </Button>
      </div>
    </div>
  );
};
