import { useScaffold } from "@Light/scaffold";
import React, { useCallback } from "react";
import { ServiceButton } from "./ServiceButton";
import clsx from "clsx";
import { TruckIcon } from "@heroicons/react/24/outline";
import { WithPopover } from "@Light/components/page/popover/WithPopover";
import { MoveServicePopover } from "./MoveServicePopover";

export type MoveServiceButtonProps = {};
export const MoveServiceButton: React.FC<MoveServiceButtonProps> = ({}) => {
  return (
    <WithPopover popover={<MoveServicePopover />}>
      {useCallback(({ open }) => {
        const scaffold = useScaffold();
        const CircleIcon = scaffold.page.circleIcon;
        return (
          <ServiceButton
            icon={
              <CircleIcon
                className={clsx(
                  "w-10 h-10",
                  scaffold.page.colors.background.info,
                )}
              >
                <TruckIcon className={clsx(scaffold.page.colors.text.info)} />
              </CircleIcon>
            }
            title="Move my service"
            onClick={open}
          >
            Transfer your plan to a new address
          </ServiceButton>
        );
      }, [])}
    </WithPopover>
  );
};
