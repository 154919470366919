import { useScaffold } from "@Light/scaffold";
import { WithPopover, usePopover } from "../popover/WithPopover";
import { useCallback } from "react";

export function MenuIcon(props: React.ComponentPropsWithoutRef<"svg">) {
  return (
    <svg viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
      <path
        d="M5 6h14M5 18h14M5 12h14"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export const HeaderPopover: React.FC = () => {
  return (
    <WithPopover style="top" popover={<MenuPopover />}>
      {useCallback(
        ({ open }) => (
          <div id="header-menu" className="p-2">
            <MenuIcon
              className="h-6 w-6 stroke-gray-900 cursor-pointer"
              onClick={open}
            />
          </div>
        ),
        [],
      )}
    </WithPopover>
  );
};

export const MenuPopover: React.FC = () => {
  const { close } = usePopover();
  const scaffold = useScaffold();
  const Menu = scaffold.page.header.menu.menu;
  return <Menu close={close} />;
};
