import { useCallback } from "react";
import { Day } from "./helpers";
import clsx from "clsx";
import { State } from "@Light/utils/state";
import { useScaffold } from "@Light/scaffold";

export type DayButtonProps = {
  day: Day;
  dayIdx: number;
  totalDays: number;
  minSelectableDate: string;
  maxSelectableDate: string;
  tempSelectedDate: State<string>;
};

export const DayButton: React.FC<DayButtonProps> = ({
  day,
  dayIdx,
  totalDays,
  minSelectableDate,
  maxSelectableDate,
  tempSelectedDate,
}: DayButtonProps) => {
  const onTempSelectDate = useCallback(() => {
    const { date } = day;
    if (date >= minSelectableDate && date <= maxSelectableDate) {
      tempSelectedDate.setVal(date);
    }
  }, [day, minSelectableDate, maxSelectableDate]);

  const scaffold = useScaffold();
  return (
    <button
      key={day.date}
      type="button"
      onClick={onTempSelectDate}
      disabled={!day.isSelectable}
      className={clsx(
        "py-1.5 hover:bg-gray-100 focus:z-10",
        day.isSelectable ? "bg-white" : "bg-gray-50",
        day.isSelected && "font-semibold",
        !day.isSelected && day.isSelectable && !day.isToday && "text-gray-900",
        !day.isSelected && !day.isSelectable && !day.isToday && "text-gray-400",
        dayIdx === 0 && "rounded-tl-lg",
        dayIdx === 6 && "rounded-tr-lg",
        dayIdx === totalDays - 7 && "rounded-bl-lg",
        dayIdx === totalDays - 1 && "rounded-br-lg",
      )}
    >
      <time
        dateTime={day.date}
        className={clsx(
          "mx-auto flex h-7 w-7 items-center justify-center rounded-full",
          day.isSelected && "border-2",
          day.isSelected && scaffold.page.colors.border.info,
        )}
      >
        {day.date.split("-")?.pop()?.replace(/^0/, "")}
      </time>
    </button>
  );
};
